import { Component, OnInit, ViewChild } from '@angular/core';
import { OnlineOfflineService } from 'src/app/online-offline.service';
import { DataBaseService } from 'src/app/provider.service';
import { MASKS } from 'ng-brazil';
import { locale, loadMessages } from "devextreme/localization";
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-funil',
  templateUrl: './funil.component.html',
  styleUrls: ['./funil.component.scss']
})

export class FunilComponent implements OnInit {
  public MASKS = MASKS;
  tipoUsuario:any = "";
  clientesFunil:any = [];
  funilVendedor:any = [];
  leadsDashFunil:any = [];
  contatoDashFunil:any = [];
  apresentacaoDashFunil:any = [];
  propostaDashFunil:any = [];
  fechamentoDashFunil:any = [];
  ganhasDashFunil:any = [];
  funilLeadsPainel:any = [];
  funilContatoPainel:any = [];
  funilApresentacaoPainel:any = [];
  funilPropostaPainel:any = [];
  funilFechamentoPainel:any = [];
  sessao:any = "inicio";
  funil:any = "leads";
  dataInicio:any = "";
  dataFim:any = "";
  dataInicioRel:any = "";
  dataFimRel:any = "";
  vendedorRel:any = "";
  percentualConversaoMes:number = 0;
  saldoPositivoMes:number = 0;
  saldoNegativoMes:number = 0;
  propostasEmAndamentoDash:number = 0;
  valorEmAndamentoDash:number = 0;
  compromissosDiaDash:number = 0;
  compromissosAtrasadoDash:number = 0;
  numeroTotal:number = 0;
  numeroPerdidas:number = 0;
  numeroEmAndamento:number = 0;
  numeroGanha:number = 0;
  valorTotal:number = 0;
  valorPerdidas:number = 0;
  valorEmAndamento:number = 0;
  valorGanha:number = 0;
  numLeadsFunil:number = 0;
  numContatoFunil:number = 0;
  numApresentacaoFunil:number = 0;
  numPropostaFunil:number = 0;
  numFechamentoFunil:number = 0;
  numGanhasFunil:number = 0;
  numeroPerdidasGeral:number = 0;
  numeroTotalGeral:number = 0;
  valorTotalGeral:number = 0;
  propostasNovas:number = 0;
  valorPropostasNovas:number = 0;
  dataHoje:any = "";
  tagsFunil:any = [];
  nomeCliente:any = "";
  tipoCliente:any = "";
  cpfCnpjCliente:any = "";
  telefoneCliente:any = "";
  telefone2Cliente:any = "";
  localTrabalhoCliente:any = "";
  funcaoCliente:any = "";
  nomeEmpresaCliente:any = "";
  nomeResponsavelCliente:any = "";
  emailCliente:any = "";
  cepCliente:any = "";
  ruaCliente:any = "";
  numeroCliente:any = "";
  bairroCliente:any = "";
  complementoCliente:any = "";
  municipioCliente:any = "";
  estadoCliente:any = "";
  tagsSelecionadas:any = [];
  informacoesAdicionaisCliente:any = "";
  nomeClienteEditar:any = "";
  tipoClienteEditar:any = "";
  cpfCnpjClienteEditar:any = "";
  telefoneClienteEditar:any = "";
  telefone2ClienteEditar:any = "";
  localTrabalhoClienteEditar:any = "";
  funcaoClienteEditar:any = "";
  nomeEmpresaClienteEditar:any = "";
  nomeResponsavelClienteEditar:any = "";
  emailClienteEditar:any = "";
  cepClienteEditar:any = "";
  ruaClienteEditar:any = "";
  numeroClienteEditar:any = "";
  bairroClienteEditar:any = "";
  complementoClienteEditar:any = "";
  municipioClienteEditar:any = "";
  estadoClienteEditar:any = "";
  tagsSelecionadasEditar:any = [];
  informacoesAdicionaisClienteEditar:any = "";
  idCliente:any = "";
  totalNegociosLeads:number = 0;
  totalGrupoLeads:number = 0;
  totalValorLeads:number = 0;
  totalNegociosContato:number = 0;
  totalGrupoContato:number = 0;
  totalValorContato:number = 0;
  totalNegociosApresentacao:number = 0;
  totalGrupoApresentacao:number = 0;
  totalValorApresentacao:number = 0;
  totalNegociosProposta:number = 0;
  totalGrupoProposta:number = 0;
  totalValorProposta:number = 0;
  totalNegociosFechamento:number = 0;
  totalGrupoFechamento:number = 0;
  totalValorFechamento:number = 0;
  negociacoesLeads:any = [];
  todosFunilVendedor:any = [];
  funilLeads:any = [];
  funilContato:any = [];
  funilApresentacao:any = [];
  funilProposta:any = [];
  funilFechamento:any = [];
  etapaMenu:any = "";
  nomeNegociacao:any = "";
  etapa:any = "Leads";
  statusNegociacao:any = "Em Andamento";
  funilSelecionado:any = "";
  cliente:any = "";
  contatos:any = [];
  chat:any = [];
  grupo:number = null;
  valor:number = null;
  clienteSelecionado:any = "";
  nomesNegociacao:any = [];
  todosClientesFunil:any = [];
  nomeBusca:any = "";
  empresaBusca:any = "";
  vendedorBusca:any = "";
  dataBuscaInicio:any = "";
  dataBuscaFim:any = "";
  idVendedorCliente:any = "";
  idUsuario:any = "";
  nomeNegociacaoEditar:any = "";
  etapaEditar:any = "";
  funilSelecionadoEditar:any = "";
  clienteEditar:any = "";
  chatEditar:any = [];
  grupoEditar:any = "";
  valorEditar:any = "";
  statusNegociacaoEditar:any = "";
  dataCriacaoEditar:any = "";
  dataEtapaEditar:any = "";
  dataTerminoEditar:any = "";
  etapaInicialEditar:any = "";
  horaEditar:any = "";
  idNegociacaoEditar:any = "";
  idVendedorEditar:any = "";
  motivoPerdaEditar:any = "";
  vendedorEditar:any = "";
  msgChat:any = "";
  intervalo:any = "";
  agendaDescricao:any = "";
  agendaStart:any = "";
  agendaEnd:any = "";
  agendaTitulo:any = "";
  agendaEditar:any = [];
  allFunil:any = [];
  negociacoes:any = [];
  idVendedorPerder:any = "";
  idFunilPerder:any = "";
  totalPropostasEmAndamento:number = 0;
  valorTotalPropostasEmAndamento:number = 0;
  ultimoTitulo:any = "";
  mensagensFixadas:any = [];
  mensagens:any = [];
  pt = {
    "pt": {
        "Yes": "Sim",
        "No": "Não",
        "Cancel": "Cancelar",
        "Clear": "Limpar",
        "Done": "Salvar",
        "Loading": "Carregando ...",
        "Select": "Selecione ...",
        "Search": "Pesquisar ...",
        "Back": "Voltar",
        "OK": "OK",
        "dxScheduler-editorLabelTitle": "Assunto",
        "dxScheduler-editorLabelStartDate": "Data de Início",
        "dxScheduler-editorLabelEndDate": "Data Final",
        "dxScheduler-editorLabelDescription": "Descrição",
        "dxScheduler-editorLabelRecurrence": "Repetir",
        "dxScheduler-openAppointment": "Abrir compromisso",
        "dxScheduler-recurrenceNever": "Nunca",
        "dxScheduler-recurrenceMinutely": "Minutely",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Diariamente",
        "dxScheduler-recurrenceWeekly": "Semanalmente",
        "dxScheduler-recurrenceMonthly": "Mensalmente",
        "dxScheduler-recurrenceYearly": "Anualmente",
        "dxScheduler-recurrenceRepeatEvery": "Todos",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "Fim da repetição",
        "dxScheduler-recurrenceAfter": "Depois de",
        "dxScheduler-recurrenceOn": "A",
        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "hour(s)",
        "dxScheduler-recurrenceRepeatDaily": "dia(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mês(es)",
        "dxScheduler-recurrenceRepeatYearly": "ano(s)",
        "dxScheduler-switcherDay": "Dia",
        "dxScheduler-switcherWeek": "Semana",
        "dxScheduler-switcherWorkWeek": "Dias úteis",
        "dxScheduler-switcherMonth": "Mês",
        "dxScheduler-switcherTimelineDay": "Linha de tempo Dia",
        "dxScheduler-switcherTimelineWeek": "Linha de tempo Semana",
        "dxScheduler-switcherTimelineWorkWeek": "Linha de tempo Dias úteis",
        "dxScheduler-switcherTimelineMonth": "Linha de tempo Mês",
        "dxScheduler-switcherAgenda": "Agenda",
        "dxScheduler-recurrenceRepeatOnDate": "na data",
        "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
        "dxScheduler-allDay": "Todo o dia",
        "dxScheduler-confirmRecurrenceEditMessage": "Deseja editar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Deseja eliminar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceEditSeries": "Editar série",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar série",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Editar compromisso",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar compromisso",
        "dxScheduler-noTimezoneTitle": "Sem fuso horário",
        "dxScheduler-moreAppointments": "{0} mais"
    }
  }
  appointments:any = [];
  dataHojeAgenda:any = [];

  constructor(public onOff: OnlineOfflineService, public db:DataBaseService){
    loadMessages(this.pt);
    locale(navigator.language);
  }

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  ngOnInit(){
    this.openMenu();
    this.tipoUsuario = localStorage.getItem('tipoVendas');
    this.idUsuario =  localStorage.getItem('idUserVendas');
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dataInicio = firstDay.toISOString().split('T')[0];
    this.dataFim = lastDay.toISOString().split('T')[0];
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    if(this.onOff.isOnline){
      if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
        this.buscarClientesFunilVendedor();
      }
      if(this.tipoUsuario == 'COORDENADOR'){
        this.buscarClientesPorCoordenador(localStorage.getItem('usuarioVendas'));
      }
      if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
        this.buscarTodosClientesFunil();
      }
      this.db.getTags().subscribe((data:any) => {
        this.tagsFunil = data.tags;
        this.db.getNomesNegociacao().subscribe((d:any) => {
          this.nomesNegociacao = d.nomesNegociacao;
        });
      });
    }else{
      alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
    }
    this.dataHojeAgenda = new Date(Date.now() - 10800000);
    this.intervalo = setInterval(() => {
      if(this.db.agendaVendedor){
        this.appointments = this.db.agendaVendedor; 
        clearInterval(this.intervalo)
      }
    },200)
  }
  
  openMenu(){
    document.getElementById("mySidenav").style.width = "15em";
    document.getElementById("principal").style.paddingLeft = "16em";  
  }
  
  closeMenu(){
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("principal").style.paddingLeft = "5em";  
  }

  painelVendas(dtInicio:any,dtFim:any){
    if(this.onOff.isOnline){
      this.compromissosAtrasadoDash = 0;
      this.compromissosDiaDash = 0;
      this.funilVendedor = [];
      this.todosFunilVendedor = [];
      this.funil = 'leads';
      if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
          this.db.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((data:any) => {
            if(data.agenda.length > 0){
              data.agenda.forEach((a) => {
                if(a.startDate.split('T')[0] == this.dataHoje){
                  this.compromissosDiaDash++
                }
                if(a.startDate.split('T')[0] > this.dataHoje){
                  this.compromissosAtrasadoDash++
                }
              })
            }
          if(data.funil?.length > 0){
            this.allFunil = data.funil;
            this.negociacoes = this.allFunil;
            let controle = 1;
            data.funil.forEach((f) => {
              this.todosFunilVendedor.push(f);
              if(f.dataCriacao >= dtInicio && f.dataCriacao <= dtFim){
                this.funilVendedor.push(f);
              }
              if(controle == data.funil.length){
                this.getDash();
              }else{
                controle++;
              }
            });
          }
        });
      }
      if(this.tipoUsuario == 'COORDENADOR'){
        this.db.getVendedoresByCoordenador(localStorage.getItem('usuarioVendas')).subscribe((vends:any) => {
          if(vends && vends.length > 0){
            vends.forEach((v) => {
              this.db.getVendedorById(v._id).subscribe((data:any) => {
                if(data.agenda.length > 0){
                  data.agenda.forEach((a) => {
                    if(a.startDate.split('T')[0] == this.dataHoje){
                      this.compromissosDiaDash++
                    }
                    if(a.startDate.split('T')[0] > this.dataHoje){
                      this.compromissosAtrasadoDash++
                    }
                  })
                }
              if(data.funil?.length > 0){
                let controle = 1;
                data.funil.forEach((f) => {
                  this.allFunil.push(f);
                  this.negociacoes.push(f);
                  this.todosFunilVendedor.push(f);
                  if(f.dataCriacao >= dtInicio && f.dataCriacao <= dtFim){
                    this.funilVendedor.push(f);
                  }
                  if(controle == data.funil.length){
                    this.getDash();
                  }else{
                    controle++;
                  }
                });
              }
            });
            })
          }
        })
      }
      if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
        this.db.getEquipeVendas().subscribe((vends:any) => {
          console.log(vends)
            vends.forEach((v:any) => {
              if(v.agenda.length > 0){
                v.agenda.forEach((a) => {
                  if(a.startDate.split('T')[0] == this.dataHoje && !a.resolvido){
                    this.compromissosDiaDash++
                  }
                  if(a.startDate.split('T')[0] < this.dataHoje && !a.resolvido){
                    this.compromissosAtrasadoDash++
                  }
                })
              }
              if(v.funil && v.funil.length > 0){
                  let controle = 1;
                  v.funil.forEach((f:any) => {
                    this.allFunil.push(f);
                    this.negociacoes.push(f);
                    this.todosFunilVendedor.push(f);
                    if(f.dataCriacao >= dtInicio && f.dataCriacao <= dtFim){
                      this.funilVendedor.push(f);
                    }
                    if(controle == v.funil.length){
                      this.getDash();
                    }else{
                      controle++;
                    }
                  });
                }
            });
        });
      }
    }else{
      alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
    }
  }

  buscarClientesPorCoordenador(coordenador:any){
      this.clientesFunil = [];
      this.todosClientesFunil = [];
      this.db.getClientesFunilByIdVendedor(localStorage.getItem('idUserVendas')).subscribe((result:any) => {
        this.clientesFunil = result;
        this.db.getVendedoresByCoordenador(coordenador).subscribe((data:any) => {
          if(data){
            data.forEach((v) => {
              this.db.getClientesFunilByIdVendedor(v._id).subscribe((d:any) => {
                if(d){
                  d.forEach((c) => {
                    this.clientesFunil.push(c);
                    this.clientesFunil = this.clientesFunil.sort((a,b) => {if(a.nome < b.nome)return -1});
                    this.todosClientesFunil = this.clientesFunil;
                  });
                }
              });
            });
          }
          this.painelVendas(this.dataInicio,this.dataFim);
        });
      });
  }

  buscarClientesFunilVendedor(){
    this.todosClientesFunil = [];
    this.clientesFunil = [];
      this.db.getClientesFunilByIdVendedor(localStorage.getItem('idUserVendas')).subscribe((data:any) => {
        this.clientesFunil = data;
        this.todosClientesFunil = this.clientesFunil;
        this.painelVendas(this.dataInicio,this.dataFim);
      })
  }

  buscarTodosClientesFunil(){
    this.todosClientesFunil = [];
    this.clientesFunil = [];
      this.db.getClientesFunil().subscribe((data:any) => {
        this.clientesFunil = data;
        this.todosClientesFunil = this.clientesFunil;
        this.painelVendas(this.dataInicio,this.dataFim);
      })
  }

  filterClientes(nome:any,vendedor:any,dtInicio:any,dtFim:any,empresa:any){
    if(nome || vendedor || dtInicio || dtFim || empresa){
      if(!dtInicio){
        dtInicio = "2000-01-01";
      }
      if(!dtFim){
        dtFim = "2200-01-01";
      }
      this.clientesFunil = this.todosClientesFunil.filter(item => {
        return item.nome?.toLowerCase().indexOf(nome?.toLowerCase()) > -1 && item.vendedor?.toLowerCase().indexOf(vendedor?.toLowerCase()) > -1 && (item.data >= dtInicio && item.data <= dtFim) && (item?.nomeEmpresa?.toLowerCase().indexOf(empresa?.toLowerCase()) > -1 || item?.localTrabalhoCliente?.toLowerCase().indexOf(empresa?.toLowerCase()) > -1);
      });
    }else{
      this.clientesFunil = this.todosClientesFunil;
    }
  }

  showClientes(){
    this.nomeBusca = "";
    this.empresaBusca = "";
    this.vendedorBusca = "";
    this.dataBuscaInicio = "";
    this.dataBuscaFim = "";
    this.clientesFunil = this.todosClientesFunil;
  }

  getDash(){
    this.funilLeadsPainel = [];
    this.funilContatoPainel = [];
    this.funilApresentacaoPainel = [];
    this.funilPropostaPainel = [];
    this.funilFechamentoPainel = [];
    this.funilLeads = [];
    this.funilContato = [];
    this.funilApresentacao = [];
    this.funilProposta = [];
    this.funilFechamento = [];
    this.numLeadsFunil = 0;
    this.numContatoFunil = 0;
    this.numApresentacaoFunil = 0;
    this.numPropostaFunil = 0;
    this.numFechamentoFunil = 0;
    this.numGanhasFunil = 0;
    this.numeroTotal = 0;
    this.numeroPerdidas = 0;
    this.numeroEmAndamento = 0;
    this.numeroGanha = 0;
    this.valorPerdidas = 0;
    this.valorEmAndamento = 0;
    this.valorTotal = 0;
    this.valorGanha = 0;
    this.propostasEmAndamentoDash = 0;
    this.valorEmAndamentoDash = 0;
    this.numeroPerdidasGeral = 0;
    this.valorTotalGeral = 0;
    this.numeroTotalGeral = 0;
    this.saldoNegativoMes = 0;
    this.propostasNovas = 0;
    this.valorPropostasNovas = 0;
    this.totalNegociosLeads = 0;
    this.totalGrupoLeads = 0;
    this.totalValorLeads = 0;
    this.totalNegociosContato = 0;
    this.totalGrupoContato = 0;
    this.totalValorContato = 0;
    this.totalNegociosApresentacao = 0;
    this.totalGrupoApresentacao = 0;
    this.totalValorApresentacao = 0;
    this.totalNegociosProposta = 0;
    this.totalGrupoProposta = 0;
    this.totalValorProposta = 0;
    this.totalNegociosFechamento = 0;
    this.totalGrupoFechamento = 0;
    this.totalValorFechamento = 0;
    this.totalPropostasEmAndamento = 0;
    this.valorTotalPropostasEmAndamento = 0;
    if(this.funilVendedor.length > 0){
      let ctrl = 1;
      this.funilVendedor.forEach((fun:any) => {
        switch(fun.etapa){
          case 'Leads' : 
            this.numLeadsFunil++;
            this.funilLeadsPainel.push(fun);
            break;
          case 'Contato' : 
            this.numContatoFunil++;
            this.funilContatoPainel.push(fun);
            break;
          case 'Apresentação' : 
            this.numApresentacaoFunil++;
            this.funilApresentacaoPainel.push(fun);
            break;
          case 'Proposta' : 
            this.numPropostaFunil++;
            this.funilPropostaPainel.push(fun);
            break;
          case 'Fechamento' : 
            this.numFechamentoFunil++;
            this.funilFechamentoPainel.push(fun);
            break;
        }
        if(fun.status == "Em Andamento"){
          this.propostasEmAndamentoDash++;
          this.valorEmAndamentoDash += fun.valor;
        }
        if(fun.status == "Perdida"){
          this.numeroPerdidasGeral++;
          this.saldoNegativoMes += fun.valor;
        }
        if(fun.status == "Ganha"){
          this.numGanhasFunil++;
          this.saldoPositivoMes += fun.valor;
        }
        if(fun.dataCriacao == this.dataHoje){
          this.propostasNovas++;
          this.valorPropostasNovas += fun.valor;
        }
        this.valorTotalGeral += fun.valor;
        if(ctrl == this.funilVendedor.length){
          this.numeroTotalGeral = this.funilVendedor.length;
          this.percentualConversaoMes = Number(((this.numGanhasFunil/this.funilVendedor.length) * 100).toFixed(2));
          this.numeroTotal = this.funilLeadsPainel.length;
          this.funilLeadsPainel.forEach((l) => {
            this.valorTotal += l.valor;
            if(l.status == 'Perdida'){
              this.numeroPerdidas++;
              this.valorPerdidas += l.valor;
            }
            if(l.status == 'Em Andamento'){
              this.numeroEmAndamento++;
              this.valorEmAndamento += l.valor;
            }
            if(l.status == 'Ganha'){
              this.numeroGanha++;
              this.valorGanha += l.valor;
            }
          });
        }else{
          ctrl++;
        }
      });
    }
    if(this.todosFunilVendedor.length > 0){
      this.todosFunilVendedor.forEach((fun:any) => {
        if(fun.status == "Em Andamento"){
        this.totalPropostasEmAndamento++;
        this.valorTotalPropostasEmAndamento += fun.valor;
        let date1 = new Date(fun.dataCriacao);
        let date2 = new Date(this.dataHoje);
        let date3 = new Date(fun.dataEtapa);
        let diffDays = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
        let dDays = Math.ceil(Math.abs(date2.getTime() - date3.getTime()) / (1000 * 3600 * 24));
        fun.diasNegociacao = diffDays;
        fun.diasEtapa = dDays;
        switch(fun.etapa){
          case 'Leads' : 
            this.funilLeads.push(fun);
            this.totalNegociosLeads++;
            this.totalGrupoLeads += fun.grupo;
            this.totalValorLeads += fun.valor;
            break;
          case 'Contato' : 
            this.funilContato.push(fun);
            this.totalNegociosContato++;
            this.totalGrupoContato += fun.grupo;
            this.totalValorContato += fun.valor;
            break;
          case 'Apresentação' : 
            this.funilApresentacao.push(fun);
            this.totalNegociosApresentacao++;
            this.totalGrupoApresentacao += fun.grupo;
            this.totalValorApresentacao += fun.valor;
            break;
          case 'Proposta' : 
            this.funilProposta.push(fun);
            this.totalNegociosProposta++;
            this.totalGrupoProposta += fun.grupo;
            this.totalValorProposta += fun.valor;
            break;
          case 'Fechamento' : 
            this.funilFechamento.push(fun);
            this.totalNegociosFechamento++;
            this.totalGrupoFechamento += fun.grupo;
            this.totalValorFechamento += fun.valor;
            break;
        }
      }
    })
  }
}

  goTo(menu:any){
    this.sessao = menu;
    this.closeMenu();
    if(menu == 'painel' || menu == 'funil'){
      this.painelVendas(this.dataInicio,this.dataFim);
    }
    if(menu == 'cadastro'){
      if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
        this.buscarClientesFunilVendedor();
      }
      if(this.tipoUsuario == 'COORDENADOR'){
        this.buscarClientesPorCoordenador(localStorage.getItem('usuarioVendas'));
      }
      if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
        this.buscarTodosClientesFunil();
      }
    }
  }

  openFunil(funil:any){
    this.numeroTotal = 0;
    this.numeroPerdidas = 0;
    this.numeroEmAndamento = 0;
    this.numeroGanha = 0;
    this.valorTotal = 0;
    this.valorPerdidas = 0;
    this.valorEmAndamento = 0;
    this.valorGanha = 0;
    this.funil = funil;
    switch(funil){
      case 'leads' : 
        this.numeroTotal = this.funilLeadsPainel.length;
        this.funilLeadsPainel.forEach((l) => {
          this.valorTotal += l.valor;
          if(l.status == 'Perdida'){
            this.numeroPerdidas++;
            this.valorPerdidas += l.valor;
          }
          if(l.status == 'Em Andamento'){
            this.numeroEmAndamento++;
            this.valorEmAndamento += l.valor;
          }
          if(l.status == 'Ganha'){
            this.numeroGanha++;
            this.valorGanha += l.valor;
          }
        });
        break;
      case 'contato' : 
        this.numeroTotal = this.funilContatoPainel.length;
        this.funilContatoPainel.forEach((l) => {
          this.valorTotal += l.valor;
          if(l.status == 'Perdida'){
            this.numeroPerdidas++;
            this.valorPerdidas += l.valor;
          }
          if(l.status == 'Em Andamento'){
            this.numeroEmAndamento++;
            this.valorEmAndamento += l.valor;
          }
          if(l.status == 'Ganha'){
            this.numeroGanha++;
            this.valorGanha += l.valor;
          }
        });
        break;
      case 'apresentacao' : 
        this.numeroTotal = this.funilApresentacaoPainel.length;
        this.funilApresentacaoPainel.forEach((l) => {
          this.valorTotal += l.valor;
          if(l.status == 'Perdida'){
            this.numeroPerdidas++;
            this.valorPerdidas += l.valor;
          }
          if(l.status == 'Em Andamento'){
            this.numeroEmAndamento++;
            this.valorEmAndamento += l.valor;
          }
          if(l.status == 'Ganha'){
            this.numeroGanha++;
            this.valorGanha += l.valor;
          }
        });
        break;
      case 'proposta' : 
        this.numeroTotal = this.funilPropostaPainel.length;
        this.funilPropostaPainel.forEach((l) => {
          this.valorTotal += l.valor;
          if(l.status == 'Perdida'){
            this.numeroPerdidas++;
            this.valorPerdidas += l.valor;
          }
          if(l.status == 'Em Andamento'){
            this.numeroEmAndamento++;
            this.valorEmAndamento += l.valor;
          }
          if(l.status == 'Ganha'){
            this.numeroGanha++;
            this.valorGanha += l.valor;
          }
        });
        break;
      case 'fechamento' : 
        this.numeroTotal = this.funilFechamentoPainel.length;
        this.funilFechamentoPainel.forEach((l) => {
          this.valorTotal += l.valor;
          if(l.status == 'Perdida'){
            this.numeroPerdidas++;
            this.valorPerdidas += l.valor;
          }
          if(l.status == 'Em Andamento'){
            this.numeroEmAndamento++;
            this.valorEmAndamento += l.valor;
          }
          if(l.status == 'Ganha'){
            this.numeroGanha++;
            this.valorGanha += l.valor;
          }
        });
        break;
    }
  }

  openModalNovoCliente(){
    let modal = document.getElementById('modNovoCliente');
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  closeModalNovoCliente(){
    let modal = document.getElementById('modNovoCliente');
    modal.style.display = "none";
    this.nomeCliente = "";
    this.tipoCliente = "";
    this.cpfCnpjCliente = "";
    this.telefoneCliente = "";
    this.telefone2Cliente = "";
    this.nomeEmpresaCliente = "";
    this.nomeResponsavelCliente = "";
    this.emailCliente = "";
    this.cepCliente = "";
    this.ruaCliente = "";
    this.numeroCliente = "";
    this.bairroCliente = "";
    this.complementoCliente = "";
    this.municipioCliente = "";
    this.estadoCliente = "";
    this.tagsSelecionadas = [];
    this.informacoesAdicionaisCliente = "";
  }
  
  openEditarCliente(cliente:any){
    this.idCliente = cliente._id;
    this.idVendedorCliente = cliente.idVendedor;
    this.nomeClienteEditar = cliente.nome;
    this.tipoClienteEditar = cliente.tipo;
    this.cpfCnpjClienteEditar = cliente.cpfCnpj;
    this.telefoneClienteEditar = cliente.telefone;
    this.telefone2ClienteEditar = cliente.telefone2;
    this.localTrabalhoClienteEditar = cliente.localTrabalhoCliente;
    this.funcaoClienteEditar = cliente.funcaoCliente;
    this.nomeEmpresaClienteEditar = cliente.nomeEmpresa;
    this.nomeResponsavelClienteEditar = cliente.nomeResponsavel;
    this.emailClienteEditar = cliente.email;
    this.cepClienteEditar = cliente.cep;
    this.ruaClienteEditar = cliente.rua;
    this.numeroClienteEditar = cliente.numero;
    this.bairroClienteEditar = cliente.bairro;
    this.complementoClienteEditar = cliente.complemento;
    this.municipioClienteEditar = cliente.municipio;
    this.estadoClienteEditar = cliente.estado;
    if(cliente.tagsSelecionadas){
      this.tagsSelecionadasEditar = cliente.tagsSelecionadas;
    }else{
      this.tagsSelecionadasEditar = [];
    }
    this.informacoesAdicionaisClienteEditar = cliente.informacoesAdicionais;
    let modal = document.getElementById('modEditarCliente');
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  closeModalEditarCliente(){
    let modal = document.getElementById('modEditarCliente');
    modal.style.display = "none";
    this.idCliente = "";
    this.idVendedorCliente = "";
    this.nomeClienteEditar = "";
    this.tipoClienteEditar = "";
    this.cpfCnpjClienteEditar = "";
    this.telefoneClienteEditar = "";
    this.telefone2ClienteEditar = "";
    this.localTrabalhoCliente = "";
    this.funcaoCliente = "";
    this.nomeEmpresaClienteEditar = "";
    this.nomeResponsavelClienteEditar = "";
    this.emailClienteEditar = "";
    this.cepClienteEditar = "";
    this.ruaClienteEditar = "";
    this.numeroClienteEditar = "";
    this.bairroClienteEditar = "";
    this.complementoClienteEditar = "";
    this.municipioClienteEditar = "";
    this.estadoClienteEditar = "";
    this.tagsSelecionadasEditar = [];
    this.informacoesAdicionaisClienteEditar = "";
  }

buscarCep(key:any){
  if(this.onOff.isOnline){
    let cEdit = key.replace(/_/gi,'').replace('.','').replace('-','');
    if(cEdit.length > 7){
      this.db.getCep(cEdit).subscribe((data:any) => {
        let cep = data;
        if(cep.erro == true){
          alert("CEP NAO ENCONTRADO");
        }else{
          this.ruaCliente = cep.logradouro.toUpperCase();
          this.bairroCliente = cep.bairro.toUpperCase();
          this.municipioCliente = cep.localidade.toUpperCase();
          this.estadoCliente = cep.uf.toUpperCase();
        }
      });
    }
  }else{
    alert("A Busca por CEP depende da conexão com a Internet, por favor fique ONLINE!")
  }
}

buscarCepEditar(key:any){
  if(this.onOff.isOnline){
    let cEdit = key.replace(/_/gi,'').replace('.','').replace('-','');
    if(cEdit.length > 7){
      this.db.getCep(cEdit).subscribe((data:any) => {
        let cep = data;
        if(cep.erro == true){
          alert("CEP NAO ENCONTRADO");
        }else{
          this.ruaClienteEditar = cep.logradouro.toUpperCase();
          this.bairroClienteEditar = cep.bairro.toUpperCase();
          this.municipioClienteEditar = cep.localidade.toUpperCase();
          this.estadoClienteEditar = cep.uf.toUpperCase();
        }
      });
    }
  }else{
      alert("A Busca por CEP depende da conexão com a Internet, por favor fique ONLINE!")
    }
}

selecionarTag(tag:any){
  let i = this.tagsSelecionadas.map((tt) => {
    return tt;
  }).indexOf(tag);
  if(i < 0){
    this.tagsSelecionadas.push(tag)
  }
}

removerTag(tag:any){
  let i = this.tagsSelecionadas.map((tt) => {
    return tt;
  }).indexOf(tag);
  if(i >= 0){
    this.tagsSelecionadas.splice(i,1)
  }
}

selecionarTagEditar(tag:any){
  let i = this.tagsSelecionadasEditar.map((tt) => {
    return tt;
  }).indexOf(tag);
  if(i < 0){
    this.tagsSelecionadasEditar.push(tag)
  }
}

removerTagEditar(tag:any){
  let i = this.tagsSelecionadasEditar.map((tt) => {
    return tt;
  }).indexOf(tag);
  if(i >= 0){
    this.tagsSelecionadasEditar.splice(i,1)
  }
}

cadastrarNovoCliente(){
  if(this.onOff.isOnline){
    if(window.confirm("Deseja Realmente Cadastrar esse Cliente?")){
      let obj = {
        data: this.dataHoje,
        vendedor: localStorage.getItem('usuarioVendas'),
        idVendedor: localStorage.getItem('idUserVendas'),
        nome: this.nomeCliente,
        tipo: this.tipoCliente,
        cpfCnpj: this.cpfCnpjCliente,
        telefone: this.telefoneCliente,
        telefone2: this.telefone2Cliente,
        nomeEmpresa: this.nomeEmpresaCliente,
        localTrabalhoCliente: this.localTrabalhoCliente,
        funcaoCliente: this.funcaoCliente,
        nomeResponsavel: this.nomeResponsavelCliente,
        email: this.emailCliente,
        cep: this.cepCliente,
        rua: this.ruaCliente,
        numero: this.numeroCliente,
        bairro: this.bairroCliente,
        complemento: this.complementoCliente,
        municipio: this.municipioCliente,
        estado: this.estadoCliente,
        tagsSelecionadas: this.tagsSelecionadas,
        informacoesAdicionais: this.informacoesAdicionaisCliente
      }
      this.db.postClienteFunil(obj).subscribe(res => {
        console.log(res);
        if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
          this.buscarClientesFunilVendedor();
        }
        if(this.tipoUsuario == 'COORDENADOR'){
          this.buscarClientesPorCoordenador(localStorage.getItem('usuarioVendas'));
        }
        if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
          this.buscarTodosClientesFunil();
        }
        this.closeModalNovoCliente();
        alert("Cadastro realizado com Sucesso!");
      }, err => {
        console.log(err);
        alert("Erro ao Realizar Cadastro, tente novamente!")
      })
    }
  }else{
    alert("Verifique sua Conexão com a Internet!")
  }
}

editarCliente(){
  if(this.onOff.isOnline){
    if(window.confirm("Deseja Realmente Editar esse Cliente?")){
      let obj = {
        nome: this.nomeClienteEditar,
        tipo: this.tipoClienteEditar,
        cpfCnpj: this.cpfCnpjClienteEditar,
        telefone: this.telefoneClienteEditar,
        telefone2: this.telefone2ClienteEditar,
        localTrabalhoCliente: this.localTrabalhoClienteEditar,
        funcaoCliente: this.funcaoClienteEditar,
        nomeEmpresa: this.nomeEmpresaClienteEditar,
        nomeResponsavel: this.nomeResponsavelClienteEditar,
        email: this.emailClienteEditar,
        cep: this.cepClienteEditar,
        rua: this.ruaClienteEditar,
        numero: this.numeroClienteEditar,
        bairro: this.bairroClienteEditar,
        complemento: this.complementoClienteEditar,
        municipio: this.municipioClienteEditar,
        estado: this.estadoClienteEditar,
        tagsSelecionadas: this.tagsSelecionadasEditar,
        informacoesAdicionais: this.informacoesAdicionaisClienteEditar
      }
      this.db.patchClienteFunil(this.idCliente,obj).subscribe(res => {
        console.log(res);
        if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
          this.buscarClientesFunilVendedor();
        }
        if(this.tipoUsuario == 'COORDENADOR'){
          this.buscarClientesPorCoordenador(localStorage.getItem('usuarioVendas'));
        }
        if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
          this.buscarTodosClientesFunil();
        }
        this.closeModalEditarCliente();
        alert("Cliente alterado com Sucesso!");

      }, err => {
        console.log(err);
        alert("Erro ao Realizar Alteração, tente novamente!")
      })
    }
  }else{
    alert("Verifique sua Conexão com a Internet!")
  }
}

openEditarNegociacao(n:any){
  this.chatEditar = n.chat.sort((a,b) => {
    if(a.data < b.data){return -1}
    if(a.data > b.data){return 1}
    if(a.hora < b.hora){return -1}
    if(a.hora > b.hora){return 1}
  });
  this.separarMensagens();
  let msgBox = document.getElementById('msgBox');
    if(msgBox){
      setTimeout(() => {
        msgBox.scrollTop = msgBox.scrollHeight;
      },200)
    }
  this.clienteEditar = n.cliente;
  this.clienteSelecionado = n.contatos[0];
  this.contatos = n.contatos;
  this.dataCriacaoEditar = n.dataCriacao;
  this.dataEtapaEditar = n.dataEtapa;
  this.dataTerminoEditar = n.dataTermino;
  this.etapaEditar = n.etapa;
  this.etapaInicialEditar = n.etapaInicial;
  this.funilSelecionadoEditar = n.funil;
  this.horaEditar = n.hora;
  this.idNegociacaoEditar = n.id;
  this.idVendedorEditar = n.idVendedor;
  this.motivoPerdaEditar = n.motivoPerda;
  this.nomeNegociacaoEditar = n.nomeNegociacao;
  this.statusNegociacaoEditar = n.status;
  this.vendedorEditar = n.vendedor;
  if(n.tagsSelecionadas){
    this.tagsSelecionadasEditar = n.tagsSelecionadas;
  }else{
    this.tagsSelecionadasEditar = [];
  }
  this.valorEditar = n.valor;
  this.grupoEditar = n.grupo;
  let modal = document.getElementById('modEditNegociacao');
  modal.style.display = "block";
  modal.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'smooth' 
  });
}

closeModalEditarNegociacao(){
  this.clienteSelecionado = "";
  this.chatEditar = [];
  this.clienteEditar = "";
  this.contatos = [];
  this.dataCriacaoEditar = "";
  this.dataEtapaEditar = "";
  this.dataTerminoEditar = "";
  this.etapaEditar = "";
  this.etapaInicialEditar = "";
  this.funilSelecionadoEditar = "";
  this.horaEditar = "";
  this.idNegociacaoEditar = "";
  this.idVendedorEditar = "";
  this.motivoPerdaEditar = "";
  this.nomeNegociacaoEditar = "";
  this.statusNegociacaoEditar = "";
  this.vendedorEditar = "";
  this.valorEditar = 0;
  this.grupoEditar = 0;
  this.tagsSelecionadasEditar = [];
  let modal = document.getElementById('modEditNegociacao');
  modal.style.display = "none";
}

editarNegociacao(){
  if(this.onOff.isOnline){
    if(window.confirm("Deseja Realmente Editar essa Negociação?")){
      let obj = {
        id : this.idNegociacaoEditar,
        hora : this.horaEditar,
        dataCriacao : this.dataCriacaoEditar,
        dataTermino : this.dataTerminoEditar,
        dataAtualizacao : this.dataHoje,
        dataEtapa : this.dataEtapaEditar,
        status : this.statusNegociacaoEditar,
        idVendedor : this.idVendedorEditar,
        vendedor : this.vendedorEditar,
        etapaInicial : this.etapaInicialEditar,
        nomeNegociacao: this.nomeNegociacaoEditar,
        etapa: this.etapaEditar,
        funil: this.funilSelecionadoEditar,
        cliente: this.clienteEditar,
        contatos: this.contatos,
        chat: this.chatEditar,
        grupo: this.grupoEditar,
        valor: this.valorEditar,
        tagsSelecionadas: this.tagsSelecionadasEditar,
        motivoPerda: this.motivoPerdaEditar
      }
      this.db.patchFunil(this.idVendedorEditar,this.idNegociacaoEditar,obj).subscribe(res => {
        console.log(res);
        alert("Negociação Editada com Sucesso!");
        this.closeModalEditarNegociacao();
        this.attFunil();
      }, err => {
        console.log(err);
        alert("Erro ao Editar Negociação, tente novamente!")
      })
    }
  }else{
      alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
    }
}

openModalNovaNegociacao(){
    let modal = document.getElementById('modNovaNegociacao');
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

closeModalNovaNegociacao(){
    this.nomeNegociacao = "";
		this.etapa = "Leads";
		this.funilSelecionado = "";
    this.tagsSelecionadas = [];
		this.cliente = "";
		this.contatos = [];
    this.chat = [];
		this.grupo = null;
    this.valor = null;
    this.statusNegociacao = "Em Andamento"
    let modal = document.getElementById('modNovaNegociacao');
    modal.style.display = "none";
}

cadastrarCliente(cliente:any){
    this.contatos = [];
    this.contatos.push(cliente) 
}

cadastrarNegociacao(){
  if(this.onOff.isOnline){
    if(window.confirm("Deseja Realmente Cadastrar essa Negociação?")){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let obj = {
        id : Math.random().toString(36).slice(-8),
        hora : hora,
        dataCriacao : this.dataHoje,
        dataTermino : "",
        dataAtualizacao : this.dataHoje,
        dataEtapa : this.dataHoje,
        status : this.statusNegociacao,
        idVendedor : localStorage.getItem('idUserVendas'),
        vendedor : localStorage.getItem('usuarioVendas'),
        etapaInicial : this.etapa,
        nomeNegociacao: this.nomeNegociacao,
        etapa: this.etapa,
        funil: this.funilSelecionado,
        cliente: this.cliente,
        contatos: this.contatos,
        chat: this.chat,
        grupo: this.grupo,
        valor: this.valor,
        tagsSelecionadas: this.tagsSelecionadas,
        motivoPerda:""
      }
      this.db.postFunil(localStorage.getItem('idUserVendas'),obj).subscribe(res => {
        console.log(res);
        alert("Negociação Cadastrada com Sucesso!");
        this.attFunil();
        this.closeModalNovaNegociacao();
      }, err => {
        console.log(err);
        alert("Erro ao Cadastrar Negociação, tente novamente!")
      })
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

ganharNegociacao(k:any){
  if(this.onOff.isOnline){
    if(k.status != 'Ganha'){
      if(window.confirm("Deseja Realmente Marcar essa Negociação como Ganha?")){
        this.db.ganharFunil(k.idVendedor,k.id,this.dataHoje).subscribe(res => {
          console.log(res);
          alert("Negociação Alterado Status para Ganha");
          this.attFunil();
        },
        err => {
          console.log(err);
          alert("Erro ao Atualizar Status, por favor tente novamente!")
        })
      }
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

perderNegociacao(k:any){
  if(this.onOff.isOnline){
    if(k.status != 'Perdida'){
      this.idVendedorPerder = k.idVendedor;
      this.idFunilPerder = k.id;
      let modPerda = document.getElementById('modalPerder');
      modPerda.style.display = 'block';
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

enviarPerderNegociacao(){
  if(this.onOff.isOnline){
    if(window.confirm("Deseja Realmente Marcar essa Negociação como Perdida?")){
      this.db.perderFunil(this.idVendedorPerder,this.idFunilPerder,this.dataHoje,this.motivoPerdaEditar).subscribe(res => {
        console.log(res);
        this.closePerderNegociacao();
        alert("Negociação Alterado Status para Perdida");
        this.attFunil();
      },
      err => {
        console.log(err);
        alert("Erro ao Atualizar Status, por favor tente novamente!")
      })
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

closePerderNegociacao(){
  let modPerda = document.getElementById('modalPerder');
  modPerda.style.display = 'none';
  this.idVendedorPerder = "";
  this.idFunilPerder = "";
}

retomarNegociacao(k:any){
  if(this.onOff.isOnline){
    if(k.status != 'Em Andamento'){
      if(window.confirm("Deseja Realmente Retomar essa Negociação?")){
        this.db.retomarFunil(k.idVendedor,k.id).subscribe(res => {
          console.log(res);
          alert("Negociação Alterado Status para Em Andamento");
          this.attFunil();
        },
        err => {
          console.log(err);
          alert("Erro ao Atualizar Status, por favor tente novamente!")
        })
      }
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

attFunil(){
  if(this.onOff.isOnline){
      this.todosFunilVendedor = [];
      if(this.tipoUsuario == 'VENDEDOR' || this.tipoUsuario == 'ASSESSOR COMERCIAL'){
        this.buscarClientesFunilVendedor();
      }
      if(this.tipoUsuario == 'COORDENADOR'){
        this.buscarClientesPorCoordenador(localStorage.getItem('usuarioVendas'));
      }
      if(this.tipoUsuario == 'MASTER' || this.tipoUsuario == 'GERENTE'){
        this.buscarTodosClientesFunil();
      }
   /*    this.db.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((data:any) => {
        if(data.funil.length > 0){
          this.allFunil = data.funil;
          this.negociacoes = this.allFunil;
          let controle = 1;
          data.funil.forEach((f) => {
          this.todosFunilVendedor.push(f);
          if(controle == data.funil.length){
            this.getDash();
          }else{
            controle++;
          }
        });
      }
    }); */
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

alterarEtapa(k:any,e:any){
  if(this.onOff.isOnline){
    this.db.patchEtapaFunil(k.idVendedor,k.id,e.srcElement.value).subscribe(res => {
      console.log(res);
      this.attFunil();
    }, err => {
      console.log(err)
    })
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

replaceStrings(string:any){
  let str = string.replace(/\%/gi,'%25');
  return str.replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\#/gi,'%23');
}

sendChat(message:any){
  if(this.onOff.isOnline){
    if(message != ""){
      let msgChat = this.replaceStrings(message);
      let data = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        id : Math.random().toString(36).slice(-8),
        data: data,
        prioridade:"normal",
        hora: hora,
        tipo: "texto",
        mensagem: msgChat,
        usuario:localStorage.getItem('usuarioVendas')
      }
      this.db.enviarChatFunil(this.idVendedorEditar,this.idNegociacaoEditar,body).subscribe(res => {
          console.log(res);
          this.msgChat = "";
          this.chatEditar.push(body);
          this.separarMensagens();
          let msgBox = document.getElementById('msgBox');
          if(msgBox){
            setTimeout(() => {
              msgBox.scrollTop = msgBox.scrollHeight;
            },200)
          }
        }); 
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

mudarPrioridade(id:any,prioridade:any){
  if(this.onOff.isOnline){
    let indice = this.chatEditar.map((c) => {
      return c.id;
    }).indexOf(id);
    if(indice >= 0){
      this.db.patchPrioridadeChat(this.idVendedorEditar,this.idNegociacaoEditar,indice,prioridade).subscribe(res => {
        console.log(res);
        this.chatEditar[indice].prioridade = prioridade;
        this.separarMensagens();
      }, err => {
        console.log(err)
      })
    }
  }else{
    alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
  }
}

enviarDoc(e:any){
  if(this.onOff.isOnline){
    let selectedFiles = <FileList>e.srcElement.files;
    const imagedata = new FormData();
    imagedata.append('files[]', selectedFiles[0], selectedFiles[0].name)
    this.db.enviarAnexoChat(imagedata).subscribe(res => {
        console.log(res);
    });
      let data = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        id : Math.random().toString(36).slice(-8),
        data: data,
        hora: hora,
        prioridade:"normal",
        tipo: "anexo",
        mensagem: this.db.urlUpload+'/upload/'+selectedFiles[0].name,
        usuario:localStorage.getItem('usuarioVendas')
      }
      this.db.enviarChatFunil(this.idVendedorEditar,this.idNegociacaoEditar,body).subscribe(r => {
        console.log(r);
        this.chatEditar.push(body);
        this.separarMensagens();
        let msgBox = document.getElementById('msgBox');
        if(msgBox){
          setTimeout(() => {
            msgBox.scrollTop = msgBox.scrollHeight;
          },200)
        }
      });
    }else{
      alert("FUNIS DE VENDA FUNCIONAM SOMENTE ONLINE, POR FAVOR VERIFIQUE SUA CONEXÃO!")
    }
}

getAgenda(){
  this.appointments = [];
  if(this.db.agendaVendedor.length > 0){
    this.db.agendaVendedor.forEach((aP:any) => {
      aP.color = this.db.corVendedor;
      this.appointments.push(aP);
    })
    }else{
      this.appointments = [];
    }
  }

 renderAppointment(e){
    e.appointmentElement.style.backgroundColor = this.db.corVendedor;
  }

  onAppointmentFormOpening(e:any){
    e.popup.option("toolbarItems[0].options.text", "Salvar");
    const form = e.form;
    let formItems = form.option("items");
    let mainGroupItems = form.itemOption('mainGroup').items;
    let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
    if(indice >= 0){
        formItems.splice(indice,1)
      }
      mainGroupItems.forEach((item:any,idx:any) => {
        if(item.itemType == 'group'){
          item.items.forEach((ii:any) => {
            if(ii.dataField == 'dayLong' || ii.dataField == 'repeat'){
              mainGroupItems.splice(idx,1)
            }
          })
        }
      })
     if(!mainGroupItems.find(function(i){return i.dataField === "telefone" })){
        mainGroupItems.push({
            colSpan: 2, 
            label: { text: "Telefone" },
            editorType: "dxTextBox",
            dataField: "telefone"
        });
        form.itemOption('mainGroup', 'items', mainGroupItems);
      }
      if(!formItems.find(function(i){return i.dataField === "local"})){
          formItems.push({
              colSpan: 2,
              label: { text: "Local" },
              editorType: "dxTextBox",
              dataField: "local"
          });
          form.option("items", formItems);
      }
      if(!formItems.find(function(i){return i.dataField === "criador"})){
        formItems.push({
            colSpan: 2,
            label: { text: "Criador" },
            editorType: "dxTextBox",
            dataField: "criador",
            editorOptions: {
              readOnly: true
            },
        });
        form.option("items", formItems);
      }
  
}
/* 
 onAppointmentFormOpening(e:any){
  e.popup.option("toolbarItems[0].options.text", "Salvar");
  const form = e.form;
  let formItems = form.option("items");
  let mainGroupItems = form.itemOption('mainGroup').items;
  mainGroupItems.forEach((item:any,idx:any) => {
    if(item.itemType == 'group'){
      item.items.forEach((ii:any) => {
        if(ii.dataField == 'dayLong' || ii.dataField == 'repeat'){
          mainGroupItems.splice(idx,1)
        }
      })
    }
  })
 if(!mainGroupItems.find(function(i){return i.dataField === "telefone" })){
      mainGroupItems.push({
          colSpan: 2, 
          label: { text: "Telefone" },
          editorType: "dxTextBox",
          dataField: "telefone"
      });
      form.itemOption('mainGroup', 'items', mainGroupItems);
  }
  if(!formItems.find(function(i){return i.dataField === "local"})){
      formItems.push({
          colSpan: 2,
          label: { text: "Local" },
          editorType: "dxTextBox",
          dataField: "local"
      });
      form.option("items", formItems);
  }
  if(!formItems.find(function(i){return i.dataField === "criador"})){
    formItems.push({
        colSpan: 2,
        label: { text: "Criador" },
        editorType: "dxTextBox",
        dataField: "criador",
        editorOptions: {
          readOnly: true
        },
    });
    form.option("items", formItems);
    }
 } */

 addEvento(e:any){
  if(this.onOff.isOnline){
    let evento = e.appointmentData;
    let rdm = Math.random().toString(32).slice(-8);
    evento.idUser = localStorage.getItem('idUserVendas');
    evento.criador = localStorage.getItem('usuarioVendas');
    evento.idCriador = localStorage.getItem('idUserVendas');
    evento.vendedor = localStorage.getItem('usuarioVendas');
    evento.id = rdm;
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.postEvento(evento).subscribe(res => {
      console.log(res);
      this.db.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((dataVendedor:any) => {
        this.db.agendaVendedor = dataVendedor.agenda;
        let dados = {
          "menu" : "AGENDA - VENDAS", 
          "descricao" : "Agenda criada por "+localStorage.getItem('usuarioVendas')+" para "+localStorage.getItem('usuarioVendas')+" - "+evento.title, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.postLogs(dados).subscribe(result => {
          console.log(result)
          this.getAgenda();
        })
      }, err => console.log(err))
    }, err => console.log(err))
    }else{
      alert("Somente é permitido visualizar a Agenda OFFLINE, para Criação e Atualização por favor fique ONLINE!")
    }
  }

 attEvento(e:any){
  if(this.onOff.isOnline){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let cor:any = e.oldData.color?.replace('#','%23');
    e.newData.color = cor;
    this.db.patchEvento(e.newData.idUser,e.newData.id,e.newData).subscribe(res => {
      console.log(res);
      this.db.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((dataVendedor:any) => {
        this.db.agendaVendedor = dataVendedor.agenda;
        let dados = {
          "menu" : "AGENDA - VENDAS", 
          "descricao" : "Agenda editada por "+localStorage.getItem('usuarioVendas')+" para "+e.newData.vendedor+" - "+e.oldData.title, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.postLogs(dados).subscribe(result => {
          console.log(result)
          this.getAgenda();
        })
      }, err => console.log(err))
    }, err => console.log(err))
  }else{
    alert("Somente é permitido visualizar a Agenda OFFLINE, para Criação e Atualização por favor fique ONLINE!")
  }
 } 

 delEvento(e:any){
  if(this.onOff.isOnline){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.deleteEvento(e.appointmentData.idUser,e.appointmentData.id).subscribe(res => {
      console.log(res);
      this.db.getVendedorById(localStorage.getItem('idUserVendas')).subscribe((dataVendedor:any) => {
        this.db.agendaVendedor = dataVendedor.agenda;
        let dados = {
          "menu" : "AGENDA - VENDAS", 
          "descricao" : "Agenda excluida por "+localStorage.getItem('usuarioVendas')+" do "+e.appointmentData.vendedor+" - "+e.appointmentData.title, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.postLogs(dados).subscribe(result => {
          console.log(result)
          this.getAgenda();
        })
      }, err => console.log(err));
    }, err => console.log(err));
  }else{
    alert("Somente é permitido visualizar a Agenda OFFLINE, para Criação e Atualização por favor fique ONLINE!")
  }
}

openModalAgenda(id:any){
  let i = this.db.agendaVendedor.map((a) => {
    return a.id;
  }).indexOf(id);
  if(i >= 0){
    this.agendaEditar = this.db.agendaVendedor[i];
    this.agendaEditar.startDate = this.db.agendaVendedor[i].startDate.split('T')[0];
    this.agendaEditar.endDate = this.db.agendaVendedor[i].endDate.split('T')[0];
    let modalAgenda = document.getElementById('modalEditAgenda');
    modalAgenda.style.display = 'block';
    let m = document.getElementById('boxxx');
    m.style.overflowY = 'hidden';
    m.scrollTop = 0;
  }
}

closeModalAgenda(){
    let m = document.getElementById('boxxx');
    m.style.overflowY = 'auto';
    m.scrollTop = 0;
    this.agendaEditar = [];
    let modalAgenda = document.getElementById('modalEditAgenda');
    modalAgenda.style.display = 'none';
}

editarAgenda(){
  if(this.onOff.isOnline){
    let idAgenda = this.agendaEditar.id;
    let idUser = this.agendaEditar.idUser;
    this.agendaEditar.starDate = this.agendaEditar.startDate+'T03:00:00.000Z';
    this.agendaEditar.endDate = this.agendaEditar.endDate+'T03:00:00.000Z';
    this.db.patchEvento(idUser,idAgenda,this.agendaEditar).subscribe(res => {
      console.log(res);
      this.closeModalAgenda();
    }, err => console.log(err))
  }else{
    alert("Somente é permitido visualizar a Agenda OFFLINE, para Criação e Atualização por favor fique ONLINE!")
  }
}

openModalNovaAgenda(){
    let modalNewAgenda = document.getElementById('modalNovaAgenda');
    modalNewAgenda.style.display = 'block';
    modalNewAgenda.scrollTop = 0;
    let m = document.getElementById('boxxx');
    m.style.overflowY = 'hidden';
    m.scrollTop = 0;
}

closeModalNovaAgenda(){
  let m = document.getElementById('boxxx');
  m.style.overflowY = 'auto';
  m.scrollTop = 0;
  this.agendaEditar = [];
  let modalNewAgenda = document.getElementById('modalNovaAgenda');
  modalNewAgenda.style.display = 'none';
}

criarAgenda(){
  if(this.onOff.isOnline){
    let rdm = Math.random().toString(32).slice(-8);
    let obj = {
      color: "",
      description: this.agendaDescricao,
      endDate: this.agendaEnd+'T03:00:00.000Z',
      startDate: this.agendaStart+'T03:00:00.000Z',
      id: rdm,
      idUser: this.idVendedorEditar,
      title: this.agendaTitulo,
      criador: localStorage.getItem('usuarioVendas'),
      idCriador: localStorage.getItem('idUserVendas'),
      vendedor: this.vendedorEditar,
      }
      this.db.agendaVendedor.push(obj);
      this.db.postEvento(obj).subscribe(res => {
        console.log(res);
        let data = new Date(Date.now() - 10800000).toISOString().split('T')[0];
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          id : Math.random().toString(36).slice(-8),
          data: data,
          hora: hora,
          tipo: "agenda",
          prioridade:"normal",
          idAgenda: rdm,
          mensagem: this.agendaTitulo,
          usuario:localStorage.getItem('usuarioVendas')
        }
          this.db.enviarChatFunil(this.idVendedorEditar,this.idNegociacaoEditar,body).subscribe(res => {
            console.log(res);
            this.closeModalNovaAgenda();
            this.chatEditar.push(body);
            this.separarMensagens();
            let msgBox = document.getElementById('msgBox');
            if(msgBox){
              setTimeout(() => {
                msgBox.scrollTop = msgBox.scrollHeight;
              },200)
            }
        }, err => console.log(err))
      }, err => console.log(err))
    }else{
      alert("Somente é permitido visualizar a Agenda OFFLINE, para Criação e Atualização por favor fique ONLINE!")
    }
  }

  separarMensagens(){
    this.mensagens = [];
    this.mensagensFixadas = [];
    this.chatEditar.forEach((c) => {
      if(c.prioridade == 'fixado'){
        this.mensagensFixadas.push(c)
      }else{
        this.mensagens.push(c)
      }
    })
  }

  uncheck(e:any){
    let checks:any = document.getElementsByClassName('checkCheck');
    for(let i = 0; i < checks.length;i++){
      if(checks[i].id != e.srcElement.id){
        checks[i].checked = false;
      }
    }
    setTimeout(() => {
      document.addEventListener('click', function(event:any) {
        if(event.target.className == "dropRelatorio"){
          console.log("clicou dentro do menu")
        }else{
          if(event.target.className != "checkCheck"){
            let checks:any = document.getElementsByClassName('checkCheck');
              for(let i = 0; i < checks.length;i++){
                  checks[i].checked = false;
              }
            }
         }  
        });
    },1000)    
  }

  uncheckAll(){
    let checks:any = document.getElementsByClassName('checkCheck');
    for(let i = 0; i < checks.length;i++){
        checks[i].checked = false;
    }
  }

  filtrarRelatorio(dtInicio:any,dtFim:any,vendedor:any){
    if(dtInicio || dtFim || vendedor){
      if(!dtInicio){
        dtInicio = "2000-01-01";
      }
      if(!dtFim){
        dtFim = "2200-01-01";
      }
      this.negociacoes = this.allFunil.filter(item => {
        return (item.vendedor?.toLowerCase().indexOf(vendedor?.toLowerCase()) > -1 && item.dataCriacao >= dtInicio && item.dataCriacao <= dtFim);
      });
    }else{
      this.negociacoes = this.allFunil;
    }
  }

  showTodosRelatorios(){
    this.dataInicioRel = "";
    this.dataFimRel = "";
    this.vendedorRel = "";
    this.negociacoes = this.allFunil;
  }

  sortTable(titulo:any){
    if(titulo == 'valor'){
      if(this.ultimoTitulo == titulo){
        this.negociacoes.sort((a,b) => {
          if(a.valor > b.valor){return -1};
          if(a.valor < b.valor){return 1};
          return 0;
        });
        this.ultimoTitulo = "";
      }else{
        this.negociacoes.sort((a,b) => {
          if(a.valor < b.valor){return -1};
          if(a.valor > b.valor){return 1};
          return 0;
        });
        this.ultimoTitulo = titulo;
      }
    }else if(titulo == 'contato'){
      if(this.ultimoTitulo == titulo){
        this.negociacoes.sort((a,b) => {
          if(a.contatos[0]?.nome > b.contatos[0]?.nome){return -1};
          if(a.contatos[0]?.nome < b.contatos[0]?.nome){return 1};
          return 0;
        });
        this.ultimoTitulo = "";
      }else{
        this.negociacoes.sort((a,b) => {
          if(a.contatos[0]?.nome < b.contatos[0]?.nome){return -1};
          if(a.contatos[0]?.nome > b.contatos[0]?.nome){return 1};
          return 0;
        });
        this.ultimoTitulo = titulo;
      }
    }else{
      if(this.ultimoTitulo == titulo){
        this.negociacoes.sort((a,b) => {
          if(a[titulo] > b[titulo]){return -1};
          if(a[titulo] < b[titulo]){return 1};
          return 0;
        });
        this.ultimoTitulo = "";
      }else{
        this.negociacoes.sort((a,b) => {
          if(a[titulo] < b[titulo]){return -1};
          if(a[titulo] > b[titulo]){return 1};
          return 0;
        });
        this.ultimoTitulo = titulo;
      }
    }
  }


}