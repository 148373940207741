import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MASKS } from 'ng-brazil';
import { DataBaseService } from '../../provider.service';
import Dexie from 'dexie';
import { Router } from '@angular/router';
import SignaturePad from 'signature_pad';
import { OnlineOfflineService } from 'src/app/online-offline.service';

@Component({
  selector: 'app-cadastro-conecta',
  templateUrl: './cadastro-conecta.component.html',
  styleUrls: ['./cadastro-conecta.component.scss']
})

export class CadastroConectaComponent implements OnInit, OnDestroy {
codigoEmpresa:any = "";
tipoPlano:any = "";
empresa:any = "";
public MASKS = MASKS;
assinado:boolean = false;
userAssinatura:any = "";
user:any = "";
associados:any = [];
vendedor:any = "";
dataHoje:any = "";
subContrato:any = "";
subContratos:any = [];
planoFisicoCheck:boolean = false;
planoEmpresarialCheck:boolean = false;
semAssinatura:boolean = false;
cpf:any = "";
estadoCivil:any = "";
nascimento:any = "";
nome:any = "";
telefone:any = "";
celular:any = "";
rua:any = "";
numero:any = "";
complemento:any = "";
bairro:any = "";
municipio:any = "";
estado:any = "";
email:any = "";
mensalidade:number = 0;
totalMensalidade:number = 0;
taxaAdesao:number = 0;
diaVencimento:number = 0;
sexo:any = "";
cep:any = "";
plano:any = "";
cartaoSelecionado:any = "";
cp:any = "";
formaPagamento:any = "";
mesFolhaPagamento:any = "";
dataAdesao:any = "";
whatsapp:boolean = false;
nomeCartao:any = "";
numeroCartao:any = "";
validadeCartao:any = "";
codigoCartao:any = "";
cpfCheck:boolean;
bloqueioPagamentos:boolean = false;
editaAdesao:any = "";
editaMensalidade:any = "";
editaTotal:any = "";
files:any = [];
selectedFiles:any = [];
botao:boolean = true;
indicacaoPremiada:boolean = false;
nomeIndicacaoPremiada:any = "";
matriculaIndicacaoPremiada:any = "";
mesIndicacaoPremiada:any = "";
banco:any = "";
agencia:number;
agenciaDigito:any = "";
contaCorrente:number;
contaCorrenteDigito:any = "";
tipoPix:any = "";
pix:any = "";
cpfBanco:any = "";
gerarLinkCartao:boolean = false;
confirmarCartao:boolean = true;
checkDadosBancarios:boolean = false;
checkPix:boolean = false;
checkVindi:boolean = false;
checkConsignet:boolean = false;
semDadosIndicacao:boolean = false;
hibrido:boolean = false;
planosFisicos = [];
planosServidores = [];
planosEmpresariais = [];
possuiAssDoc:boolean = false;
vendaTelefone:boolean = false;
unidadeConsumidora:number = null;
cartoes:any = ["VISA","MASTERCARD","ELO","DINERS CLUB","AMERICAN EXPRESS","HIPERCARD"];
totalAsfDependentes:number = 0;
totalAsfDependentesExtras:number = 0;
valorFuneralExtra:number = 0;
valorFuneralFilhosMaior:number = 0;
funeralExtra:any = "";
loginEconsig:any = "";
senhaEconsig:any = "";
cpfAnalise:boolean = false;
doenca:any = "";
especificarDoenca:any = "";
funeralConjuge:any = "";
funeralFilhosMenor:any = "";
dependentes:FormGroup;
dependentesExtra:FormGroup;
public listaDeDependentes:FormArray;
public listaDeDependentesExtra:FormArray;
get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
maxDependentesConecta:number = 0;

constructor(public db:DataBaseService, private formBuilder: FormBuilder,public router:Router,public onOff: OnlineOfflineService){}
 
 ngOnInit(){
  this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  this.dataAdesao = this.dataHoje;
  this.user = localStorage.getItem('usuarioVendas');
  this.vendedor = localStorage.getItem('usuarioVendas');
  this.associados = [];
  if(!this.onOff.isOnline){
    this.db.initOff();
   }
  this.assinar();  
  this.dependentes = this.formBuilder.group({
    dependente: this.formBuilder.array([])
    });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
    this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
 }

 backToDash(){
   this.router.navigate(['/inicio']);
 }

 selectDoencaSmart(e:any){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c:any;
  if(inner == "SIM" && checked == true){
      this.doenca = "SIM";
      c = <HTMLInputElement>document.getElementById('doencaNaoSmart');
      c.checked = false
  }else if(inner == "NÃO" && checked == true){
      this.doenca = "NÃO";
      c = <HTMLInputElement>document.getElementById('doencaSimSmart');
      c.checked = false
  }else{
    this.doenca = "";
  }
}

 createDependente():FormGroup{
  return this.formBuilder.group({
    tipo:[null, Validators.compose([Validators.required])],
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: null,
    asf:null,
    corretora:false,
    numSorte:null,
    situacao:'ATIVO',
    conecta:null,
    idDoc:null,
    email:null,
    sexo:null
  });
}

addDependente(){
  this.listaDeDependentes.push(this.createDependente());
}

removeDependente(index){
  this.listaDeDependentes.removeAt(index);
  this.selectAsf();
}

createDependenteExtra():FormGroup{
  return this.formBuilder.group({
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: null,
    tipo:[null, Validators.compose([Validators.required])],
    asf:null,
    corretora:false,
    numSorte:null,
    situacao:'ATIVO',
    conecta:null,
    idDoc:null,
    email:null,
    sexo:null
  });
}

addDependenteExtra(){
  this.listaDeDependentesExtra.push(this.createDependenteExtra());
}

removeDependenteExtra(index){
  this.listaDeDependentesExtra.removeAt(index);
  this.selectAsfExtras();
}

selectAsf(){
  if(this.hibrido){
    this.totalAsfDependentes = 0;
    this.totalMensalidade = 0;
    let control = 1;
    if(this.dependentes.value.dependente.length > 0){
        this.dependentes.value.dependente.forEach((item) => {
        if(item.asf == "FUNERAL CONTRATADO"){
          this.totalAsfDependentes += this.valorFuneralFilhosMaior;
        }
          if(control == this.dependentes.value.dependente.length){
            this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
          }else{
            control++
          }
      })
    }else{
      this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
    }
  }
}

 selectAsfExtras(){
  if(this.hibrido){
    this.totalAsfDependentesExtras = 0;
    this.totalMensalidade = 0;
    let control = 1;
    if(this.dependentesExtra.value.dependenteExtra.length > 0){
      this.dependentesExtra.value.dependenteExtra.forEach((item) => {
        if(this.funeralExtra == "SIM" && item.asf == "FUNERAL CONTRATADO"){
          this.totalAsfDependentesExtras += this.valorFuneralExtra;
        }
        if(control == this.dependentesExtra.value.dependenteExtra.length){
          this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
        }else{
          control++
        }
      })
    }else{
      this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade
    }
  }
}

tipoDependenteSmart(i:any){
  if(this.hibrido){
      let tipoInput = (<HTMLInputElement>document.getElementById('tipoSmart'+i)).value;
      let asfInput = (<HTMLInputElement>document.getElementById('asfSmart'+i));
      if(tipoInput == "CÔNJUGE" && this.funeralConjuge == "AUTOMATICO"){
          asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
          this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO');
      }else{
        asfInput.value = "";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('')
      }
      if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenor == "AUTOMATICO"){
        asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
        this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS');
      }
    }
  }

  tipoDependenteExtraSmart(i:any){
    if(this.hibrido){
      let conta = 100+i;
      let asfInput = (<HTMLInputElement>document.getElementById('asfSmartExtra'+conta.toString()));
      if(this.funeralExtra == "AUTOMATICO"){
        asfInput.value = "AUTOMÁTICO SEM CUSTO";
        this.dependentesExtra.get('dependenteExtra.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
      }
    }
  }


ngOnDestroy(){
  this.semAssinatura = false;
  this.subContrato = "";
  this.planoFisicoCheck = false;
  this.planoEmpresarialCheck = false;
  this.cpf = "";
  this.cpfCheck = null;
  this.estadoCivil = "";
  this.nascimento = "";
  this.nome = "";
  this.telefone = "";
  this.celular = "";
  this.rua = "";
  this.numero = "";
  this.complemento = "";
  this.bairro = "";
  this.municipio = "";
  this.estado = "";
  this.email = "";
  this.formaPagamento = "";
  this.mensalidade = 0;
  this.taxaAdesao= 0;
  this.diaVencimento = 0;
  this.mesFolhaPagamento = "";
  this.whatsapp = false;
  this.cep = "";
  this.sexo = "";
  this.listaDeDependentes.clear();
  this.dataAdesao = "";
  this.plano = "";
  this.totalMensalidade = 0;
  this.nomeCartao = "";
  this.numeroCartao = "";
  this.validadeCartao = "";
  this.codigoCartao = "";
  this.bloqueioPagamentos = false;
  this.editaAdesao = "";
  this.editaMensalidade = "";
  this.unidadeConsumidora = null;
  this.hibrido = false;
  this.cpfAnalise = false;
  this.loginEconsig = "";
  this.senhaEconsig = "";
  this.maxDependentesConecta = 0;
}

cadastrarAssociado(){
  this.botao = false;
  this.confirmarCartao = (!this.gerarLinkCartao && this.planoFisicoCheck) ? true : false;
  if(window.confirm("Deseja realmente cadastrar esse Associado?")){
    let descriDocs = this.files.length > 0 ? ' - Com '+this.files.length+' documentos anexados' : '';
    let subs = this.subContrato.nome ? this.subContrato.nome : "";
    let motivoPend = subs == "" ? "AGUARDANDO CADASTRO NA VINDI/CYCLOPAY" : "CONFERIR AUTORIZAÇÃO DE DESCONTO ANEXO E COBRANÇA";
    if(this.taxaAdesao == null){this.taxaAdesao = 0;}
    if(this.mensalidade == null){this.mensalidade = 0;}
    if(this.taxaAdesao == null){this.taxaAdesao = 0;}
    if(this.totalMensalidade == null){this.totalMensalidade = 0;}
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();   
    let dados = {
      matricula:0,
      data: this.dataHoje,
      hora: hora,
      plano : this.plano,
      cpf : this.cpf,
      estadoCivil : this.estadoCivil,
      nascimento : this.nascimento.split('/').reverse().join('-'),
      nome : this.nome,
      sexo: this.sexo,
      telefone : this.telefone,
      celular : this.celular,
      cep: this.cep,
      rua : this.rua,
      numero : this.numero,
      complemento : this.complemento,
      bairro : this.bairro,
      municipio : this.municipio,
      estado : this.estado,
      email:this.email,
      localTrabalho : "",
      funcao : "",
      doenca : "",
      especificarDoenca :  "",
      formaPagamento :  this.formaPagamento,
      mensalidade :  this.mensalidade,
      taxaAdesao :  this.taxaAdesao,
      diaVencimento :  this.diaVencimento,
      mesFolhaPagamento :  this.mesFolhaPagamento,
      semAssinatura : false,
      prefeituraConsignet: this.subContrato?.checkConsignet,
      historicos:[{
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO", 
        "msg" : "ASSOCIADO CADASTRADO!", 
        "usuario" : localStorage.getItem('usuarioVendas')
       }],
      agendamentos:[],
      dataAdesao:this.dataAdesao,
      whatsapp: this.whatsapp,
      dependentes:this.dependentes.get('dependente').value,
      dependentesExtra:this.dependentesExtra.get('dependenteExtra').value,
      nomeCartao : this.nomeCartao,
      numeroCartao : this.numeroCartao,
      validadeCartao : this.validadeCartao,
      codigoCartao : this.codigoCartao,
      idVendedor:localStorage.getItem('idUserVendas'),
      vendedor:this.vendedor,
      totalMensalidade:this.totalMensalidade,
      subContrato : subs,
      planoFisicoCheck: this.planoFisicoCheck,
      planoEmpresarialCheck:this.planoEmpresarialCheck,
      cartaoSelecionado: this.cartaoSelecionado,
      valorCapitalSegurado: 0,
      documentos:[],
      situacao:"PENDENTE",
      motivoPendencia:motivoPend,
      first:true,
      observacaoPendente : "PENDÊNCIA GERADO PELO SISTEMA",
      checkSolicitouCancelamento:false,
      motivoSolicitacaoCancelamento:"",
      indicacaoPremiada: this.indicacaoPremiada,
      nomeIndicacaoPremiada: this.nomeIndicacaoPremiada,
      matriculaIndicacaoPremiada: this.matriculaIndicacaoPremiada,
      mesIndicacaoPremiada: this.mesIndicacaoPremiada,
      banco:this.banco,
      agencia:this.agencia,
      agenciaDigito: this.agenciaDigito,
      contaCorrente: this.contaCorrente,
      contaCorrenteDigito: this.contaCorrenteDigito,
      tipoPix: this.tipoPix,
      pix: this.pix,
      cpfBanco: this.cpfBanco,
      gerarLinkCartao: this.gerarLinkCartao,
      confirmarCartao: this.confirmarCartao,
      checkDadosBancarios : this.checkDadosBancarios,
      checkPix : this.checkPix,
      checkVindi : this.checkVindi,
      checkConsignet : this.checkConsignet,
      semDadosIndicacao : this.semDadosIndicacao,
      empresa:"CONECTA",
      cpfAnalise:this.cpfAnalise,
      vendaTelefone:this.vendaTelefone,
      codigoEmpresa:this.codigoEmpresa,
      unidadeConsumidora:this.unidadeConsumidora,
      hibrido:this.hibrido,
      loginEconsig: this.loginEconsig,
      senhaEconsig: this.replaceStrings(this.senhaEconsig)
    }
    let body = {
      "menu" : "CADASTRAR ASSOCIADOS", 
      "descricao" : "Novo Associado: "+this.nome+descriDocs+" | Sistema App", 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    if(this.onOff.isOnline){
      let idIndicacao:any = "";
      let responsavelIndicacao:any = "";
      let idLinkCartao:any = "";
      let responsavelLinkCartao:any = "";
      let idCartaoVindi:any = "";
      let responsavelCartaoVindi:any = "";
      let idMargemConsignet:any = "";
      let responsavelMargemConsignet:any = "";
      let idBoletoVindi:any = "";
      let responsavelBoletoVindi:any = "";
      let idCarne:any = "";
      let responsavelCarne:any = "";
      let idxIndicacao = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["indicacaoPremiada"]');
      if(idxIndicacao >= 0){
        let dadosIndicacao:any = Object.values(this.db.emails[idxIndicacao])[0];
        idIndicacao = dadosIndicacao.idResponsavel;
        responsavelIndicacao = dadosIndicacao.nomeResponsavel;
      }
      let idxLinkCartao = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["linkCartao"]');
      if(idxLinkCartao >= 0){
        let dadosLinkCartao:any = Object.values(this.db.emails[idxLinkCartao])[0];
        idLinkCartao = dadosLinkCartao.idResponsavel;
        responsavelLinkCartao = dadosLinkCartao.nomeResponsavel;
      }
      let idxCartaoVindi = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["cartaoVindi"]');
      if(idxCartaoVindi >= 0){
        let dadosCartaoVindi:any = Object.values(this.db.emails[idxCartaoVindi])[0];
        idCartaoVindi = dadosCartaoVindi.idResponsavel;
        responsavelCartaoVindi = dadosCartaoVindi.nomeResponsavel;
      }
      let idxMargemConsignet = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["margemConsignet"]');
      if(idxMargemConsignet >= 0){
        let dadosMargemConsignet:any = Object.values(this.db.emails[idxMargemConsignet])[0];
        idMargemConsignet = dadosMargemConsignet.idResponsavel;
        responsavelMargemConsignet = dadosMargemConsignet.nomeResponsavel;
      }
      let idxBoletoVindi = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["boletoVindi"]');
      if(idxBoletoVindi >= 0){
        let dadosBoletoVindi:any = Object.values(this.db.emails[idxBoletoVindi])[0];
        idBoletoVindi = dadosBoletoVindi.idResponsavel;
        responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
      }
      let idxCarne = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["gerarCarne"]');
      if(idxCarne >= 0){
        let dadosCarne:any = Object.values(this.db.emails[idxCarne])[0];
        idCarne = dadosCarne.idResponsavel;
        responsavelCarne = dadosCarne.nomeResponsavel;
      }
      this.db.getConfigs().subscribe((x:any) => {
        this.db.userTop = x[20].userTelemedicina;
        this.db.senhaTop = x[20].passTelemedicina;
        this.db.sandboxTop = x[20].passSandbox;
        let matricula = x[10].matricula;
        let idMatricula = x[10]._id;
        dados.matricula = matricula;
        this.db.getAssociadosMatricula(matricula).subscribe((m:any) => {
          if(m && m.length > 0){
            dados.matricula = matricula+10
          }else{
            dados.matricula = matricula;
          }
        this.db.cadastroProposta(dados).subscribe(res => {
          console.log(res);
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.db.patchMatricula(idMatricula,{'matricula':dados.matricula+1}).subscribe(res => {
              console.log(res);
              if(this.formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'){
                if(this.subContrato?.checkConsignet){
                  let randomConfCard = Math.random().toString(36).slice(-8);
                  let confirmarCartaoAgenda = {
                    "id": randomConfCard,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "CONSULTAR MARGEM NO CONSIGNET",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idMargemConsignet,
                    "responsavel" : responsavelMargemConsignet,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err))
                }else{
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "CONFERIR AUTORIZAÇÃO DE DESCONTO",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idMargemConsignet,
                    "responsavel" : responsavelMargemConsignet,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
                }
              }else{
                if(this.gerarLinkCartao){
                  let randomGeraLink = Math.random().toString(36).slice(-8);
                  let linkCartaoAgenda = {
                    "id": randomGeraLink,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "GERAR LINK CARTÃO",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idLinkCartao,
                    "responsavel" : responsavelLinkCartao,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(linkCartaoAgenda).subscribe(res => console.log(res),err => console.log(err))
                }else{
                  let randomConfCard = Math.random().toString(36).slice(-8);
                  let confirmarCartaoAgenda = {
                    "id": randomConfCard,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "VERIFICAR CADASTRO E COBRANÇA NA VINDI: COBRANÇA RECORRENTE", 
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idCartaoVindi,
                    "responsavel" : responsavelCartaoVindi,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err))
               }
              }
              if(this.cpfAnalise){
                alert("Cliente possui cancelamento por inadimplência - em análise");
                let randomGeraLink = Math.random().toString(36).slice(-8);
                let cpfAnalisar = {
                  "id": randomGeraLink,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "CADASTRO CPF INADIMPLENTE",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idBoletoVindi,
                  "responsavel" : responsavelBoletoVindi,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(cpfAnalisar).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.indicacaoPremiada){
                  let randomInd = Math.random().toString(36).slice(-8);
                  let dadosPgto = dados.checkDadosBancarios ? " - Dados Bancarios: "+dados.banco+" - Agencia: "+dados.agencia+" - "+dados.agenciaDigito+" - Conta: "+dados.contaCorrente+" - "+dados.contaCorrenteDigito+" - CPF: "+dados.cpfBanco : dados.checkPix ? " - Tipo de Pix: "+dados.tipoPix+" - Chave Pix: "+dados.pix : dados.checkConsignet ? " - CONSIGNET" : dados.checkVindi ? " - VINDI" : "";
                  let obsInd:any = dados.semDadosIndicacao == true ? 'Dados Incompletos: ' : '';
                  let indicacaoPrem = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "INDICAÇÃO PREMIADA",
                    "descricao" : obsInd+"Associado: "+ dados.nome + " - Matricula: " + dados.matricula+ " - Quem Indicou: "+ dados.nomeIndicacaoPremiada + " - Matricula: " + dados.matriculaIndicacaoPremiada+ " - Data Crédito: 15/" + dados.mesIndicacaoPremiada.split('-').reverse().join('/') + dadosPgto,
                    "idResponsavel" : idIndicacao,
                    "responsavel" : responsavelIndicacao,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(indicacaoPrem).subscribe(res => console.log(res),err => console.log(err))
              }
              const imagedata = new FormData();
              imagedata.append('files[]', this.userAssinatura);
              for (let i = 0; i < this.selectedFiles.length; i++) {
                imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
              }
              this.db.enviarDocAssociado(res['insertedId'], imagedata).subscribe(
                (res) => {
                  console.log(res);
                  this.botao = true;
                  alert("Cadastro realizado com sucesso!");
                  this.router.navigate(['/inicio']);
                },
                (error) => {
                  console.log(error);
                  this.botao = true;
                  alert("Cadastro realizado com sucesso!");
                  this.router.navigate(['/inicio']);
                }
              );
            }, err => console.log(err))
          }, err => {
            console.log(err)
          })
        }, err => {
          console.log(err);
          alert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
          this.botao = true;
        })
      });
    });
    }else{
      const imagedata = new FormData();
      const filesArray = [];
      imagedata.append('files[]', this.userAssinatura);
      filesArray.push(this.userAssinatura);
      for (let i = 0; i < this.selectedFiles.length; i++) {
        imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
        filesArray.push(this.selectedFiles[i]);
      }
      new Dexie("dados").open().then(function(db) {
        db.table('associados').add({
          dados: dados,
          files: filesArray.length > 1 ? filesArray : "",
          assinatura: this.userAssinatura,
          bodyLog: body
        });
      }.bind(this));
      alert("OFFLINE: Cadastro salvo no dispositivo!");
      this.ngOnDestroy();
      this.router.navigate(['/inicio']);
    }
    }else{
      this.botao = true;
  }
}

cpfUnico(cpf:any){
  this.cpfCheck = true;
  this.cpfAnalise = false;
  let cp = cpf.replace(/\D/g,"");
  if(cp.length == 11){
  if(this.onOff.isOnline){
      this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"cpf":1,"empresa":1',cpf,"CONECTA").subscribe((result:any) => {
      if(result && result.length > 0){
        this.cpfCheck = false;
      }else{
        this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentes.cpf":1,"empresa":1',cpf,"CONECTA").subscribe((resDep:any) => {
          if(resDep && resDep.length > 0){
            this.cpfCheck = false;
          }else{
            this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentesExtra.cpf":1,"empresa":1',cpf,"CONECTA").subscribe((resDepExtra:any) => {
              if(resDepExtra && resDepExtra.length > 0){
                this.cpfCheck = false;
              }else{
              this.cpfCheck = true;      
              this.db.getCanceladoInadimplenteCpf(cpf).subscribe((data:any) => {
                if(data && data.length > 0){
                  this.cpfAnalise = true;
                }
              })
              }
            })
            }
          })
        }
      })
    }else{
      this.cpfCheck = true;      
      this.cpfAnalise = true;
    }
  }
}

buscarCep(cepGet){
  let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
  if(c.length > 7){
    this.db.getCep(c).subscribe((data:any) => {
      let cep = data;
       if(cep.erro == true){
         alert("CEP NAO ENCONTRADO");
       }else{
         this.rua = cep.logradouro.toUpperCase();
         this.bairro = cep.bairro.toUpperCase();
         this.municipio = cep.localidade.toUpperCase();
         this.estado = cep.uf.toUpperCase();
       }
    });
  }
}

escolhePlanoFisico(planoFisico){
  if(planoFisico == true){
    this.subContrato = "";
    this.mensalidade = 0;
    this.taxaAdesao = 0;
  }
}

escolhePlanoEmpresarial(planoEmpresarial){
  if(planoEmpresarial == true){
    this.subContrato = "";
    this.mensalidade = 0;
    this.taxaAdesao = 0;
  }
}

escolherTipoPlano(tipoPlano){
  let dF = <HTMLInputElement>document.getElementById('teledescontoFolha');
  let dR = <HTMLInputElement>document.getElementById('teledebitoRecorrente');
  let bM = <HTMLInputElement>document.getElementById('teleboletoMensal');
  //let dC = <HTMLInputElement>document.getElementById('telecarne');
  let dP = <HTMLInputElement>document.getElementById('telepix');
  dF.checked = false;
  dR.checked = false;
  bM.checked = false;
  dP.checked = false;
  this.bloqueioPagamentos = false;
  this.planosFisicos = [];
  this.planosServidores = [];
  this.planosEmpresariais = [];
  this.formaPagamento = "";
  switch(tipoPlano){
    case 'FISICO' : {
    this.planoFisicoCheck = true;
    this.planoEmpresarialCheck = false;
    this.db.planosFisicos.forEach((item) => {
      if(item.empresaOrigem == 'CONECTA' && item.tipo == 'PLANO FISICO'){
        this.planosFisicos.push(item)
      }
    })
    }
    break;
    case 'SERVIDOR' : {
      this.planoFisicoCheck = false;
      this.planoEmpresarialCheck = false;
      this.db.planosServidores.forEach((item) => {
        if(item.empresaOrigem == 'CONECTA' && item.tipo == 'PLANO SERVIDOR'){
          this.planosServidores.push(item)
        }
      })
    }
    break;
    case 'EMPRESARIAL' : {
      this.planoFisicoCheck = false;
      this.planoEmpresarialCheck = true;
      this.db.planosEmpresariais.forEach((item) => {
        if(item.empresaOrigem == 'CONECTA' && item.tipo == 'PLANO EMPRESARIAL'){
          this.planosEmpresariais.push(item)
        }
      })
    }
    break;
  }
}

selectEmpresa(empresa){
  this.codigoEmpresa = empresa.codigo;
}

escolheSubContrato(subContrato){
  if(subContrato == ""){
    this.plano = "";
    this.formaPagamento = "";
    this.bloqueioPagamentos = false;
  }else{
    this.planoFisicoCheck = false;
    this.planoEmpresarialCheck = false;
  }
}

selectPlano(tipo,plano){
  let dF = <HTMLInputElement>document.getElementById('teledescontoFolha');
  let dR = <HTMLInputElement>document.getElementById('teledebitoRecorrente');
  let bM = <HTMLInputElement>document.getElementById('teleboletoMensal');
 // let dC = <HTMLInputElement>document.getElementById('telecarne');
  let dP = <HTMLInputElement>document.getElementById('telepix');
  dF.checked = false;
  dR.checked = false;
  bM.checked = false;
  //dC.checked = false;
  dP.checked = false;
  this.formaPagamento = "";
  this.mensalidade = 0;
  this.taxaAdesao = 0;
  this.totalMensalidade = 0;
  this.bloqueioPagamentos = false;
  if(tipo == 'empresarial'){
      let i = this.db.planosEmpresariais.map(function(item){
        return item.nome
      }).indexOf(plano);
      if(i >= 0){
        this.mensalidade = this.db.planosEmpresariais[i]?.mensalidade;
        this.editaAdesao = this.db.planosEmpresariais[i]?.editaAdesao;
        this.editaMensalidade = this.db.planosEmpresariais[i]?.editaMensalidade;
        this.editaTotal = this.db.planosEmpresariais[i]?.editaTotal;
        this.maxDependentesConecta = this.db.planosEmpresariais[i]?.maxDependentesConecta;
        if(this.db.planosEmpresariais[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao = this.db.planosEmpresariais[i]?.adesao;
        }
        this.totalMensalidade = this.mensalidade;
        this.hibrido = this.db.planosEmpresariais[i]?.hibrido;
      }
    }
  if(tipo == 'fisico'){
      let i = this.db.planosFisicos.map(function(item){
        return item.nome
      }).indexOf(plano);
      if(i >= 0){
        this.mensalidade = this.db.planosFisicos[i]?.mensalidade;
        this.editaAdesao = this.db.planosFisicos[i]?.editaAdesao;
        this.editaMensalidade = this.db.planosFisicos[i]?.editaMensalidade;
        this.editaTotal = this.db.planosFisicos[i]?.editaTotal;
        this.maxDependentesConecta = this.db.planosFisicos[i]?.maxDependentesConecta;
        if(this.db.planosFisicos[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao = this.db.planosFisicos[i]?.adesao;
        }
        this.totalMensalidade = this.mensalidade;
        this.hibrido = this.db.planosFisicos[i]?.hibrido;
      }
  }
  if(tipo == 'servidor'){
      this.diaVencimento = 15;
      let i = this.db.planosServidores.map((item:any) => {
        return item.nome;
      }).indexOf(plano);
      if(i >= 0){
        this.mensalidade = this.db.planosServidores[i]?.mensalidade;
        this.editaAdesao = this.db.planosServidores[i]?.editaAdesao;
        this.editaMensalidade = this.db.planosServidores[i]?.editaMensalidade;
        this.editaTotal = this.db.planosServidores[i]?.editaTotal;
        this.maxDependentesConecta = this.db.planosServidores[i]?.maxDependentesConecta;
        if(this.db.planosServidores[i]?.adesao == null){
          this.taxaAdesao = 0;
        }else{
          this.taxaAdesao = this.db.planosServidores[i]?.adesao;
        }
        this.totalMensalidade = this.mensalidade;
        this.hibrido = this.db.planosServidores[i]?.hibrido;
        this.formaPagamento = "DESCONTO EM FOLHA DE PAGAMENTO";
        dF.checked = true;
        this.bloqueioPagamentos = true;
      }
    }
}

cartaoSelect(e,index){
  let checked = e.srcElement.checked;
  if(this.cartaoSelecionado != ""){
   let checks = document.getElementsByName('ch');
    for(let i = 0; i < checks.length; i++){
      let c = checks[i] as HTMLInputElement;
      c.checked= false
      if(i+1 == checks.length){
        if(checked){
          let cb = checks[index] as HTMLInputElement;
          cb.checked= true
          this.cartaoSelecionado = e.srcElement.labels[0].innerText;
        }else{
          this.cartaoSelecionado = "";
        }
      }
    }
  }else{
    this.cartaoSelecionado = e.srcElement.labels[0].innerText;
  }
}

selectFormaPagamento(e:any){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let dR = <HTMLInputElement>document.getElementById('teledebitoRecorrente');
  let bM = <HTMLInputElement>document.getElementById('teleboletoMensal');
  //let dC = <HTMLInputElement>document.getElementById('telecarne');
  let dF = <HTMLInputElement>document.getElementById('teledescontoFolha');
  let dP = <HTMLInputElement>document.getElementById('telepix');
  this.mesFolhaPagamento = "";
  if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
    dR.checked = false;
    bM.checked = false;
    //dC.checked = false;
    dP.checked = false;
  }
  if(inner == 'DEBITO RECORRENTE'){
    dF.checked = false;
    bM.checked = false;
   // dC.checked = false;
    dP.checked = false;
  }
  if(inner == 'BOLETO'){
    dF.checked = false;
    dR.checked = false;
    // dC.checked = false;
    dP.checked = false;
  }
/*   if(inner == 'CARNÊ'){
    dF.checked = false;
    dR.checked = false;
    bM.checked = false;
    dP.checked = false;
  } */
  if(inner == 'PIX'){
    dF.checked = false;
    dR.checked = false;
    bM.checked = false;
  }
  if(checked){
    this.formaPagamento = inner;
  }else{
    this.formaPagamento = "";
  }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

replaceStrings(string:any){
  if(!string){return}
  return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
}

reverseReplaceStrings(string:any){
  if(!string){return}
  return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
}

assinar(){
  let signaturePad = new SignaturePad(<HTMLCanvasElement>document.getElementById('telesignature-pad'), {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    penColor: 'rgb(0, 0, 0)'
  });
  let cancelButton = document.getElementById('teleclear');
  let checkbox = document.getElementById("telecheckAss");
  checkbox.addEventListener('change', function(e:any) {
    if(e.target.checked){
      this.assinado = true;
      let data = signaturePad.toDataURL('image/png');
      let blobBin = atob(data.split(',')[1]);
      let array = [];
      for(let i = 0; i < blobBin.length; i++){
        array.push(blobBin.charCodeAt(i));
      }
      let file = new Blob([new Uint8Array(array)], {type: 'image/png'});
      this.userAssinatura = file;
    }else{
      signaturePad.clear();
      this.assinado = false;
      this.userAssinatura = "";
    }
  }.bind(this));
  cancelButton.addEventListener('click',function(){
    signaturePad.clear();
    this.assinado = false;
    this.userAssinatura = "";
    let checkAssinatura = <HTMLInputElement>document.getElementById('telecheckAss');
    checkAssinatura.checked = false;
  }.bind(this));
}

buscarAsssociadoPorMatricula(matricula){
  if(this.onOff.isOnline){
    this.db.getAssociadosMatricula(matricula).subscribe((data:any) => {
      this.nomeIndicacaoPremiada = data[0].nome;
    })
  }
}

qtdeConectaDependentes():number{
  let qtdeConecta:number = 0;
  if(this.dependentes.value.dependente.length > 0){
    for(let i:any = 0; i < this.dependentes.value.dependente.length; i++){
      if(this.dependentes.value.dependente[i].conecta){
        qtdeConecta++;
      }
        if(i+1 == this.dependentes.value.dependente.length){
          if(this.dependentesExtra.value.dependenteExtra.length > 0){
            for(let iE:any = 0; iE < this.dependentesExtra.value.dependenteExtra.length; iE++){
              if(this.dependentesExtra.value.dependenteExtra[iE].conecta){
                qtdeConecta++;
              }
            }
          }
        }
      }
      }else{
        if(this.dependentesExtra.value.dependenteExtra.length > 0){
          for(let iE:any = 0; iE < this.dependentesExtra.value.dependenteExtra.length; iE++){
            if(this.dependentesExtra.value.dependenteExtra[iE].conecta){
              qtdeConecta++;
            }
          }
        }
      }
      return qtdeConecta;
}

}
