import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MASKS } from 'ng-brazil';
import { DataBaseService } from '../../provider.service';
import Dexie from 'dexie';
import { Router } from '@angular/router';
import SignaturePad from 'signature_pad';
import { OnlineOfflineService } from 'src/app/online-offline.service';

@Component({
  selector: 'app-cadastro-seguro',
  templateUrl: './cadastro-seguro.component.html',
  styleUrls: ['./cadastro-seguro.component.scss']
})

export class CadastroSeguroComponent implements OnInit, OnDestroy {
public MASKS = MASKS;
assinado:boolean = false;
userAssinatura:any = "";
user:any = "";
associados:any = [];
vendedor:any = "";
dataHoje:any = "";
subContrato:any = "";
subContratos:any = [];
planoFisicoCheck:boolean = false;
planoEmpresarialCheck:boolean = false;
semAssinatura:boolean = false;
cpf:any = "";
cp:any = "";
estadoCivil:any = "";
nascimento:any = "";
nome:any = "";
telefone:any = "";
celular:any = "";
rua:any = "";
numero:any = "";
complemento:any = "";
bairro:any = "";
municipio:any = "";
estado:any = "";
email:any = "";
localTrabalho:any = "";
funcao:any = "";
doenca:any = "";
especificarDoenca:any = "";
mensalidade:number = 0;
totalMensalidade:number = 0;
taxaAdesao:number = 0;
diaVencimento:number = 0;
sexo:any = "";
cep:any = "";
plano:any = "";
tipoPlano:any = "";
dependentes:FormGroup;
dependentesExtra:FormGroup;
cartaoSelecionado:any = "";
formaPagamento:any = "";
mesFolhaPagamento:any = "";
dataAdesao:any = "";
whatsapp:boolean = false;
nomeCartao:any = "";
numeroCartao:any = "";
validadeCartao:any = "";
codigoCartao:any = "";
cpfCheck:boolean;
matriculaCheck:boolean;
bloqueioPagamentos:boolean = false;
funeralConjuge:any = "";
funeralExtra:any = "";
funeralFilhosMaior:any = "";
funeralFilhosMenor:any = "";
editaAdesao:any = "";
editaMensalidade:any = "";
editaTotal:any = "";
valorCapitalSegurado:number = 0;
valorFuneralConjuge:number = 0;
valorFuneralExtra:number = 0;
valorFuneralFilhosMaior:number = 0;
valorFuneralFilhosMenor:number = 0;
totalAsfDependentes:number = 0;
totalAsfDependentesExtras:number = 0;
files:any = [];
selectedFiles:any = [];
maisSessentaNove:boolean = false;
filhoMenorIdadeCheck:boolean = false;
botao:boolean = true;
pendente:boolean = false;
observacaoPendente:any = "";
motivoPendencia:any = "";
public listaDeDependentes:FormArray;
public listaDeDependentesExtra:FormArray;
get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
indicacaoPremiada:boolean = false;
nomeIndicacaoPremiada:any = "";
matriculaIndicacaoPremiada:any = "";
mesIndicacaoPremiada:any = "";
banco:any = "";
agencia:number;
agenciaDigito:any = "";
contaCorrente:number;
contaCorrenteDigito:any = "";
tipoPix:any = "";
pix:any = "";
cpfBanco:any = "";
gerarLinkCartao:boolean = false;
checkDadosBancarios:boolean = false;
checkPix:boolean = false;
checkVindi:boolean = false;
checkConsignet:boolean = false;
semDadosIndicacao:boolean = false;
unidadeConsumidora:number = null;
cartoes:any = ["VISA","MASTERCARD","ELO","DINERS CLUB","AMERICAN EXPRESS","HIPERCARD"];
cpfAnalise:boolean = false;
limiteASF:number = 0;
loginEconsig:any = "";
senhaEconsig:any = "";
primeiroVencimento:any = "";

constructor(public db:DataBaseService, private formBuilder: FormBuilder,public router:Router,public onOff: OnlineOfflineService){}

 ngOnInit(){  
  this.assinar();   
  this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
  this.dataAdesao = this.dataHoje;
  this.user = localStorage.getItem('usuarioVendas');
   this.vendedor = localStorage.getItem('usuarioVendas');
   this.associados = [];
   this.dependentes = this.formBuilder.group({
    dependente: this.formBuilder.array([])
    });
    this.dependentesExtra = this.formBuilder.group({
      dependenteExtra: this.formBuilder.array([])
    });
    this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
    this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
    if(!this.onOff.isOnline){
      this.db.initOff();
   } 
 }

 backToDash(){
   this.router.navigate(['/inicio']);
 }

createDependente():FormGroup{
  return this.formBuilder.group({
    tipo:[null, Validators.compose([Validators.required])],
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependente(){
  this.listaDeDependentes.push(this.createDependente());
}

removeDependente(index){
  this.listaDeDependentes.removeAt(index);
  this.selectAsf();
}

createDependenteExtra():FormGroup{
  return this.formBuilder.group({
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    tipo:[null, Validators.compose([Validators.required])],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependenteExtra(){
  this.listaDeDependentesExtra.push(this.createDependenteExtra());
}

removeDependenteExtra(index){
  this.listaDeDependentesExtra.removeAt(index);
  this.selectAsfExtras();
}

ngOnDestroy(){
  this.semAssinatura = false;
  this.pendente = false;
  this.observacaoPendente = "";
  this.subContrato = "";
  this.planoFisicoCheck = false;
  this.planoEmpresarialCheck = false;
  this.cpf = "";
  this.cpfCheck = null;
  this.matriculaCheck = null;
  this.estadoCivil = "";
  this.nascimento = "";
  this.nome = "";
  this.telefone = "";
  this.celular = "";
  this.rua = "";
  this.numero = "";
  this.complemento = "";
  this.bairro = "";
  this.municipio = "";
  this.estado = "";
  this.email = "";
  this.localTrabalho = "";
  this.funcao = "";
  this.doenca = "";
  this.especificarDoenca = "";
  this.formaPagamento = "";
  this.mensalidade = 0;
  this.taxaAdesao= 0;
  this.diaVencimento = 0;
  this.mesFolhaPagamento = "";
  this.whatsapp = false;
  this.cep = "";
  this.sexo = "";
  this.listaDeDependentes.clear();
  this.listaDeDependentesExtra.clear();
  this.dataAdesao = "";
  this.plano = "";
  this.totalMensalidade = 0;
  this.nomeCartao = "";
  this.numeroCartao = "";
  this.validadeCartao = "";
  this.codigoCartao = "";
  this.bloqueioPagamentos = false;
  this.funeralConjuge = "";
  this.funeralExtra = "";
  this.funeralFilhosMaior = "";
  this.funeralFilhosMenor = "";
  this.editaAdesao = "";
  this.editaMensalidade = "";
  this.valorCapitalSegurado = 0;
  this.valorFuneralConjuge = 0;
  this.valorFuneralExtra = 0;
  this.valorFuneralFilhosMaior = 0;
  this.valorFuneralFilhosMenor = 0;
  this.unidadeConsumidora = null;
  this.cpfAnalise = false;
  this.limiteASF = 0;
  this.loginEconsig = "";
  this.senhaEconsig = "";
}

cadastrarAssociado(){
  if(window.confirm("Deseja realmente cadastrar esse Associado?")){
    this.botao = false;
    let subs = this.subContrato.nome ? this.subContrato.nome : "";
    if(this.taxaAdesao == null){this.taxaAdesao = 0;}
    if(this.mensalidade == null){this.mensalidade = 0;}
    if(this.totalMensalidade == null){this.totalMensalidade = 0;}
    let jsonDate:any = new Date().toJSON();
    let hora:any = new Date(jsonDate).toLocaleTimeString();
    let sit:any;
    let descr:any;
    let msg:any;
    let descriDocs:any = this.files.length > 0 ? ' - Com '+this.files.length+' documentos anexados' : '';
    if(this.pendente == true){
      sit = "PENDENTE";
      descr = "Novo Associado SEGUROS - PENDENTE: "+this.nome+" | "+this.observacaoPendente;
      msg = "ASSOCIADO CADASTRADO COMO PENDENTE! | "+this.observacaoPendente;
    }else{
      if(this.formaPagamento == "DEBITO RECORRENTE"){
        sit = "PENDENTE";
        descr = "Novo Associado SEGUROS - PENDENTE: "+this.nome;
        msg = "ASSOCIADO CADASTRADO!";
        this.motivoPendencia = "AGUARDANDO CADASTRO NA VINDI/CYCLOPAY"
      }else if(this.formaPagamento == "DESCONTO EM FOLHA DE PAGAMENTO"){
        this.motivoPendencia = "AUTORIZACAO DE DESCONTO";
        sit = "PENDENTE";
        descr = "Novo Associado SEGUROS - PENDENTE: "+this.nome;
        msg = "ASSOCIADO CADASTRADO!";
      }else{
        this.motivoPendencia = "AGUARDANDO CADASTRO NA VINDI/CYCLOPAY";
        sit = "PENDENTE";
        descr = "Novo Associado SEGUROS - PENDENTE: "+this.nome;
        msg = "ASSOCIADO CADASTRADO!";
      }
    }
    let dados = {
      matricula:0,
      data: this.dataHoje,
      hora: hora,
      plano : this.plano,
      cpf : this.cpf,
      estadoCivil : this.estadoCivil,
      nascimento : this.nascimento.split('/').reverse().join('-'),
      nome : this.nome,
      sexo: this.sexo,
      telefone : this.telefone,
      celular : this.celular,
      cep: this.cep,
      rua : this.rua,
      numero : this.numero,
      complemento : this.complemento,
      bairro : this.bairro,
      municipio : this.municipio,
      estado : this.estado,
      email:this.email,
      localTrabalho :  this.localTrabalho,
      funcao : this.funcao,
      doenca : this.doenca,
      especificarDoenca :  this.especificarDoenca,
      formaPagamento :  this.formaPagamento,
      mensalidade :  this.mensalidade,
      taxaAdesao :  this.taxaAdesao,
      diaVencimento :  this.diaVencimento,
      primeiroVencimento: this.primeiroVencimento,
      mesFolhaPagamento :  this.mesFolhaPagamento,
      semAssinatura : false,
      prefeituraConsignet: this.subContrato?.checkConsignet,
      unidadeConsumidora:this.unidadeConsumidora,
      historicos:[{
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO SEGUROS", 
        "msg" : msg, 
        "usuario" : localStorage.getItem('usuarioVendas')
       }],
      agendamentos:[],
      dataAdesao:this.dataAdesao,
      whatsapp: this.whatsapp,
      dependentes:this.dependentes.get('dependente').value,
      dependentesExtra:this.dependentesExtra.get('dependenteExtra').value,
      nomeCartao : this.nomeCartao,
      numeroCartao : this.numeroCartao,
      validadeCartao : this.validadeCartao,
      codigoCartao : this.codigoCartao,
      idVendedor:localStorage.getItem('idUserVendas'),
      vendedor:this.vendedor,
      totalMensalidade:this.totalMensalidade,
      subContrato : subs,
      planoFisicoCheck: this.planoFisicoCheck,
      planoEmpresarialCheck:this.planoEmpresarialCheck,
      cartaoSelecionado: this.cartaoSelecionado,
      valorCapitalSegurado: this.valorCapitalSegurado,
      documentos:[],
      situacao:sit,
      observacaoPendente : this.observacaoPendente,
      checkSolicitouCancelamento:false,
      motivoSolicitacaoCancelamento:"",
      indicacaoPremiada: this.indicacaoPremiada,
      nomeIndicacaoPremiada: this.nomeIndicacaoPremiada,
      matriculaIndicacaoPremiada: this.matriculaIndicacaoPremiada,
      mesIndicacaoPremiada: this.mesIndicacaoPremiada,
      banco:this.banco,
      agencia:this.agencia,
      agenciaDigito: this.agenciaDigito,
      contaCorrente: this.contaCorrente,
      contaCorrenteDigito: this.contaCorrenteDigito,
      tipoPix: this.tipoPix,
      pix: this.pix,
      cpfBanco: this.cpfBanco,
      gerarLinkCartao: this.gerarLinkCartao,
      checkDadosBancarios : this.checkDadosBancarios,
      checkPix : this.checkPix,
      checkVindi : this.checkVindi,
      checkConsignet : this.checkConsignet,
      semDadosIndicacao : this.semDadosIndicacao,
      empresa:"SEGUROS",
      cpfAnalise:this.cpfAnalise,
      loginEconsig: this.loginEconsig,
      senhaEconsig: this.replaceStrings(this.senhaEconsig)
    }
    let body = {
      "menu" : "CADASTRAR ASSOCIADOS", 
      "descricao" : descr+descriDocs+" | Sistema App", 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : this.user
    }
    if(this.onOff.isOnline){
      let idIndicacao:any = "";
      let responsavelIndicacao:any = "";
      let idLinkCartao:any = "";
      let responsavelLinkCartao:any = "";
      let idCartaoVindi:any = "";
      let responsavelCartaoVindi:any = "";
      let idMargemConsignet:any = "";
      let responsavelMargemConsignet:any = "";
      let idBoletoVindi:any = "";
      let responsavelBoletoVindi:any = "";
      let idCarne:any = "";
      let responsavelCarne:any = "";
      let idxIndicacao = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["indicacaoPremiada"]');
      if(idxIndicacao >= 0){
        let dadosIndicacao:any = Object.values(this.db.emails[idxIndicacao])[0];
        idIndicacao = dadosIndicacao.idResponsavel;
        responsavelIndicacao = dadosIndicacao.nomeResponsavel;
      }
      let idxLinkCartao = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["linkCartao"]');
      if(idxLinkCartao >= 0){
        let dadosLinkCartao:any = Object.values(this.db.emails[idxLinkCartao])[0];
        idLinkCartao = dadosLinkCartao.idResponsavel;
        responsavelLinkCartao = dadosLinkCartao.nomeResponsavel;
      }
      let idxCartaoVindi = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["cartaoVindi"]');
      if(idxCartaoVindi >= 0){
        let dadosCartaoVindi:any = Object.values(this.db.emails[idxCartaoVindi])[0];
        idCartaoVindi = dadosCartaoVindi.idResponsavel;
        responsavelCartaoVindi = dadosCartaoVindi.nomeResponsavel;
      }
      let idxMargemConsignet = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["margemConsignet"]');
      if(idxMargemConsignet >= 0){
        let dadosMargemConsignet:any = Object.values(this.db.emails[idxMargemConsignet])[0];
        idMargemConsignet = dadosMargemConsignet.idResponsavel;
        responsavelMargemConsignet = dadosMargemConsignet.nomeResponsavel;
      }
      let idxBoletoVindi = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["boletoVindi"]');
      if(idxBoletoVindi >= 0){
        let dadosBoletoVindi:any = Object.values(this.db.emails[idxBoletoVindi])[0];
        idBoletoVindi = dadosBoletoVindi.idResponsavel;
        responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
      }
      let idxCarne = this.db.emails.map((e:any) => {
        return JSON.stringify(Object.keys(e));
      }).indexOf('["gerarCarne"]');
      if(idxCarne >= 0){
        let dadosCarne:any = Object.values(this.db.emails[idxCarne])[0];
        idCarne = dadosCarne.idResponsavel;
        responsavelCarne = dadosCarne.nomeResponsavel;
      }
      this.db.getConfigs().subscribe((x:any) => {
        let matricula = x[10].matricula;
        let idMatricula = x[10]._id;
        dados.matricula = matricula;
        this.db.getAssociadosMatricula(matricula).subscribe((m:any) => {
          if(m && m.length > 0){
            dados.matricula = matricula+10
          }else{
            dados.matricula = matricula;
          }
        this.db.cadastroProposta(dados).subscribe(res => {
          console.log(res);
          if(this.files.length > 0){
            const imagedata = new FormData();
            for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
            imagedata.append('files[]',this.userAssinatura);
            this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
                console.log(res);
              },(error) => {
                console.log(error);
              });
            }else{
              const imagedata = new FormData();
              imagedata.append('files[]',this.userAssinatura);
              this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
                  console.log(res);
                },(error) => {
                  console.log(error);
              });
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.db.patchMatricula(idMatricula,{'matricula':dados.matricula+1}).subscribe(res => {
              console.log(res);
              if(this.cpfAnalise){
                alert("Cliente possui cancelamento por inadimplência - em análise");
                let randomGeraLink = Math.random().toString(36).slice(-8);
                let cpfAnalisar = {
                  "id": randomGeraLink,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "CADASTRO CPF INADIMPLENTE",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idBoletoVindi,
                  "responsavel" : responsavelBoletoVindi,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(cpfAnalisar).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.indicacaoPremiada){
                let randomInd = Math.random().toString(36).slice(-8);
                let dadosPgto = dados.checkDadosBancarios ? " - Dados Bancarios: "+dados.banco+" - Agencia: "+dados.agencia+" - "+dados.agenciaDigito+" - Conta: "+dados.contaCorrente+" - "+dados.contaCorrenteDigito+" - CPF: "+dados.cpfBanco : dados.checkPix ? " - Tipo de Pix: "+dados.tipoPix+" - Chave Pix: "+dados.pix : dados.checkConsignet ? " - CONSIGNET" : dados.checkVindi ? " - VINDI" : "";
                let obsInd:any = dados.semDadosIndicacao == true ? 'Dados Incompletos: ' : '';
                let indicacaoPrem = {
                  "id": randomInd,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "INDICAÇÃO PREMIADA",
                  "descricao" : obsInd+"Associado: "+ dados.nome + " - Matricula: " + dados.matricula+ " - Quem Indicou: "+ dados.nomeIndicacaoPremiada + " - Matricula: " + dados.matriculaIndicacaoPremiada+ " - Data Crédito: 15/" + dados.mesIndicacaoPremiada.split('-').reverse().join('/') + dadosPgto,
                  "idResponsavel" : idIndicacao,
                  "responsavel" : responsavelIndicacao,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(indicacaoPrem).subscribe(res => console.log(res),err => console.log(err));
              }     
              if(this.formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'){
              if(this.subContrato?.checkConsignet){
                console.warn("SubContrato Consignet")
                let randomConfCard = Math.random().toString(36).slice(-8);
                let confirmarCartaoAgenda = {
                  "id": randomConfCard,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "CONSULTAR MARGEM NO CONSIGNET",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idMargemConsignet,
                  "responsavel" : responsavelMargemConsignet,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
                }else{
                let randomInd = Math.random().toString(36).slice(-8);
                let bolGera = {
                  "id": randomInd,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "CONFERIR AUTORIZAÇÃO DE DESCONTO",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idMargemConsignet,
                  "responsavel" : responsavelMargemConsignet,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
              }                
                }else{
              if(this.gerarLinkCartao){
                let randomGeraLink = Math.random().toString(36).slice(-8);
                let linkCartaoAgenda = {
                  "id": randomGeraLink,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "GERAR LINK CARTÃO",
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idLinkCartao,
                  "responsavel" : responsavelLinkCartao,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(linkCartaoAgenda).subscribe(res => console.log(res),err => console.log(err));
                }
              if(this.formaPagamento == 'BOLETO'){
                console.warn("pagamento Boleto")
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "CADASTRAR BOLETO NA VINDI",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idBoletoVindi,
                    "responsavel" : responsavelBoletoVindi,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.formaPagamento == 'CARNÊ'){
                console.warn("pagamento Carne")
                  let randomInd = Math.random().toString(36).slice(-8);
                  let bolGera = {
                    "id": randomInd,
                    "data" : this.dataHoje,
                    "hora" : hora,
                    "titulo" : "GERAR CARNÊ",
                    "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                    "idResponsavel" : idCarne,
                    "responsavel" : responsavelCarne,
                    "resolvido":false,
                    "dataResolucao" : "",
                    "horaResolucao" : "",
                    "prioridade" : "ALTA",
                    "origem":"Sistema",
                    "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                    "nomeSolicitante":localStorage.getItem('usuarioVendas')
                  }
                  this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
              }
              if(this.formaPagamento == 'DEBITO RECORRENTE' && !this.gerarLinkCartao){
                let rnd = Math.random().toString(36).slice(-8);
                let bolGera = {
                  "id": rnd,
                  "data" : this.dataHoje,
                  "hora" : hora,
                  "titulo" : "VERIFICAR CADASTRO E COBRANÇA NA VINDI: "+this.formaPagamento,
                  "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                  "idResponsavel" : idBoletoVindi,
                  "responsavel" : responsavelBoletoVindi,
                  "resolvido":false,
                  "dataResolucao" : "",
                  "horaResolucao" : "",
                  "prioridade" : "ALTA",
                  "origem":"Sistema",
                  "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                  "nomeSolicitante":localStorage.getItem('usuarioVendas')
                }
                this.db.postTarefa(bolGera).subscribe(res => {
                  console.log(res);
                }, err => {
                  console.log(err)
                })
              }
            }
              alert("Cadastro Realizado com Sucesso!");
              this.router.navigate(['/inicio']);
              this.botao = true;
              }, err => console.log(err))
            }, err => {
              console.log(err)
            })
          }, err => {
            console.log(err);
            alert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
            this.botao = true;
          })
        });
    });
      }else{
        if(this.files.length > 0){
          const imagedata = new FormData();
          for(let i=0; i<this.selectedFiles.length; i++){
            imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)
            if(i == this.selectedFiles.length-1){
                new Dexie("dados").open().then(function(db){
                db.table('associados').add({
                dados:dados,
                files:this.selectedFiles,
                assinatura:this.userAssinatura,
                bodyLog:body
              })
              }.bind(this));
              alert("OFFLINE: Cadastro salvo no dispositivo!");
              this.ngOnDestroy();
              this.router.navigate(['/inicio']);
            }
          };
    }else{
      new Dexie("dados").open().then(function(db){
        db.table('associados').add({
        dados:dados,
        files:"",
        assinatura:this.userAssinatura,
        bodyLog:body
      })
      }.bind(this));
      alert("OFFLINE: Cadastro salvo no dispositivo!");
      this.ngOnDestroy();
      this.router.navigate(['/inicio']);      }
      }
    }else{
      this.botao = true;
  }
}

cpfUnico(cpf:any){
  this.cpfCheck = true;
  this.cpfAnalise = false;
  let cp = cpf.replace(/\D/g,"");
  if(cp.length == 11){
  if(this.onOff.isOnline){
      this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"cpf":1,"empresa":1',cpf,"SEGUROS").subscribe((result:any) => {
      if(result && result.length > 0){
        this.cpfCheck = false;
      }else{
        this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentes.cpf":1,"empresa":1',cpf,"SEGUROS").subscribe((resDep:any) => {
          if(resDep && resDep.length > 0){
            this.cpfCheck = false;
          }else{
            this.db.getAssociadoByCpfEmpresa('"nome":1,"matricula":1,"dependentesExtra.cpf":1,"empresa":1',cpf,"SEGUROS").subscribe((resDepExtra:any) => {
              if(resDepExtra && resDepExtra.length > 0){
                this.cpfCheck = false;
              }else{
              this.cpfCheck = true;      
              this.db.getCanceladoInadimplenteCpf(cpf).subscribe((data:any) => {
                if(data && data.length > 0){
                  this.cpfAnalise = true;
                }
              })
              }
            })
            }
          })
        }
      })
    }else{
      this.cpfCheck = true;      
      this.cpfAnalise = true;
    }
  }
}

matriculaUnica(matricula){
  this.matriculaCheck = true;
   this.db.getAssociadosMatricula(matricula).subscribe((data:any) => {
      if(data.length > 0){
        this.matriculaCheck = false;
        alert("ESTA MATRICULA JÁ ESTA CADASTRADA!");
      }else{
        this.matriculaCheck = true;
      }
    })
}

tipoDependente(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  if(tipoInput == "CÔNJUGE" && this.funeralConjuge == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
  }else{
    asfInput.value = "";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('')
  }
  if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenor == "AUTOMATICO"){
    asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
  }
}

tipoDependenteExtra(i){
  let conta = 100+i;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+conta.toString()));
  if(this.funeralExtra == "AUTOMATICO"){
    asfInput.value = "AUTOMÁTICO SEM CUSTO";
    this.dependentesExtra.get('dependenteExtra.'+i+'.asf').patchValue('AUTOMÁTICO SEM CUSTO')
  }
}

verificaNascimento(i:any){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimento'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(this.verificaIdade(nascimentoInput.value) < 18){
       this.filhoMenorIdadeCheck = true;
       if(this.funeralFilhosMenor == "AUTOMATICO"){
         asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
       }
    }else{
      this.filhoMenorIdadeCheck = false;
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
    }
  }else if(tipoInput != "CÔNJUGE"){
    if(this.verificaIdade(nascimentoInput.value) >= this.limiteASF){
      alert("Idade limite para Assistência Funeral é de "+this.limiteASF);
      asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
    }
  }
}

verificaNascimentoExtra(i:any){
  let asfInput = (<HTMLInputElement>document.getElementById('asf'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimento'+i));
  console.log(this.limiteASF)
  if(this.verificaIdade(nascimentoInput.value) >= this.limiteASF){
    alert("Idade limite para Assistência Funeral é de "+this.limiteASF);
    asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentes.get('dependenteExtra.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
  }
}

selectAsf(){
  this.totalAsfDependentes = 0;
  this.totalMensalidade = 0;
  let control = 1;
  if(this.dependentes.value.dependente.length > 0){
      this.dependentes.value.dependente.forEach((item:any) => {
      if(item.asf == "FUNERAL CONTRATADO"){
        this.totalAsfDependentes += this.valorFuneralFilhosMaior;
      }
        if(control == this.dependentes.value.dependente.length){
          this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
        }else{
          control++
        }
    })
  }else{
    this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
  }
}

 selectAsfExtras(){
  this.totalAsfDependentesExtras = 0;
  this.totalMensalidade = 0;
  let control = 1;
  if(this.dependentesExtra.value.dependenteExtra.length > 0){
    this.dependentesExtra.value.dependenteExtra.forEach((item:any) => {
      if(this.funeralExtra == "SIM" && item.asf == "FUNERAL CONTRATADO"){
        this.totalAsfDependentesExtras += this.valorFuneralExtra;
      }
      if(control == this.dependentesExtra.value.dependenteExtra.length){
        this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
      }else{
        control++
      }
    })
  }else{
    this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade
  }
}

verificaIdade(e:any){
  if(e){
    let d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    let dia_aniversario = data.getDate();
    let anos = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        anos--;
    }
    console.log(anos)
    return anos;
  }
}

buscarCep(cepGet){
  let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
  if(c.length > 7){
    this.db.getCep(c).subscribe((data:any) => {
      let cep = data;
       if(cep.erro == true){
         alert("CEP NAO ENCONTRADO");
       }else{
         this.rua = cep.logradouro.toUpperCase();
         this.bairro = cep.bairro.toUpperCase();
         this.municipio = cep.localidade.toUpperCase();
         this.estado = cep.uf.toUpperCase();
       }
    });
  }
}

escolhePlanoFisico(planoFisico){
  if(planoFisico == true){
    this.subContrato = "";
    this.mensalidade = 0;
    this.taxaAdesao = 0;
  }
}

escolhePlanoEmpresarial(planoEmpresarial){
  if(planoEmpresarial == true){
    this.subContrato = "";
    this.mensalidade = 0;
    this.taxaAdesao = 0;
  }
}

escolheSubContrato(subContrato){
  let dFa = <HTMLInputElement>document.getElementById('descontoFolha');
  dFa.checked = false;
  let dRa = <HTMLInputElement>document.getElementById('debitoRecorrente');
  dRa.checked = false;
  let bMa = <HTMLInputElement>document.getElementById('boletoMensal');
  bMa.checked = false;
  let dCa = <HTMLInputElement>document.getElementById('carne');
  dCa.checked = false;
  if(subContrato == ""){
    this.plano = "";
    this.formaPagamento = "";
    this.bloqueioPagamentos = false;
  }else{
    this.planoFisicoCheck = false;
    this.planoEmpresarialCheck = false;
    this.mensalidade = 0;
    this.taxaAdesao = 0;
  }
}

selectPlano(tipo,plano){
  this.tipoPlano = tipo;
  this.formaPagamento = "";
  this.mensalidade = 0;
  this.taxaAdesao = 0;
  this.totalMensalidade = 0;
  this.mesFolhaPagamento = "";
  this.bloqueioPagamentos = false;
  let dF:any = <HTMLInputElement>document.getElementById('descontoFolha');
  dF.checked = false;
  let dR:any = <HTMLInputElement>document.getElementById('debitoRecorrente');
  dR.checked = false;
  let bM:any = <HTMLInputElement>document.getElementById('boletoMensal');
  bM.checked = false;
  let dC:any = <HTMLInputElement>document.getElementById('carne');
  dC.checked = false; 
  if(tipo == 'fisico'){
      let i = this.db.planosFisicos.map((item:any) => {
        return item.nome
      }).indexOf(plano);
      this.limiteASF = this.db.planosFisicos[i]?.limiteASF;
      this.mensalidade = this.db.planosFisicos[i]?.mensalidade;
      this.funeralConjuge = this.db.planosFisicos[i]?.funeralConjuge;
      this.funeralExtra = this.db.planosFisicos[i]?.funeralExtra;
      this.funeralFilhosMaior = this.db.planosFisicos[i]?.funeralFilhosMaior;
      this.funeralFilhosMenor = this.db.planosFisicos[i]?.funeralFilhosMenor;
      this.editaAdesao = this.db.planosFisicos[i]?.editaAdesao;
      this.editaMensalidade = this.db.planosFisicos[i]?.editaMensalidade;
      this.editaTotal = this.db.planosFisicos[i]?.editaTotal;
      if(this.db.planosFisicos[i]?.adesao == null){
        this.taxaAdesao = 0;
      }else{
        this.taxaAdesao = this.db.planosFisicos[i]?.adesao;
      }
      if(this.db.planosFisicos[i]?.valorCapitalSegurado == null){
        this.valorCapitalSegurado = 0;
      }else{
        this.valorCapitalSegurado = this.db.planosFisicos[i]?.valorCapitalSegurado;
      }
      if(this.db.planosFisicos[i]?.valorFuneralConjuge == null){
        this.valorFuneralConjuge = 0;
      }else{
        this.valorFuneralConjuge = this.db.planosFisicos[i]?.valorFuneralConjuge;
      }
      if(this.db.planosFisicos[i]?.valorFuneralExtra == null){
        this.valorFuneralExtra = 0;
      }else{
        this.valorFuneralExtra = this.db.planosFisicos[i]?.valorFuneralExtra;
      }
      if(this.db.planosFisicos[i]?.valorFuneralFilhosMaior == null){
        this.valorFuneralFilhosMaior = 0;
      }else{
        this.valorFuneralFilhosMaior = this.db.planosFisicos[i]?.valorFuneralFilhosMaior;
      }
      if(this.db.planosFisicos[i]?.valorFuneralFilhosMenor == null){
        this.valorFuneralFilhosMenor = 0;
      }else{
        this.valorFuneralFilhosMenor =  this.db.planosFisicos[i]?.valorFuneralFilhosMenor;
      }
      this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
  }
  if(tipo == 'servidor'){
      this.diaVencimento = 15;
      let i = this.db.planosServidores.map((item:any) => {
        return item.nome
      }).indexOf(plano);
      this.limiteASF = this.db.planosServidores[i]?.limiteASF;
      this.mensalidade = this.db.planosServidores[i]?.mensalidade;
      this.funeralConjuge = this.db.planosServidores[i]?.funeralConjuge;
      this.funeralExtra = this.db.planosServidores[i]?.funeralExtra;
      this.funeralFilhosMaior = this.db.planosServidores[i]?.funeralFilhosMaior;
      this.funeralFilhosMenor = this.db.planosServidores[i]?.funeralFilhosMenor;
      this.editaAdesao = this.db.planosServidores[i]?.editaAdesao;
      this.editaMensalidade = this.db.planosServidores[i]?.editaMensalidade;
      this.editaTotal = this.db.planosServidores[i]?.editaTotal;
      if(this.db.planosServidores[i]?.adesao == null){
        this.taxaAdesao = 0;
      }else{
        this.taxaAdesao = this.db.planosServidores[i]?.adesao;
      }
      if(this.db.planosServidores[i]?.valorCapitalSegurado == null){
        this.valorCapitalSegurado = 0;
      }else{
        this.valorCapitalSegurado = this.db.planosServidores[i]?.valorCapitalSegurado;
      }
      if(this.db.planosServidores[i]?.valorFuneralConjuge == null){
        this.valorFuneralConjuge = 0;
      }else{
        this.valorFuneralConjuge = this.db.planosServidores[i]?.valorFuneralConjuge;
      }
      if(this.db.planosServidores[i]?.valorFuneralExtra == null){
        this.valorFuneralExtra = 0;
      }else{
        this.valorFuneralExtra = this.db.planosServidores[i]?.valorFuneralExtra;
      }
      if(this.db.planosServidores[i]?.valorFuneralFilhosMaior == null){
        this.valorFuneralFilhosMaior = 0;
      }else{
        this.valorFuneralFilhosMaior = this.db.planosServidores[i]?.valorFuneralFilhosMaior;
      }
      if(this.db.planosServidores[i]?.valorFuneralFilhosMenor == null){
        this.valorFuneralFilhosMenor = 0;
      }else{
        this.valorFuneralFilhosMenor =  this.db.planosServidores[i]?.valorFuneralFilhosMenor;
      }
      this.totalMensalidade = this.totalAsfDependentes + this.totalAsfDependentesExtras + this.mensalidade;
      this.formaPagamento = "DESCONTO EM FOLHA DE PAGAMENTO";
      let dFa = <HTMLInputElement>document.getElementById('descontoFolha');
      dFa.checked = true;
      this.bloqueioPagamentos = true;
  }
}

cartaoSelect(e,index){
  let checked = e.srcElement.checked;
  if(this.cartaoSelecionado != ""){
   let checks = document.getElementsByName('ch');
    for(let i = 0; i < checks.length; i++){
      let c = checks[i] as HTMLInputElement;
      c.checked= false
      if(i+1 == checks.length){
        if(checked){
          let cb = checks[index] as HTMLInputElement;
          cb.checked= true
          this.cartaoSelecionado = e.srcElement.labels[0].innerText;
        }else{
          this.cartaoSelecionado = "";
        }
      }
    }
  }else{
    this.cartaoSelecionado = e.srcElement.labels[0].innerText;
  }
}

selectDoenca(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c;
  if(inner == "SIM" && checked == true){
      this.doenca = inner;
      c = <HTMLInputElement>document.getElementById('doencaNao');
      c.checked = false
  }
  if(inner == "NÃO" && checked == true){
      this.doenca = inner;
      c = <HTMLInputElement>document.getElementById('doencaSim');
      c.checked = false
  }
}

selectFormaPagamento(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
    this.formaPagamento = inner;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
    dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensal');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carne');
    dC.checked = false;
  }
  if(inner == 'DEBITO RECORRENTE'){
    this.formaPagamento = inner;
    let dF = <HTMLInputElement>document.getElementById('descontoFolha');
    dF.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensal');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carne');
    dC.checked = false;
    this.mesFolhaPagamento = "";
  }
  if(inner == 'BOLETO'){
    let dF = <HTMLInputElement>document.getElementById('descontoFolha');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
    dR.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carne');
    dC.checked = false;
    this.mesFolhaPagamento = "";
  }
  if(inner == 'CARNÊ'){
    let dF = <HTMLInputElement>document.getElementById('descontoFolha');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrente');
    dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensal');
    bM.checked = false;
    this.mesFolhaPagamento = "";
  }
  if(checked){
    this.formaPagamento = inner;
  }else{
    this.formaPagamento = "";
  }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

replaceStrings(string:any){
  if(!string){return}
  return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
}

reverseReplaceStrings(string:any){
  if(!string){return}
  return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
}

assinar(){
  let signaturePad = new SignaturePad(<HTMLCanvasElement>document.getElementById('signature-pad'), {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    penColor: 'rgb(0, 0, 0)'
  });
  let cancelButton = document.getElementById('clear');
  let checkbox = document.getElementById("checkAss");
  checkbox.addEventListener('change', function(e:any) {
    if(e.target.checked){
      this.assinado = true;
      let data = signaturePad.toDataURL('image/png');
      let blobBin = atob(data.split(',')[1]);
      let array = [];
      for(let i = 0; i < blobBin.length; i++){
        array.push(blobBin.charCodeAt(i));
      }
      let file = new Blob([new Uint8Array(array)], {type: 'image/png'});
      this.userAssinatura = file;
    }else{
      signaturePad.clear();
      this.assinado = false;
      this.userAssinatura = "";
    }
  }.bind(this));
  cancelButton.addEventListener('click',function(){
    signaturePad.clear();
    this.assinado = false;
    this.userAssinatura = "";
    let checkAssinatura = <HTMLInputElement>document.getElementById('checkAss');
    checkAssinatura.checked = false;
  }.bind(this));
}

buscarAsssociadoPorMatricula(matricula){
  if(this.onOff.isOnline){
    this.db.getAssociadosMatricula(matricula).subscribe((data:any) => {
      this.nomeIndicacaoPremiada = data[0].nome;
    })
  }
}

selectSubContrato(k){
 this.checkConsignet = k.checkConsignet;
}


}
