<div class="backblack" id="modalAcesso">
    <div class="inner-black" [ngStyle]="{'width' : !app.isDesktop ? '100%' : '65%'}">
        <div *ngIf="!lead">
            <div class="titulo-mensagem">Cadastrar Acesso Credenciados</div>
            <br>
            <input type="text" class="form-control" placeholder="Nome" [(ngModel)]="nomeAcesso">
            <br>
            <input type="text" class="form-control" placeholder="Celular" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celularAcesso">
            <br>
            <input type="text" class="form-control" placeholder="E-mail" [(ngModel)]="emailAcesso">
        </div>
        <div class="leadOn" *ngIf="lead">
            <div class="row">
                <label>Já existe um Cadastro com este e-mail, deseja renovar o acesso por mais 48h?</label><br><br>
                <div class="col col-6">
                    <input type="text" class="form-control" placeholder="Nome" [(ngModel)]="lead.nome">
                </div>
                <div class="col col-6">
                    <input type="text" class="form-control" placeholder="Celular" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="lead.celular">
                </div>
            </div>
            <div class="row">
                <div class="col col-10">
                    <input type="text" class="form-control" placeholder="E-mail" [(ngModel)]="lead.email" readonly>
                </div>
                <div class="col col-2">
                    <button class="btn btn-success btn-sm" (click)="renovarCadastro()">
                        <fa-icon [icon]="['fas', 'sync-alt']" style="color: white"></fa-icon>
                    </button>
                </div>
            </div>
            <br>
            <div class="table-responsive logs" [ngStyle]="{'font-size' : !app.isDesktop ? '0.85em' : '1em'}">
                <label>Logs</label>
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>#</th>
                            <th>Data</th>
                            <th>Usuário</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let l of lead.logs.reverse(); let i = index">
                        <tr>
                            <td>{{i+1}}</td>
                            <td>{{l.data | date: 'dd/MM/yyyy'}} - {{l.hora}}</td>
                            <td>{{l.usuario}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="botoesBox">
            <button class="btn btn-danger btn-block" (click)="closeModalAcesso()">CANCELAR</button>
            <br>
            <button class="btn btn-success btn-block" (click)="cadastrarEmailLead()" [disabled]="!emailAcesso || lead" *ngIf="!lead">CADASTRAR</button>
        </div>
    </div>
</div>
<!-- MODAL LOADER -->
<div class="modalLoader" *ngIf="load">
    <div class="box">
        <div class="loader">Loading...</div>
       <div style="text-align: center;font-weight: bold;font-size: 1.2em;">CARREGANDO CREDENCIADOS</div>
    </div>
</div>
<!-- FIM MODAL LOADER -->
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            CREDENCIADOS
        </div>
        <div class="col" style="text-align:right">
            <button class="btn btn-sm btn-outline-light" (click)="openModalAcesso()">CADASTRAR ACESSO</button>
        </div>
    </div>
        <div [ngStyle]="{'height' : !app.isDesktop ? '4em' : '5em'}"></div>
        <div class="boxSearch" *ngIf="app.isDesktop">
            <div class="mioloSearch">
                <h4>Faça sua busca utilizando os filtros abaixo</h4>
                <br>
                <div class="row">
                    <div class="col col-4">
                        <label>Cidade</label>
                        <ng-select class="form-control" [items]="db?.cidades" bindLabel="cidade" appendTo="body" [(ngModel)]="cidade"></ng-select>
                    </div>
                    <div class="col col-6">
                        <label>Especialidade</label>
                        <ng-select class="form-control" [items]="db?.especialidades" bindLabel="nome" appendTo="body" [(ngModel)]="especialidade"></ng-select>
                    </div>
                    <div class="col col-2">
                        <br>
                        <button class="btn btn-danger" (click)="procurar(cidade,especialidade)">PESQUISAR</button>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="credenciados?.length > 0">
                    <div class="col" style="text-align: center;color:#074364">
                        <p><strong>{{cidadeProcurada}}</strong></p>
                        <span>{{especialidadeProcurada}}</span>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="credenciados?.length > 0">
                    <div class="col col-6" *ngFor="let credenciado of credenciados" style="margin-bottom: 15px;">
                        <div class="boxCredenciado">
                            <p class="enderecoCred"><strong>{{credenciado?.especialidade}}</strong></p>
                            <p class="nomeCred">{{credenciado?.fantasiaCredenciado}}</p>
                            <p class="enderecoCred">{{credenciado?.rua}}, {{credenciado?.numero}}, {{credenciado?.bairro}}</p>
                            <p class="enderecoCred">{{credenciado?.complemento}}</p>
                            <p class="enderecoCred">{{credenciado?.municipio}}/{{credenciado?.estado}}</p>
                            <p class="telefoneCred" *ngIf="credenciado?.telefoneExibir && !credenciado?.telefone2Exibir">{{credenciado?.telefone}}</p>
                            <p class="telefoneCred" *ngIf="!credenciado?.telefoneExibir && credenciado?.telefone2Exibir">{{credenciado?.telefone2}}</p>
                            <p class="telefoneCred" *ngIf="credenciado?.telefoneExibir && credenciado?.telefone2Exibir">{{credenciado?.telefone}} | {{credenciado?.telefone2}}</p>
                            <p><span class="observacaoCred" *ngIf="credenciado?.amic"> - REDE PARCEIRA AMIC</span><span class="observacaoCred" *ngIf="credenciado?.sindilojas"> - REDE PARCEIRA SINDILOJAS</span><span class="observacaoCred" *ngIf="credenciado?.guiaMedica"> - NECESSIDADE GUIA MÉDICA</span></p>
                            <p class="observacaoCred">{{ credenciado?.observacoesCliente}}</p>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="credenciados?.length == 0 && procurado">
                    <div class="col" class="nenhumResultado">
                        <p>Nenhum Resultado Encontrado!</p>
                    </div>
                </div>
            </div>
        </div>
    <!-- SEARCH MOBILE -->
    <div class="boxSearchMobile" *ngIf="!app.isDesktop">
        <div class="mioloSearchMobile">
            <h5>Faça sua busca utilizando os filtros abaixo</h5>
            <div class="row">
                <div class="col">
                    <label>Cidade</label>
                    <ng-select class="form-control" [items]="db?.cidades" bindLabel="cidade" appendTo="body" [(ngModel)]="cidade"></ng-select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Especialidade</label>
                    <ng-select class="form-control" [items]="db?.especialidades" bindLabel="nome" appendTo="body" [(ngModel)]="especialidade"></ng-select>
                </div>
            </div>
            <br>
            <div class="row" style="text-align: center;">
                <div class="col">
                    <button class="btn btn-danger" (click)="procurar(cidade,especialidade)">PESQUISAR</button>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="credenciados?.length > 0">
                <div class="col" style="text-align: center;color:#074364">
                    <p><strong>{{cidadeProcurada}}</strong></p>
                    <span>{{especialidadeProcurada}}</span>
                </div>
            </div>
            <br>
            <div class="boxesMobile" *ngIf="credenciados?.length > 0">
                <div class="boxCredenciadoMobile" *ngFor="let credenciado of credenciados">
                    <div class="bodyBoxCredenciadoMobile">
                        <p class="nomeCred">{{credenciado?.fantasiaCredenciado}}</p>
                        <p class="enderecoCred">{{credenciado?.rua}}, {{credenciado?.numero}}, {{credenciado?.bairro}}</p>
                        <p class="enderecoCred">{{credenciado?.complemento}}</p>
                        <p class="enderecoCred">{{credenciado?.municipio}}/{{credenciado?.estado}}</p>
                        <p class="telefoneCred" *ngIf="credenciado?.telefoneExibir && !credenciado?.telefone2Exibir">{{credenciado?.telefone}}</p>
                        <p class="telefoneCred" *ngIf="!credenciado?.telefoneExibir && credenciado?.telefone2Exibir">{{credenciado?.telefone2}}</p>
                        <p class="telefoneCred" *ngIf="credenciado?.telefoneExibir && credenciado?.telefone2Exibir">{{credenciado?.telefone}} | {{credenciado?.telefone2}}</p>
                        <p><span class="observacaoCred" *ngIf="credenciado?.amic"> - REDE PARCEIRA AMIC</span><span class="observacaoCred" *ngIf="credenciado?.sindilojas"> - REDE PARCEIRA SINDILOJAS</span><span class="observacaoCred" *ngIf="credenciado?.guiaMedica"> - NECESSIDADE GUIA MÉDICA</span></p>
                        <p class="observacaoCred">{{ credenciado?.observacoesCliente}}</p>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="credenciados?.length == 0 && procurado">
                <div class="col" class="nenhumResultado">
                    <p>Nenhum Resultado Encontrado!</p>
                </div>
            </div>
        </div>
    </div>
    <div style="height: 4em;"></div>
</div>