<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            CANCELADOS
        </div>
    </div>
    <div class="corpo">
        <div class="row">
            <div class="col col-2">
                <label>Data Inicio Cancelamento</label>
                <input type="date" [(ngModel)]="dtInicio" class="form-control">
            </div>
            <div class="col col-2">
                <label>Data Fim Cancelamento</label>
                <input type="date" [(ngModel)]="dtFim" class="form-control">
            </div>
            <div class="col col-3">
                <label>Tipo Plano</label>
                <select class="form-control" [(ngModel)]="tipoPlano">
                    <option value="" selected></option>
                    <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                    <option value="PLANO FÍSICO">PLANO FÍSICO</option>
                    <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Motivo Cancelamento</label>
                <select class="form-control" [(ngModel)]="motivoCancelamento">
                    <option value="" selected></option>
                    <option *ngFor="let mot of motivosCancelamento" [value]="mot.nome">{{mot.nome}}</option>
                </select>
            </div>
            <div class="col col-1">
                <br>
                <button class="btn btn-primary" (click)="buscarCancelados()" [disabled]="!dtInicio || !dtFim">
                    <fa-icon [icon]="['fas', 'search']" style="color: white"></fa-icon>
                </button>
            </div>
        </div>
        <br><br>
        <div class="row" style="margin:5px;font-weight: bold;text-align: center">
            <div class="col">
                Adesão
            </div>
            <div class="col">
                Cancelado
            </div>
            <div class="col">
                Matricula
            </div>
            <div class="col">
                Nome
            </div>
            <div class="col">
                Plano
            </div>
            <div class="col">
                Motivo
            </div>
            <div class="col">
                Celular
            </div>
            <div class="col">
                Dias
            </div>
            <div class="col">
                Follow-Up
            </div>
            <div class="col">
                
            </div>
        </div>
        <div class="row" *ngFor="let rel of relCancelados; let i = index" style="margin:5px;text-align: center;border-bottom: 1px solid #c4c4c4;">
            <div class="col">
                {{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}
            </div>
            <div class="col">
                {{ rel?.dataCancelamento | date: 'dd/MM/yyyy' }}
            </div>
            <div class="col">
                {{ rel?.matricula }}
            </div>
            <div class="col">
                {{ rel?.nome }}
            </div>
            <div class="col">
                <td>{{ rel?.subContrato }} <span *ngIf="rel?.planoFisicoCheck && !rel?.planoEmpresarialCheck">PLANO FISICO</span><span *ngIf="!rel?.planoFisicoCheck && rel?.planoEmpresarialCheck">PLANO EMPRESARIAL</span></td>
            </div>
            <div class="col">
                {{ rel?.motivoCancelamento }}
            </div>
            <div class="col">
                {{ rel?.celular }}
            </div>
            <div class="col">
                {{ rel?.diasCancelado }}
            </div>
            <div class="col" style="font-size:14px">
             {{ rel?.historicos[0]?.data | date:'dd/MM/yyyy' }} - {{ rel?.historicos[0]?.hora }} - {{ rel?.historicos[0]?.motivo }}<br>
             {{ rel?.historicos[0]?.msg }} 
            </div>
            <div class="col">
                <button class="btn btn-success" (click)="mostrarAtendimento(i)">
                    <fa-icon [icon]="['fas', 'plus']" style="color: white"></fa-icon>
                </button>
            </div>
            <div class="col col-12 historicoDisplay" id="historico{{i}}">
                <div class="card" style="margin:14px" *ngFor="let a of rel?.historicos">
                    <h5 class="card-header" style='font-size:16px; background-color: #274562; color:white'>{{a?.motivo}}</h5>
                    <div class="card-body">
                        <h5 class="card-title" style="font-size:16px !important">{{a?.data | date:'dd/MM/yyyy'}} - {{a?.hora}}</h5>
                        <h6 class="card-title" style="font-size:16px !important">Atendente: {{a?.usuario}}</h6>
                        <p class="card-text" style="font-size:16px !important">{{reverseReplaceStrings(a?.msg)}}</p>
                    </div>
                </div>
                <br>
                <textarea [(ngModel)]="mensagem" rows="4" style="width:100%; padding:5px;"></textarea>
                <button class="btn btn-success" (click)="cadastrarHistorico(rel?._id)">ADICIONAR NOVO ATENDIMENTO</button>
                <br><br>
            </div>
        </div>
    </div>
</div>