import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-controle-vendas',
  templateUrl: './controle-vendas.component.html',
  styleUrls: ['./controle-vendas.component.scss']
})
export class ControleVendasComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

}
