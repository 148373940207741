import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from './provider.service';
import { HttpClient } from '@angular/common/http';
import { OnlineOfflineService } from './online-offline.service';

@Injectable()
export class AuthService {
  constructor(public db: DataBaseService,public onOff: OnlineOfflineService, public route:Router, public http:HttpClient) {}

  login(mail, pwd){
    this.db.getLoginByUsuario(mail.toUpperCase(),pwd).toPromise().then((res:any) => {
        if(res == ''){
          alert("Email não cadastrado ou Senha não confere, tente novamente!")
          return;
        }else{
          let user = res[0];
          localStorage.setItem('permissoes',JSON.stringify(user.permissoes));
          if(user.permissoes.vendas == "VER" || user.permissoes.vendas == "EDITAR"){
            let storage = Math.random().toString(36).slice(-8);
            let usuario = user.nome;
            if(!usuario){
               usuario = user.razaoEmpresa;
             }
            let idUser = user._id;
            let tipo = user.tipo;
            localStorage.setItem('tokenVivamaxVendas', JSON.stringify(storage));
            localStorage.setItem('usuarioVendas', usuario);
            localStorage.setItem('idUserVendas', idUser);
            localStorage.setItem('tipoVendas', tipo);
            this.isLoggedIn();
            this.route.navigate(['/inicio']);
            setTimeout(() => {
              location.reload()
            },1000)
          }else{
            alert("Usuário sem acesso permitido ao módulo de Vendas!")
            return;
          }        
      }
    },
      err => {
        if(err.status < 200){
          alert("Por Favor Verifique Sua Conexão com a Internet")
        }
      }
    ).catch(err => console.log(err))
}

  logOut() {
    localStorage.clear();
    this.isLoggedIn();
    this.db.filaEspera = 0;
    this.route.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
}

getToken(): String {
  var token = localStorage.getItem('tokenVivamaxVendas');
  return token ? token : "";
}



}   