import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-cancelados',
  templateUrl: './cancelados.component.html',
  styleUrls: ['./cancelados.component.scss']
})
export class CanceladosComponent implements OnInit {
  relCancelados:any = [];
  motivosCancelamento:any = [];
  dataHoje:any = "";
  dtInicio:any = "";
  dtFim:any = "";
  mensagem:any = "";
  motivoCancelamento:any = "";
  tipoPlano:any = "";

  constructor(public router:Router,public db:DataBaseService) { }

  ngOnInit(): void {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.dtInicio = this.dataHoje;
    this.dtFim = this.dataHoje;
    this.db.getConfigs().subscribe((conf:any) => {
      this.motivosCancelamento = conf[13].motivosCancelamento;
    })
    this.buscarCancelados();
  }

  buscarCancelados(){
    this.relCancelados = [];
    let tipo = "";
    if(this.tipoPlano != ""){
      if(this.tipoPlano == "PLANO SERVIDOR"){
        tipo = '"planoFisicoCheck":false,"planoEmpresarialCheck":false,';
      }
      if(this.tipoPlano == "PLANO EMPRESARIAL"){
        tipo = '"planoFisicoCheck":false,"planoEmpresarialCheck":true,';
      }
      if(this.tipoPlano == "PLANO FÍSICO"){
        tipo = '"planoFisicoCheck":true,"planoEmpresarialCheck":false,';
      }
    }
    this.db.getAssociadosCanceladosTodosVendedor(this.dtInicio,this.dtFim,this.motivoCancelamento,tipo).subscribe((data:any) => {
      let ctrl:number = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.dataCancelamento);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasCancelado = diffDays;
          a.historicos = a.historicos.reverse();
          this.relCancelados.push(a);
          if(ctrl == data.length){
            this.relCancelados.sort((x:any,y:any) => {if(x.diasCancelado < y.diasCancelado){return 1}else{return -1}});
          }else{
            ctrl++;
          }
        })
      }
    })
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  mostrarAtendimento(i:any){
    let hist:any = document.getElementById('historico'+i);
    if(hist.style.display == 'block'){
      hist.style.display = 'none';
    }else{
      hist.style.display = 'block';
    }
  }
  
  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
  }

  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
  }

  cadastrarHistorico(id:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : "FOLLOW-UP", 
      "msg" : this.replaceStrings(this.mensagem), 
      "usuario" : localStorage.getItem('usuarioVendas')
    }
    this.db.newHistorico(id,dados).subscribe(res => {
      console.log(res);
      alert("Atendimento Cadastrado com Sucesso!");
      this.mensagem = "";
      this.buscarCancelados();
    }, err => {
      console.log(err);
      alert("Erro ao cadastrar atendimento, por favor tente novamente!")
    })
  }


}
