import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
    pure: false
})

export class ReplacePipe implements PipeTransform {
    transform(string: any): any {
        if(!string){
            return
        }
        return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&');
    }
}