
<!-- MODAL EDITAR ASSOCIADO -->
<div class="modal" id="modalEditar">
    <!-- MODAL LIGHTBOX -->
    <div id="myModal" class="modalLightbox">
        <span (click)="closeModal()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div> 
    <div style="width: 800px; height: 400px;display: none;">
        <ng2-pdfjs-viewer 
            #pdfViewerOnDemand
            [externalWindow]="true"
            [downloadFileName]="'vivamax.pdf'"
            [openFile]="false"
            [viewBookmark]="false"
            [download]="true">
        </ng2-pdfjs-viewer>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center header">
            <div class="col col-1" (click)="closeModalEditar()" style="text-align: left; cursor: pointer;">
                <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
            </div>
            <div class="col col-11" *ngIf="editaOn">
                EDITAR ASSOCIADO
            </div>
            <div class="col col-11" *ngIf="!editaOn">
                ASSOCIADO
            </div>
        </div>
    <br>
    <div class="corpo">
        <div class="row align-items-center" style="padding:10px">
            <div class="col col-md-12 col-12" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}">
                <label>PROPOSTA Nº</label>
                <input type="number" class="form-control" [(ngModel)]="matriculaEditar" (change)="matriculaUnicaEditar(matriculaEditar)">
            </div>
        </div>
        <br>
        <tabset #staticTabs>
            <tab heading="DADOS PESSOAIS" style="padding:10px;">
            <div style="background-color: #f7e96b; padding:10px" *ngIf="situacaoEditar == 'PENDENTE'">
                <div class="row" style="text-align: center; padding-top:10px; color:black; font-size: 1.2em;font-weight: bold;">
                    <div class="col">
                        CONTRATO PENDENTE
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Observação Pendente</label>
                        <textarea [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-control" rows="4" [(ngModel)]="observacaoPendenteEditar" (change)="salvar('observacaoPendente',observacaoPendenteEditar)"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-5 col-12">
                    <label>Nome Completo:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="nomeEditar" (change)="salvar('nome',nomeEditar)" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-md-2 col-12">
                    <label>Estado Civil:</label>
                    <select [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-control" [(ngModel)]="estadoCivilEditar" (change)="salvar('estadoCivil',estadoCivilEditar)">
                        <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                    </select>
                </div>
                <div class="col col-md-3 col-6">
                    <label>CPF:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="cpfEditar" cpf [textMask]="{mask: MASKS.cpf.textMask}" (change)="cpfUnicoEditar(cpfEditar)">
                </div>
                <div class="col col-md-2 col-6">
                    <label>Data Adesão:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="date" (keyup)="verificaData(dataAdesaoEditar)" class="form-control" [(ngModel)]="dataAdesaoEditar" (change)="salvar('dataAdesao',dataAdesaoEditar)">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col col-md-2 col-6">
                    <label>Nascimento:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="date" (keyup)="verificaData(nascimentoEditar)" class="form-control" [(ngModel)]="nascimentoEditar" (blur)="salvar('nascimento',nascimentoEditar)">
                </div>
                <div class="col col-md-4 col-6">
                    <label>Telefone:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" (change)="salvar('telefone',telefoneEditar)">
                </div>
                <div class="col col-md-4 col-6">
                    <label>Celular:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celularEditar" (change)="salvar('celular',celularEditar)">
                </div>
                <div class="col col-md-2 col-6">
                    <br>
                    <div class="form-check">
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" [(ngModel)]="whatsappEditar" id="whatsEditar" (change)="salvar('whatsapp',whatsappEditar)">
                        <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-label" for="whatsEditar">&nbsp;&nbsp;Whatsapp</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-2 col-6">
                    <label>Sexo:</label>
                    <select [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-control" [(ngModel)]="sexoEditar" (change)="salvar('sexo',sexoEditar)">
                        <option value="MASCULINO">MASCULINO</option>
                        <option value="FEMININO">FEMININO</option>
                    </select>
                </div>
                <div class="col col-md-2 col-6">
                    <label>CEP:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)" (change)="salvar('cep',cepEditar)">
                </div>
                <div class="col col-md-3 col-12">
                    <label>Rua:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('rua',ruaEditar)">
                </div>
                <div class="col col-md-2 col-4">
                    <label>Número:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="numeroEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('numero',numeroEditar)">
                </div>
                <div class="col col-md-3 col-8">
                    <label>Bairro:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('bairro',bairroEditar)">
                </div>
            </div>
            <div class="row">
                <div class="col col-md-2 col-12">
                    <label>Complemento:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('complemento',complementoEditar)">
                </div>
                <div class="col col-md-3 col-9">
                    <label>Município:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="municipioEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('municipio',municipioEditar)">
                </div>
                <div class="col col-md-1 col-3">
                    <label>UF:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('estado',estadoEditar)">
                </div>
                <div class="col col-md-3 col-12">
                    <label>Local Trabalho:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="localTrabalhoEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('localTrabalho',localTrabalhoEditar)">
                </div>
                <div class="col col-md-3 col-12">
                    <label>Função:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="funcaoEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('funcao',funcaoEditar)">
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12 col-12">
                    <label>E-mail:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="emailEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('email',emailEditar)">
                </div>
            </div>
            <br>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div class="row" style="padding-left: 10px;">
                <div class="col col-md-2 col-6">
                    <br>
                    <div class="form-check">
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="doencaSimEditar" (change)="selectDoencaEditar($event)" [checked]="doencaEditar == 'SIM'">
                        <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-label" for="doencaSimEditar" style="margin-left:10px">SIM</label>
                    </div>
                </div>
                <div class="col col-md-2 col-6">
                    <br>
                    <div class="form-check">
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="doencaNaoEditar" (change)="selectDoencaEditar($event)" [checked]="doencaEditar == 'NÃO'">
                        <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">NÃO</label>
                    </div>
                </div>
                <div class="col col-md-8 col-12" *ngIf="doencaEditar == 'SIM'">
                    <label>Especificar:</label>
                    <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="especificarDoencaEditar" oninput="this.value = this.value.toUpperCase()" (change)="salvar('especificarDoenca',especificarDoencaEditar)">
                </div>
            </div>     
        </tab>
        <tab heading="DEPENDENTES" style="padding:10px" [hidden]="empresa == 'CONECTA'">
            <div class="row align-items-center">
                <div class="col col-md-8 col-12">
                    <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
                </div>
            </div>
            <br>
            <form [formGroup]="dependentesEditar" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}">
                <div class="row" formArrayName="dependenteEditar">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentesEditar?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteEditar('')">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesEditar?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dependenteEditar of dependentesEditarFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row align-items-center">
                        <div class="form-group col-md-3 col-12">
                            <label>Tipo:</label>
                            <select class="form-control" formControlName="tipo" id="tipoEditar{{i}}" style="text-align: left;" (change)="tipoDependenteEditar(i)" [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].nome == '' || dependentesEditar.controls.dependenteEditar.value[i].nome == null || dependentesEditar.controls.dependenteEditar.value[i].tipo == '' || dependentesEditar.controls.dependenteEditar.value[i].tipo == null) ? '#dd443e' : null}">
                                <option value=""></option>
                                <option value="CÔNJUGE">CÔNJUGE</option>
                                <option value="FILHO">FILHO</option>
                                <option value="FILHA">FILHA</option>
                            </select>
                        </div>
                        <div class="form-group col-md-5 col-12">
                            <label>Nome:</label>
                            <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentesEditar.controls.dependenteEditar.value[i].nome == '' || dependentesEditar.controls.dependenteEditar.value[i].nome == null || dependentesEditar.controls.dependenteEditar.value[i].tipo == '' || dependentesEditar.controls.dependenteEditar.value[i].tipo == null) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-md-4 col-6">
                            <label>Data Nascimento:</label>
                            <input type="text" class="form-control" formControlName="nascimento" (change)="verificaNascimentoEditar(i)" id="nascimentoEditar{{i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentesEditar.controls.dependenteEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesEditar.controls.dependenteEditar.value[i].cpf == '' || dependentesEditar.controls.dependenteEditar.value[i].cpf == null) || (dependentesEditar.controls.dependenteEditar.value[i].nascimento == '' || dependentesEditar.controls.dependenteEditar.value[i].nascimento == null) || (dependentesEditar.controls.dependenteEditar.value[i].tipo == '' || dependentesEditar.controls.dependenteEditar.value[i].tipo == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-md-3 col-6">
                            <label>CPF:</label>
                            <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfEditar{{i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentesEditar.controls.dependenteEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesEditar.controls.dependenteEditar.value[i].cpf == '' || dependentesEditar.controls.dependenteEditar.value[i].cpf == null) || (dependentesEditar.controls.dependenteEditar.value[i].nascimento == '' || dependentesEditar.controls.dependenteEditar.value[i].nascimento == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-md-4 col-12">
                            <label>ASF:</label>
                            <select class="form-control" formControlName="asf" id="asfEditar{{i}}" (change)="selectAsfEditar()">
                                <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS">FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                <option value="FUNERAL AUTOMÁTICO VITALÍCIO">FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                            </select>
                        </div> 
                        <div class="form-group col-md-2 col-4" style="text-align: right;">
                            <br>
                            <button class="btn btn-outline-danger" (click)="removeDependenteEditar(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-outline-success" (click)="addDependenteEditar('')" [disabled]="((dependentesEditar.controls.dependenteEditar.value[i].tipo == '' || dependentesEditar.controls.dependenteEditar.value[i].tipo == null) || (dependentesEditar.controls.dependenteEditar.value[i].nome == '' || dependentesEditar.controls.dependenteEditar.value[i].nome == null)) || (dependentesEditar.controls.dependenteEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesEditar.controls.dependenteEditar.value[i].nascimento == '' || dependentesEditar.controls.dependenteEditar.value[i].nascimento == null) || ((dependentesEditar.controls.dependenteEditar.value[i].cpf == '' || dependentesEditar.controls.dependenteEditar.value[i].cpf == null))))">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <hr>
                    </div>
                </div>   
            </form>
            <br>
            <div class="row">
                <div class="col">
                    <label>DADOS CADASTRAIS DOS DEPENDENTES EXTRAS</label>
                </div>
            </div>
            <br>
            <form [formGroup]="dependentesExtraEditar" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}">
                <div class="row" formArrayName="dependenteExtraEditar">
                    <div class="form-group col-3" style="text-align: left;" [hidden]="listaDeDependentesExtraEditar?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtraEditar('')">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtraEditar?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dependenteExtraEditar of dependentesExtraEditarFormGroup.controls; let i = index;">
                    <div class="row align-items-center" [formGroupName]="i">
                        <div class="form-group col-md-3 col-12">
                            <label>Tipo:</label>
                            <select class="form-control" formControlName="tipo" id="tipoEditar{{100+i}}" style="text-align: left;" [ngStyle]="{'border-color': (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == null || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null) ? '#dd443e' : null}">
                                <option value=""></option>
                                <option value="MÃE">MÃE</option>
                                <option value="PAI">PAI</option>
                                <option value="SOGRO">SOGRO</option>
                                <option value="SOGRA">SOGRA</option>
                            </select>
                        </div>
                        <div class="form-group col-md-5 col-12">
                            <label>Nome:</label>
                            <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == null || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-md-4 col-6">
                            <label>Data Nascimento:</label>
                            <input type="text" class="form-control" formControlName="nascimento" id="nascimentoEditar{{100+i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null)) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-md-3 col-6">
                            <label>CPF:</label>
                            <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfEditar{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == null)) ? '#dd443e' : null}">
                        </div> 
                        <div class="form-group col-md-4 col-12">
                            <label>ASF:</label>
                            <select class="form-control" formControlName="asf" id="asfEditar{{100+i}}" (change)="selectAsfExtrasEditar()">   
                                <option value=""></option>
                                <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                            </select>
                        </div> 
                        <div class="form-group col-md-2 col-4" style="text-align: right;">
                        <br>
                        <button class="btn btn-outline-danger" type="button" (click)="removeDependenteExtraEditar(i)">
                            <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-outline-success" type="button" (click)="addDependenteExtraEditar('')" [disabled]="((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].tipo == null) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nome == null)) || (dependentesExtraEditar.controls.dependenteExtraEditar.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].nascimento == null) || ((dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == '' || dependentesExtraEditar.controls.dependenteExtraEditar.value[i].cpf == null))))">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                        </button>
                        </div>
                    </div>
                    <hr>
                    </div>
                </div>   
            </form>
            <div class="row align-items-center" style="position: fixed; bottom:0; width: 100%; background-color: #fff;">
                <div class="col col-md-12 col-12" style="text-align: center;">
                    <button [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="btn btn-success btn-block" (click)="salvarDependentes()">
                        <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR DEPENDENTES
                    </button>
                </div>
            </div>
            <br>
        </tab>
        <tab heading="PLANO" style="padding:10px">
            <br>
            <div class="row" *ngIf="codigoEmpresa">
                <div class="col col-2">
                    <label>Código</label>
                    <input type="text" class="form-control" [(ngModel)]="codigoEmpresa" readonly>
                </div>
                <div class="col col-3">
                    <label>Empresa</label>
                    <input type="text" class="form-control" [(ngModel)]="localTrabalhoEditar" readonly>
                </div>
                <div class="col col-3">
                    <label>Plano</label>
                    <input type="text" class="form-control" [(ngModel)]="planoEditar" readonly>
                </div>
                <div class="col col-2">
                    <label>Mensalidade:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="mensalidadeEditar" disabled>
                    </div>
                </div>
                <div class="col col-2">
                    <label>Total Com ASF:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="totalMensalidadeEditar" disabled>
                    </div>
                </div>
            </div>
            <div class="row" [hidden]="codigoEmpresa">
                <div class="col col-mb-2 col-12">
                    <div class="form-check">
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" [(ngModel)]="planoFisicoCheckEditar" id="planoFisicoEditar" [disabled]="subContratoEditar != '' && subContratoEditar != null" (change)="escolhePlanoFisicoEditar(planoFisicoCheckEditar)">
                        <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-label" for="planoFisicoEditar">
                            &nbsp;&nbsp;Plano Físico
                        </label>
                        </div>
                </div>
                <div class="col col-mb-6 col-12">
                    <label>Sub-Contrato</label>
                    <ng-select [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-control" [items]="db.subContratos" [disabled]="planoFisicoCheckEditar" bindLabel="nome" appendTo="body" [(ngModel)]="subContratoEditar" (change)="escolheSubContratoEditar(subContratoEditar)"></ng-select>
                </div>
                <div class="col col-mb-4 col-12" *ngIf="planoFisicoCheckEditar == true" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" >
                    <label>Plano:</label>
                    <select class="form-control" [(ngModel)]="planoEditar" (change)="selectPlanoEditar('fisico',planoEditar,'codigo')" [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                        <option *ngFor="let plan of planosFisicosEditar" [value]="plan.nome">{{plan.nome}}</option>
                    </select>
                </div>
                <div class="col col-mb-4 col-12" *ngIf="subContratoEditar != '' && subContratoEditar != null" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" >
                    <label>Plano:</label>
                    <select class="form-control" [(ngModel)]="planoEditar" (change)="selectPlanoEditar('servidor',planoEditar,'codigo')" [ngStyle]="{'border-color': !planoEditar ? '#dd443e' : '#3cb42c'}">
                        <option *ngFor="let plan of planosServidoresEditar" [value]="plan.nome">{{plan.nome}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row align-items-center" [hidden]="codigoEmpresa"> 
                <div class="col col-mb-2 col-6">
                    <label>Taxa de Adesão:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="number" class="form-control" [(ngModel)]="taxaAdesaoEditar" [disabled]="editaAdesaoEditar == 'NÃO'">
                    </div>
                </div>
                <div class="col col-mb-3 col-6">
                    <label>Mensalidade:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="number" class="form-control" [(ngModel)]="mensalidadeEditar" (change)="calcularDependentes()" [disabled]="editaTotalEditar == 'NÃO'">
                    </div>
                </div>
                <div class="col col-mb-2 col-6">
                    <label>Dia Vencimento:</label>
                    <input type="date" class="form-control" [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" [(ngModel)]="diaVencimentoEditar" [ngStyle]="{'border-color': !diaVencimentoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-mb-3 col-6">
                    <label>Total Mensal:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="number" class="form-control" [(ngModel)]="totalMensalidadeEditar" [disabled]="editaTotalEditar == 'NÃO'">
                    </div>
                </div>
            </div>
            <br>
            <div [hidden]="codigoEmpresa">
                <label>FORMA DE PAGAMENTO</label>    
                <br><br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="descontoFolhaEditar" [disabled]="formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ' || bloqueioPagamentos" (change)="selectFormaPagamentoEditar($event)">
                            <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}"  class="form-check-label" for="descontoFolhaEditar" style="margin-left:15px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-mb-4 col-10">
                            <label>A PARTIR DO MÊS:</label>
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="month" [(ngModel)]="mesFolhaPagamentoEditar" class="form-control">
                        </div>
                    </div>
                    <br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="debitoRecorrenteEditar" [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'CARNÊ'" (change)="selectFormaPagamentoEditar($event)">
                            <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}"  class="form-check-label" for="debitoRecorrenteEditar" style="margin-left:15px">DEBITO RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamentoEditar == 'DEBITO RECORRENTE'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                <div class="row" style="margin-left:20px">
                    <div class="col col-10 col-md-4" *ngFor="let cartao of db.tableCartoes; let i = index" style="padding:10px">
                        <div class="form-check" style="padding:10px">
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="cardEditar{{i}}" name="cards" (change)="cartaoSelectEditar($event,i)" [checked]="this.cartaoSelecionadoEditar == cartao.nome">
                            <label [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}"  class="form-check-label" for="cardEditar{{i}}" style="margin-left:15px">{{cartao.nome}}</label>
                        </div>
                    </div>
                </div>
                <br>
                <div style="padding:10px">
                    <div class="row" *ngIf="cartaoSelecionadoEditar != ''">
                        <div class="col col-12 col-md-4">
                            <label>Nome</label><br><small>(idêntico ao do cartão de crédito)</small>
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="nomeCartaoEditar" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-12 col-md-4">
                            <label>Número do Cartão</label>
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="numeroCartaoEditar" maxlength="19" onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;"> 
                        </div>
                        <div class="col col-6 col-md-2">
                            <label>Validade</label>
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" type="text" class="form-control" [(ngModel)]="validadeCartaoEditar" maxlength="5" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                        </div>
                        <div class="col col-6 col-md-2">
                            <label>Cód. Segurança</label>
                            <input style="pointer-events:none" type="text" class="form-control" [(ngModel)]="codigoCartaoEditar">
                        </div>
                    </div>
                </div>
            </div>
            <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="boletoMensalEditar" [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'DEBITO RECORRENTE' || formaPagamentoEditar == 'CARNÊ'" (change)="selectFormaPagamentoEditar($event)">
                            <label class="form-check-label" for="boletoMensalEditar" style="margin-left:15px">BOLETO</label>
                        </div>
                    </div>
                </div>
                    <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="form-check-input" type="checkbox" id="carneEditar" [disabled]="formaPagamentoEditar == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamentoEditar == 'BOLETO' || formaPagamentoEditar == 'DEBITO RECORRENTE'" (change)="selectFormaPagamentoEditar($event)">
                            <label class="form-check-label" for="carneEditar" style="margin-left:15px">CARNÊ</label>
                        </div>
                    </div>
                </div>
            </div>
            <br><br> 
            <div class="row align-items-center" style="position: fixed; bottom:0; width: 100%; background-color: #fff;">
                <div class="col col-md-12 col-12" style="text-align: center;">
                    <button [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="btn btn-success btn-block" (click)="editarPlano()" [disabled]="!diaVencimentoEditar || !planoEditar || !formaPagamentoEditar">
                        <fa-icon [icon]="['fas', 'save']"></fa-icon>&nbsp;SALVAR ALTERAÇÕES
                    </button>
                </div>
            </div>
        </tab>
        <tab heading="DOCUMENTOS">
            <br>
            <div class="row">
                <div class="col" style="text-align: center; color:#5e5e5e">
                    <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChange($event)">
                        <input [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                    </div>
                    <div class="files-list" *ngFor="let file of files;let i= index">
                        <p>{{ file }}</p>
                    </div> 
                </div>
            </div>
            <br>
            <div class="row" style="text-align: center;">
                <div class="col col-12">
                    <button [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="btn btn-primary btn-block" (click)="uploadFile()" [disabled]="files.length < 1">
                        <fa-icon [icon]="['fas', 'upload']"></fa-icon>&nbsp;&nbsp;ENVIAR
                    </button>
                </div>
            </div>
            <br><br><br>
                    <div class="row justify-content-md-center">
                <div class="col col-mb-3 col-12" *ngFor="let pdf of pdfs;" style="margin: 10px; background-color:#ce2626; color:white; height: 200px; width:90%; text-align: center; border-radius: 10px;">
                    <div class="delButton" name="delButton">
                        <button [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="btn btn-dark btn-sm" (click)="delDoc(pdf.url)"><strong>X</strong></button>
                    </div>
                    <div (click)="openModalPdfViewer(pdf.url)" style="position: relative;left:50%;top:50%;transform: translate(-50%,-50%);cursor:pointer">
                        <fa-icon [icon]="['fas', 'file-pdf']" style="font-size: 5em;"></fa-icon>
                            <br>DOCUMENTO PDF
                    </div>
                </div>
                <div class="col col-mb-3 col-12 img-container" *ngFor="let img of images; let i = index" style="margin: 10px; text-align: center;">
                    <div class="delButton" name="delButton">
                        <button [ngStyle]="{'pointer-events': editaOn ? 'all' : 'none'}" class="btn btn-danger btn-sm" (click)="delDoc(img.url)"><strong>X</strong></button>
                    </div>
                    <img [src]="img.url" (click)="openModalLightBox(img.url, i)" style="border-radius: 10px; height: 250px;">
                </div>
            </div>
            <br><br><br>
        </tab>
        <tab heading="INDICAÇÃO PREMIADA" *ngIf="indicacaoEnviada">
                <div style="padding:10px">
                <div class="row">
                    <div class="col col-2">
                        <label>Matricula</label><br>
                        <div class="input-group">
                            <input type="number" [(ngModel)]="matriculaIndicacaoPremiada" class="form-control">
                            <div class="input-group-prepend">
                            <span class="input-group-text">
                            <fa-icon [icon]="['fas', 'eye']" size="1x" (click)="buscarAsssociadoPorMatricula(matriculaIndicacaoPremiada)" style="cursor:pointer"></fa-icon>
                            </span>
                            </div>
                          </div>
                    </div>
                    <div class="col col-5">
                        <label>Nome de quem indicou</label><br>
                        <input type="text" [(ngModel)]="nomeIndicacaoPremiada" oninput="this.value = this.value.toUpperCase()" class="form-control">
                    </div>
                    <div class="col col-5">
                        <label>Data Crédito no valor da mensalidade:</label><br>
                        <input type="month" maxlength="2" [(ngModel)]="mesIndicacaoPremiada" class="form-control">
                    </div>
                </div>
                <br>
                <div class="row" style="margin-left:25px">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="dadosBanco" [(ngModel)]="checkDadosBancarios" [disabled]="checkPix || checkVindi || checkConsignet">
                            <label class="form-check-label" for="dadosBanco" style="margin-left:20px">DADOS BANCARIOS
                            </label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="pixChave" [(ngModel)]="checkPix" [disabled]="checkDadosBancarios || checkVindi || checkConsignet">
                            <label class="form-check-label" for="pixChave" style="margin-left:20px">CHAVE PIX
                            </label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="vindi" [(ngModel)]="checkVindi" [disabled]="checkDadosBancarios || checkPix || checkConsignet">
                            <label class="form-check-label" for="vindi" style="margin-left:20px">VINDI
                            </label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="consignet" [(ngModel)]="checkConsignet" [disabled]="checkDadosBancarios || checkPix || checkVindi">
                            <label class="form-check-label" for="consignet" style="margin-left:20px">CONSIGNET
                            </label>
                        </div>
                    </div>
                </div>
                <br><br>
                <div *ngIf="checkDadosBancarios">
                    <label>Dados Bancários</label>
                    <div class="row">
                        <div class="col col-4">
                            <label>Banco</label>
                            <input type="text" [(ngModel)]="banco" class="form-control" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Agência</label>
                            <input type="number" min="0" [(ngModel)]="agencia" class="form-control">
                        </div>
                        <div class="col col-1">
                            <label>Digito</label>
                            <input type="text" [(ngModel)]="agenciaDigito" class="form-control">
                        </div>
                        <div class="col col-2">
                            <label>Conta Corrente</label>
                            <input type="number" min="0" [(ngModel)]="contaCorrente" class="form-control">
                        </div>
                        <div class="col col-1">
                            <label>Digito</label>
                            <input type="text" [(ngModel)]="contaCorrenteDigito" class="form-control">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="cpfBanco" [textMask]="{mask: MASKS.cpf.textMask}">
                        </div>
                    </div>
                </div>
                <div *ngIf="checkPix">
                    <div class="row">
                        <div class="col col-4">
                        <label>Tipo de Chave</label>
                        <select [(ngModel)]="tipoPix" class="form-control">
                            <option selected></option>
                            <option value="CNPJ">CNPJ</option>
                            <option value="CPF">CPF</option>
                            <option value="E-MAIL">E-MAIL</option>
                            <option value="TELEFONE">TELEFONE</option>
                        </select>
                        </div>
                        <div class="col col-8">
                            <label>Chave Pix</label>
                            <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CNPJ'" [textMask]="{mask: MASKS.cnpj.textMask}">
                            <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CPF'" [textMask]="{mask: MASKS.cpf.textMask}">
                            <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'E-MAIL'">
                            <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'TELEFONE'" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row" style="padding-top:50px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="solicitarIndicacaoPremiada()">SOLICITAR INDICAÇÃO PREMIADA</button>
                    </div>
                </div>
            </div>
        </tab>
    </tabset> 
    </div>
    <br><br>
    </div>
</div>
<!-- INICIO ASSOCIADOS -->
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            ASSOCIADOS
        </div>
        <div class="col" style="text-align: right;">
            <button class="btn btn-outline-light" (click)="buscarTodos()">
                TODOS
            </button>
        </div> 
    </div>
    <div class="corpo">
        <div class="row align-items-end" style="padding:5px">
            <div class="col col-md-2 col-10">
                <label>Matricula</label>
                <input type="text" class="form-control" [(ngModel)]="matriculaTitular" (keyup)="verificaEnter(matriculaTitular,'matricula',$event)">
            </div>
            <div class="col col-md-1 col-2">
                <button class="btn btn-primary" (click)="buscarMatricula(matriculaTitular)" [disabled]="!matriculaTitular">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
            <div class="col col-md-4 col-10">
                <label>Tipo</label>
                <select class="form-control" [(ngModel)]="tipoAssociado" (CHANGE)="verificaEnter(tipoAssociado,'tipo',$event)">
                    <option value="" selected></option>
                    <option value="PLANO FISICO">PLANO FÍSICO</option>
                    <option value="PLANO SERVIDOR">PLANO SERVIDOR</option>
                    <option value="PLANO EMPRESARIAL">PLANO EMPRESARIAL</option>
                </select>
            </div>
            <div class="col col-md-1 col-2">
                <button class="btn btn-primary" (click)="buscarTipoAssociado(tipoAssociado)" [disabled]="!tipoAssociado">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
            <div class="col col-md-3 col-10">
                <label>Empresa</label>
                <select class="form-control" [(ngModel)]="empresaAssociado" (CHANGE)="verificaEnter(empresaAssociado,'empresa',$event)">
                    <option value="" selected></option>
                    <option value="VIVA MAX">VIVA MAX</option>
                    <option value="CONECTA">CONECTA</option>
                </select>
            </div>
            <div class="col col-md-1 col-2">
                <button class="btn btn-primary" (click)="buscarTipoEmpresa(empresaAssociado)" [disabled]="!empresaAssociado">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
            <div class="col col-md-5 col-10">
                <label>Titular</label>
                <input type="text" class="form-control" [(ngModel)]="nomeTitular" (keyup)="verificaEnter(nomeTitular,'titular',$event)">
            </div>
            <div class="col col-md-1 col-1">
                <button class="btn btn-primary" (click)="buscarTitular(nomeTitular)" [disabled]="!nomeTitular">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
            <div class="col col-md-5 col-10">
                <label>Beneficiário</label>
                <input type="text" class="form-control" [(ngModel)]="nomeBeneficiario" (keyup)="verificaEnter(nomeBeneficiario,'beneficiario',$event)">
            </div>
            <div class="col col-md-1 col-1">
                <button class="btn btn-primary" (click)="buscarBeneficiario(nomeBeneficiario)" [disabled]="!nomeBeneficiario">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
        </div>
        <div class="row" style="color:#5e5e5e;font-weight: bold;text-align: center;" *ngIf="associadosAtivos.length < 1 && associadosPendentes.length < 1">
            <div class="col">
                NÃO HÁ ASSOCIADOS DISPONÍVEIS
            </div>
        </div>
        <div class="row" style="text-align: center;font-weight: bold;" *ngIf="associadosAtivos?.length > 0">
            <div class="col">ASSOCIADOS ATIVOS</div>
        </div>
        <table class="table table-striped" style="text-align: center;" *ngIf="associadosAtivos?.length > 0">
            <thead class="thead-dark">
                <tr>
                    <th>Empresa</th>
                    <th>Matricula</th>
                    <th>Associado</th>
                    <th>Cidade</th>
                    <th>Ver</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let associadoAtivo of associadosAtivos">
                    <td>{{ associadoAtivo?.empresa }}</td>
                    <td>{{ associadoAtivo?.matricula }}</td>
                    <td>{{ associadoAtivo?.nome }}</td>
                    <td>{{ associadoAtivo?.municipio }}</td>
                    <td>
                        <button class="btn btn-outline-success" (click)="openModalEditar(associadoAtivo, 'none','ver')">
                            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row" style="text-align: center;font-weight: bold;" *ngIf="associadosPendentes?.length > 0">
            <div class="col">ASSOCIADOS PENDENTES</div>
        </div>
        <table class="table table-striped" style="text-align: center;" *ngIf="associadosPendentes?.length > 0">
            <thead class="thead-dark">
                <tr>
                    <th>Empresa</th>
                    <th>Matricula</th>
                    <th>Associado</th>
                    <th>Cidade</th>
                    <th>Dias</th>
                    <th>Editar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let associadoPendente of associadosPendentes">
                    <td>{{ associadoPendente?.empresa }}</td>
                    <td>{{ associadoPendente?.matricula }}</td>
                    <td>{{ associadoPendente?.nome }}</td>
                    <td>{{ associadoPendente?.municipio }}</td>
                    <td>{{ associadoPendente?.diasPendente }}</td>
                    <td>
                        <button class="btn btn-outline-success" (click)="openModalEditar(associadoPendente, 'none','editar')">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>