import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider.service';
import { locale, loadMessages } from "devextreme/localization";

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
  pt = {
    "pt": {
        "Yes": "Sim",
        "No": "Não",
        "Cancel": "Cancelar",
        "Clear": "Limpar",
        "Done": "Concluído",
        "Loading": "Carregando ...",
        "Select": "Selecione ...",
        "Search": "Pesquisar ...",
        "Back": "Voltar",
        "OK": "OK",
        "dxScheduler-editorLabelTitle": "Assunto",
        "dxScheduler-editorLabelStartDate": "Data de Início",
        "dxScheduler-editorLabelEndDate": "Data Final",
        "dxScheduler-editorLabelDescription": "Descrição",
        "dxScheduler-editorLabelRecurrence": "Repetir",
        "dxScheduler-openAppointment": "Abrir compromisso",
        "dxScheduler-recurrenceNever": "Nunca",
        "dxScheduler-recurrenceMinutely": "Minutely",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Diariamente",
        "dxScheduler-recurrenceWeekly": "Semanalmente",
        "dxScheduler-recurrenceMonthly": "Mensalmente",
        "dxScheduler-recurrenceYearly": "Anualmente",
        "dxScheduler-recurrenceRepeatEvery": "Todos",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "Fim da repetição",
        "dxScheduler-recurrenceAfter": "Depois de",
        "dxScheduler-recurrenceOn": "A",
        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "hour(s)",
        "dxScheduler-recurrenceRepeatDaily": "dia(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mês(es)",
        "dxScheduler-recurrenceRepeatYearly": "ano(s)",
        "dxScheduler-switcherDay": "Dia",
        "dxScheduler-switcherWeek": "Semana",
        "dxScheduler-switcherWorkWeek": "Dias úteis",
        "dxScheduler-switcherMonth": "Mês",
        "dxScheduler-switcherTimelineDay": "Linha de tempo Dia",
        "dxScheduler-switcherTimelineWeek": "Linha de tempo Semana",
        "dxScheduler-switcherTimelineWorkWeek": "Linha de tempo Dias úteis",
        "dxScheduler-switcherTimelineMonth": "Linha de tempo Mês",
        "dxScheduler-switcherAgenda": "Agenda",
        "dxScheduler-recurrenceRepeatOnDate": "na data",
        "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
        "dxScheduler-allDay": "Todo o dia",
        "dxScheduler-confirmRecurrenceEditMessage": "Deseja editar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Deseja eliminar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceEditSeries": "Editar série",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar série",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Editar compromisso",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar compromisso",
        "dxScheduler-noTimezoneTitle": "Sem fuso horário",
        "dxScheduler-moreAppointments": "{0} mais"
    }
};
  appointments:any = [];
  dataHojeAgenda:any = [];
  todasAgendas:any = [];
  idVendedor:any = "";
  vendedorBusca:any = "TODOS";
  vendedor:any = "";
  vendedores:any = [];
  agendaVendedor:any = [];
  agendaShow:boolean = false;
  todosVendedores:boolean = false;
  tipoUsuario = localStorage.getItem('tipoVendas');
  dataHoje:any = "";

  constructor(public db:DataBaseService,public router:Router){
    loadMessages(this.pt);
    locale(navigator.language);
   }

   backToDash(){
    this.router.navigate(['/inicio']);
  }

  ngOnInit(){
    this.dataHojeAgenda = new Date(Date.now() - 10800000);
    this.appointments = [];
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getUsuarioById(localStorage.getItem('idUserVendas')).subscribe((user:any) => {
      this.vendedor = user;
      if(this.vendedor.equipe && this.vendedor.equipe.length > 0){
        this.vendedores.push(this.vendedor);
        if(this.vendedor.agenda.length > 0){
          let ctr = 1;
          this.vendedor.agenda.forEach((a:any) => {
            a.color = this.vendedor.color;
            this.appointments.push(a);
            if(ctr == this.vendedor.agenda.length){
              this.agendaShow = true;
            }else{
              ctr++;
            }
          })
        }
        let ctrl = 1;
        this.vendedor.equipe.forEach((v:any) => {
          this.db.getUsuarioById(v.id).subscribe((vUser:any) => {
            this.vendedores.push(vUser);
            if(vUser.agenda.length > 0){
              vUser.agenda.forEach((a:any) => {
                a.color = vUser.color;
                this.appointments.push(a);
              })
            }
          })
          if(ctrl == this.vendedor.equipe.length){
            this.todosVendedores = true;
            this.agendaShow = true;
          }else{
            ctrl++;
          }
        })
      }else{
        this.vendedores = this.vendedor;
        let ctrlSolo = 1;
        this.vendedor.agenda.forEach((a:any) => {
          a.color = this.vendedor.color;
          this.appointments.push(a);
          if(ctrlSolo == this.vendedor.agenda.length){
            this.agendaShow = true;
          }else{
            ctrlSolo++;
          }
        })
      }
    })
   }

   getAgenda(){
    if(this.vendedorBusca != "TODOS"){
      this.appointments = [];
      this.todosVendedores = false;
      let i = this.vendedores.map((v:any) => {
        return v.nome;
      }).indexOf(this.vendedorBusca);
      if(i >= 0){
      let ctrlA = 1;
       this.idVendedor = this.vendedores[i]._id;
       this.vendedor = this.vendedores[i];
       if(this.vendedor.agenda && this.vendedor.agenda.length > 0){
        this.vendedor.agenda.forEach((aP:any) => {
          aP.color = this.vendedor.color;
          this.appointments.push(aP);
          if(ctrlA == this.vendedores.length){
            this.agendaShow = true;
          }else{
            ctrlA++;
          }
        })
         this.agendaShow = true;
        }else{
         this.appointments = [];
         this.agendaShow = true;
        }
      }else{
       this.agendaShow = false;
       this.idVendedor = "";
       this.vendedor = [];
      }
    }else{
      this.getAgendaAllVendedores();
    }
   }

   getAgendaAllVendedores(){
    this.appointments = [];
    let ctrl = 1;
    this.vendedores.forEach((item:any) => {
      if(item.agenda && item.agenda.length > 0){
         item.agenda.forEach((a:any) => {
          a.color = item.color;
          this.appointments.push(a);
        })
      }
      if(ctrl == this.vendedores.length){
        this.agendaShow = true;
        this.todosVendedores = true;
      }else{
        ctrl++;
      }
  });
}

  renderAppointment(e:any){
    let i =  this.vendedores.map((m) => {
      return m._id;
    }).indexOf(e.appointmentData.idUser);
    if(i >= 0){
      e.appointmentElement.style.backgroundColor = e.appointmentData.color;
    }
  }

  onAppointmentFormOpening(e:any){
    e.popup.option("toolbarItems[0].options.text", "Salvar");
    const form = e.form;
    let formItems = form.option("items");
    let mainGroupItems = form.itemOption('mainGroup').items;
    if(this.todosVendedores){
      if(!e.appointmentData.vendedor){
        let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
        if(indice >= 0){
          formItems.splice(indice,1)
        }
        if(!formItems.find(function(i){return i.dataField === "vendedor"})){
          formItems.push({
              colSpan: 2,
              isRequired: true,
              label: {text: 'Vendedor'},
              editorType: 'dxSelectBox',
              dataField: 'vendedor',
              editorOptions: {
                items: this.vendedores,
                displayExpr: 'nome',
                valueExpr: '_id'
              }
            });
          form.option("items", formItems);
        }else{
          let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
          if(indice >= 0){
            formItems.splice(indice,1)
          }
          if(!formItems.find(function(i){return i.dataField === "vendedor"})){
            formItems.push({
                colSpan: 2,
                label: { text: "Vendedor" },
                editorType: "dxTextBox",
                dataField: "vendedor",
                editorOptions: {
                  readOnly: true
                },
            });
            form.option("items", formItems);
         }
        }
      }else{
        let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
        if(indice >= 0){
          formItems.splice(indice,1)
        }
        if(!formItems.find(function(i){return i.dataField === "vendedor"})){
          formItems.push({
              colSpan: 2,
              label: { text: "Vendedor" },
              editorType: "dxTextBox",
              dataField: "vendedor",
              editorOptions: {
                readOnly: true
              },
          });
          form.option("items", formItems);
       }
      }
      let mainGroupItems = form.itemOption('mainGroup').items;
      mainGroupItems.forEach((item:any,idx:any) => {
        if(item.itemType == 'group'){
          item.items.forEach((ii:any) => {
            if(ii.dataField == 'dayLong' || ii.dataField == 'repeat'){
              mainGroupItems.splice(idx,1)
            }
          })
        }
      })
      if(!mainGroupItems.find(function(i){return i.dataField === "telefone" })){
          mainGroupItems.push({
              colSpan: 2, 
              label: { text: "Telefone" },
              editorType: "dxTextBox",
              dataField: "telefone"
          });
          form.itemOption('mainGroup', 'items', mainGroupItems);
      }
      if(!formItems.find(function(i){return i.dataField === "local"})){
          formItems.push({
              colSpan: 2,
              label: { text: "Local" },
              editorType: "dxTextBox",
              dataField: "local"              
          });
          form.option("items", formItems);
      }
      if(!formItems.find(function(i){return i.dataField === "criador"})){
        formItems.push({
            colSpan: 2,
            label: { text: "Criador" },
            editorType: "dxTextBox",
            dataField: "criador",
            editorOptions: {
              readOnly: true
            },
        });
        form.option("items", formItems);
      }
    }else{
      if(e.appointmentData.idUser){
        this.idVendedor = e.appointmentData.idUser;
          let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
          if(indice >= 0){
            formItems.splice(indice,1)
          }
          if(!formItems.find(function(i){return i.dataField === "vendedor"})){
            formItems.push({
                colSpan: 2,
                label: { text: "Vendedor" },
                editorType: "dxTextBox",
                dataField: "vendedor",
                editorOptions: {
                  readOnly: true
                },
            });
            form.option("items", formItems);
         }
      }else{
          let indice = formItems.map((x:any) => {return x.dataField}).indexOf("vendedor");
          if(indice >= 0){
            formItems.splice(indice,1)
          }
          formItems.push({
              colSpan: 2,
              isRequired: true,
              label: {text: 'Vendedor'},
              editorType: 'dxSelectBox',
              dataField: 'vendedor',
              editorOptions: {
                items: this.vendedores,
                displayExpr: 'nome',
                valueExpr: '_id'
              }
            });
          form.option("items", formItems);
      }
      mainGroupItems.forEach((item:any,idx:any) => {
        if(item.itemType == 'group'){
          item.items.forEach((ii:any) => {
            if(ii.dataField == 'dayLong' || ii.dataField == 'repeat'){
              mainGroupItems.splice(idx,1)
            }
          })
        }
      })
     if(!mainGroupItems.find(function(i){return i.dataField === "telefone" })){
        mainGroupItems.push({
            colSpan: 2, 
            label: { text: "Telefone" },
            editorType: "dxTextBox",
            dataField: "telefone"
        });
        form.itemOption('mainGroup', 'items', mainGroupItems);
      }
      if(!formItems.find(function(i){return i.dataField === "local"})){
          formItems.push({
              colSpan: 2,
              label: { text: "Local" },
              editorType: "dxTextBox",
              dataField: "local"
          });
          form.option("items", formItems);
      }
      if(!formItems.find(function(i){return i.dataField === "criador"})){
        formItems.push({
            colSpan: 2,
            label: { text: "Criador" },
            editorType: "dxTextBox",
            dataField: "criador",
            editorOptions: {
              readOnly: true
            },
        });
        form.option("items", formItems);
      }
  }
}
   
   addEvento(e:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let evento = e.appointmentData;
    if(this.todosVendedores){
      let idx = this.vendedores.map((ve:any) => {
        return ve._id;
      }).indexOf(evento.vendedor);
      if(idx >= 0){
        evento.idUser = evento.vendedor;
        evento.vendedor = this.vendedores[idx].nome;
        this.vendedores[idx].agenda.push(evento)
      }
     }else{
      evento.vendedor = this.vendedor.nome;
      evento.idUser = this.idVendedor;
     }
     let rdm = Math.random().toString(32).slice(-8);
     evento.id = rdm;
     evento.criador = localStorage.getItem('usuarioVendas');
     evento.idCriador = localStorage.getItem('idUserVendas');
     this.db.postEvento(evento).subscribe(res => {
       console.log(res);
       let dados = {
        "menu" : "AGENDA - VENDAS", 
        "descricao" : "Agenda criada por "+localStorage.getItem('usuarioVendas')+" para "+evento.vendedor+" - "+evento.title, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVendas')
      }
      this.db.postLogs(dados).subscribe(result => {
        console.log(result)
        this.getAgenda();
      })
    }, err => console.log(err)
     )
  }

   attEvento(e:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let cor:any = e.oldData.color.replace('#','%23');
    e.newData.color = cor;
    let evento = e.newData;
    let idAgenda = e.newData.id;
    this.db.patchEvento(e.newData.idUser,idAgenda,evento).subscribe(res => {
      console.log(res);
      let idx = this.vendedores.map((v:any) => {
        return v._id;
      }).indexOf(e.newData.idUser);
      if(idx >= 0){
        let i = this.vendedores[idx].agenda.map((a:any) => {
          return a.id;
        }).indexOf(idAgenda);
        if(i >= 0){
          this.vendedores[idx].agenda[i] = evento;
          let dados = {
            "menu" : "AGENDA - VENDAS", 
            "descricao" : "Agenda editada por "+localStorage.getItem('usuarioVendas')+" do "+e.oldData.vendedor+" - "+e.oldData.title, 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : localStorage.getItem('usuarioVendas')
          }
          this.db.postLogs(dados).subscribe(result => {
            console.log(result)
           this.getAgenda();
          }, err => console.log(err))
        }
      }
    }, err => console.log(err))
   } 

   delEvento(e:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let idAgenda = e.appointmentData.id;
    let idUser = e.appointmentData.idUser;
    this.db.deleteEvento(idUser,idAgenda).subscribe(res => {
      console.log(res);
      let idx = this.vendedores.map((v:any) => {
        return v._id;
      }).indexOf(e.appointmentData.idUser);
      if(idx >= 0){
        let i = this.vendedores[idx].agenda.map((a:any) => {
          return a.id;
        }).indexOf(idAgenda);
        if(i >= 0){
          this.vendedores[idx].agenda.splice(i, 1);
          let dados = {
            "menu" : "AGENDA - VENDAS", 
            "descricao" : "Agenda excluida por "+localStorage.getItem('usuarioVendas')+" do "+e.appointmentData.vendedor+" - "+e.appointmentData.title, 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : localStorage.getItem('usuarioVendas')
          }
          this.db.postLogs(dados).subscribe(result => {
            console.log(result)
            this.getAgenda();
          })        }
      }
    }, err => console.log(err))
  }

}