<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            CADASTRAR ASSOCIADO
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center">           
            <div class="col col-6 col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="planoFisicoCheck" id="planoFisicoCheck" [disabled]="subContrato != '' && subContrato != null" (change)="escolhePlanoFisico(planoFisicoCheck)">
                    <label class="form-check-label" for="planoFisicoCheck" style="margin-left:20px">Plano Físico
                    </label>
                </div>
            </div>
            <div class="col col-12 col-md-9">
                <label>Sub-Contrato:</label>
                <ng-select class="form-control" [items]="db.tableSubContratos" [disabled]="planoFisicoCheck" bindLabel="nome" appendTo="body" [(ngModel)]="subContrato" (change)="selectSubContrato(subContrato)"></ng-select>
            </div>
            <div class="col col-12 col-md-6" *ngIf="planoFisicoCheck">
                <label>Plano Fisico:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('fisico',plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of db.tablePlanosFisicos | filtro:'VIVA MAX'" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-12 col-md-6" *ngIf="subContrato">
                <label>Plano Servidor:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('servidor',plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of db.tablePlanosServidores | filtro:'VIVA MAX'" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
        </div>
        <div class="row" *ngIf="subContrato != '' && subContrato != null">
            <div class="col col-6">
                <label>Login E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="loginEconsig">
            </div>
            <div class="col col-6">
                <label>Senha E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="senhaEconsig">
            </div>
        </div>
        <br>
        <div class="row" *ngIf="plano">
            <div class="col col-12 col-md-2">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="pendente" id="pendenteCheck">
                    <label class="form-check-label" for="pendenteCheck" style="margin-left:20px">Pendente</label>
                </div>
            </div>
            <div class="col col-6 col-md-2">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': !cpfCheck ? '#dd443e' : '#3cb42c'}" (change)="cpfUnico(cpf)">
            </div>
            <div class="col col-6 col-md-2">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': (nascimento.length != 10 || maisSessentaNove) ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-4" *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck)">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-2" *ngIf="pendente || (!maisSessentaNove && nascimento.length == 10 && plano && cpfCheck)">
                <label>Estado Civil:</label>
                <select class="form-control" [(ngModel)]="estadoCivil" [ngStyle]="{'border-color': !estadoCivil ? '#dd443e' : '#3cb42c'}">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                    <option value="CASADO">CASADO</option>
                    <option value="DIVORCIADO">DIVORCIADO</option>
                    <option value="VIÚVO">VIÚVO</option>
                    <option value="SEPARADO">SEPARADO</option>
                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
        </div>
        <div class="row" *ngIf="pendente">
            <div class="col col-12 col-md-4">
                <label>Motivo da Pendência</label>
                <select class="form-control" [(ngModel)]="motivoPendencia" [ngStyle]="{'border-color': !motivoPendencia ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let motivo of db.tableMotivosPendencia" [value]="motivo.nome">{{motivo.nome}}</option>
                </select>
            </div>
            <div class="col col-12 col-md-8">
                <label>Observação Pendente</label>
                <textarea class="form-control" row="2" [(ngModel)]="observacaoPendente" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !observacaoPendente ? '#dd443e' : '#3cb42c'}"></textarea>
            </div>
        </div>
        <div [hidden]="!pendente && (nascimento.length != 10 || !plano || !cpfCheck || !nome || !estadoCivil)">
            <div class="row align-items-center">
                <div class="col col-12 col-md-3">
                    <label>Sexo:</label>
                    <select class="form-control" [(ngModel)]="sexo" [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                        <option value="MASCULINO">MASCULINO</option>
                        <option value="FEMININO">FEMININO</option>
                    </select>
                </div>
                <div class="col col-6 col-md-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone">
                </div>
                <div class="col col-6 col-md-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="whats">
                        <label class="form-check-label" for="whats" style="margin-left:20px">Possui Whatsapp</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-12 col-md-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-2">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-9 col-md-3">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3 col-md-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-3">
                    <label>Local Trabalho:</label>
                    <input type="text" class="form-control" [(ngModel)]="localTrabalho" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-12 col-md-3">
                    <label>Função:</label>
                    <input type="text" class="form-control" [(ngModel)]="funcao" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-12">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email" (keyup)="db.checkEmail(email)" oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email && !db.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
        </div>
        <div [hidden]="!pendente && (!nome || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado)">
            <br>
            <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            <br>
            <form [formGroup]="dependentes">
                <div class="row" formArrayName="dependente">
                    <div class="form-group col col-12 col-md-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col col-12 col-md-9" [hidden]="listaDeDependentes?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col col-12 col-md-3">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipo{{i}}" style="text-align: left;" (change)="tipoDependente(i)" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                </select>
                            </div>
                            <div class="form-group col col-12 col-md-6">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col col-6 col-md-3">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento" (change)="verificaNascimento(i)" id="nascimento{{i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-group col col-6 col-md-4">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf  id="cpf{{i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-group col col-12 col-md-6">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asf{{i}}" (change)="selectAsf()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div> 
                            <div class="form-group col col-12 col-md-2" style="text-align: right;padding-bottom: 1em;">
                                <br>
                                <button class="btn btn-outline-danger" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success" (click)="addDependente()" [disabled]="((dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                            <br>
                            <hr>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <label>DEPENDENTES EXTRAS</label>
            <form [formGroup]="dependentesExtra">
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col col-12 col-md-3" style="text-align: left;" [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col col-12 col-md-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col col-12 col-md-3">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipo{{100+i}}"  (change)="tipoDependenteExtra(i)" style="text-align: left;" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                </select>
                            </div>
                            <div class="form-group col col-12 col-md-6">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col col-6 col-md-3">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento" id="nascimento{{100+i}}" (change)="verificaNascimentoExtra(100+i)" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-group col col-6 col-md-4">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf  id="cpf{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-groupcol col-12 col-md-6">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asf{{100+i}}" (change)="selectAsfExtras()">
                                    <option value=""></option>
                                    <option value="AUTOMÁTICO SEM CUSTO" disabled>AUTOMÁTICO SEM CUSTO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div> 
                            <div class="form-group col col-12 col-md-2" style="text-align: right;padding-bottom: 1em;">
                                <br>
                                <button class="btn btn-outline-danger" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success" (click)="addDependenteExtra()" [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>   
            </form>
            <br>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div style="padding:10px">
                <div class="row">
                    <div class="col col col-6 col-md-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaSim" (change)="selectDoenca($event)">
                            <label class="form-check-label" for="doencaSim" style="margin-left:20px">SIM</label>
                        </div>
                    </div>
                    <div class="col col col-6 col-md-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaNao" (change)="selectDoenca($event)">
                            <label class="form-check-label" for="doencaNao" style="margin-left:20px">NÃO</label>
                        </div>
                    </div>
                </div>    
                <div class="row" *ngIf="doenca == 'SIM'">
                    <div class="col col col-12 col-md-12">
                        <br>
                        <label>Especificar:</label>
                        <input type="text" class="form-control" [(ngModel)]="especificarDoenca" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div> 
            </div>
            <br>
            <label>ADESÃO E MANUTENÇÃO</label> 
            <div class="row">
                <div class="col col-md-3 col-6">
                    <label>Data Adesão</label>
                    <input type="date" class="form-control" [(ngModel)]="dataAdesao" [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-md-3 col-6">
                    <label>Data 1ª Vencimento</label>
                    <input type="date" class="form-control" [(ngModel)]="primeiroVencimento" [ngStyle]="{'border-color': !primeiroVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaAdesao == 'SIM'">
                    <label>Taxa de Adesão:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="taxaAdesao">
                    </div> 
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaAdesao == 'NÃO'">
                    <label>Taxa de Adesão:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="taxaAdesao" readonly>
                    </div> 
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaMensalidade == 'SIM'">
                    <label>Mensalidade:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="mensalidade">
                    </div> 
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaMensalidade == 'NÃO'">
                    <label>Mensalidade:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="mensalidade" readonly>
                    </div> 
                </div>
                <div class="col col-md-3 col-6" *ngIf="tipoPlano != 'servidor'">
                    <label>Dia Vencimento:</label>
                    <input type="date" class="form-control" [(ngModel)]="diaVencimento" [ngStyle]="{'border-color': !diaVencimento ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaTotal == 'SIM'">
                    <label>Total Com ASF:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="totalMensalidade">
                    </div> 
                </div>
                <div class="col col-md-3 col-6" *ngIf="editaTotal == 'NÃO'">
                    <label>Total Com ASF:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                        </div>
                        <input type="number" class="form-control" [(ngModel)]="totalMensalidade" readonly>
                    </div> 
                </div>
            </div>
            <br>
            <div>
                <label>FORMA DE PAGAMENTO</label>    
                <br>
                <div class="row" *ngIf="estado == 'SC'">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="convenioCelesc" [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || bloqueioPagamentos" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="convenioCelesc" style="margin-left:20px">CONVÊNIO CELESC</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'CONVÊNIO CELESC'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-10 col-md-4">
                            <label>UNIDADE CONSUMIDORA:</label>
                            <input type="number" [(ngModel)]="unidadeConsumidora" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="descontoFolha" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || bloqueioPagamentos" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="descontoFolha" style="margin-left:20px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-10 col-md-4">
                            <label>A PARTIR DO MÊS:</label>
                            <input type="month" [(ngModel)]="mesFolhaPagamento" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="debitoRecorrente" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="debitoRecorrente" style="margin-left:20px">DEBITO RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DEBITO RECORRENTE'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <div class="row" style="margin-left:20px">
                        <div class="col col-10 col-md-4" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="card{{i}}" name="ch" (change)="cartaoSelect($event,i)">
                                <label class="form-check-label" for="card{{i}}" style="margin-left: 15px; font-size:0.8em;">{{cartao}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="padding:10px" *ngIf="cartaoSelecionado != ''">
                        <div class="row" style="padding-top:10px">
                            <div class="col col-12 col-md-4">
                                <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                <input type="text" class="form-control" [(ngModel)]="nomeCartao" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>Número do Cartão</label>
                                <input type="text" class="form-control" [(ngModel)]="numeroCartao" maxlength="19" onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;"> 
                            </div>
                            <div class="col col-6 col-md-2">
                                <label>Validade</label>
                                <input type="text" class="form-control" [(ngModel)]="validadeCartao" maxlength="5" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                            </div>
                            <div class="col col-6 col-md-2">
                                <label>Cód. Segurança</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoCartao" maxlength="3">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="geraLink" [(ngModel)]="gerarLinkCartao" [disabled]="nomeCartao  != ''|| numeroCartao  != ''|| validadeCartao  != ''|| codigoCartao != ''">
                                <label class="form-check-label" for="geraLink" style="margin-left:20px">Gerar Link
                                </label>
                            </div>
                        </div>
                    <!--     <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="confirmaCartao" [(ngModel)]="confirmarCartao"  [disabled]="!nomeCartao || !numeroCartao || !validadeCartao || !codigoCartao">
                                <label class="form-check-label" for="confirmaCartao" style="margin-left:20px">Cartão Cadastrado
                                </label>
                            </div>
                        </div> -->
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="boletoMensal" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="boletoMensal" style="margin-left:20px">BOLETO</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="carne" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="carne" style="margin-left:20px">CARNÊ</label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                        </div>
                            <div class="files-list" *ngFor="let file of files;let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                </div>
                <br>
                <div style="text-align: center;width: 100%;">
                        <label>ASSINATURA DO ASSOCIADO</label>
                </div>
                <div class="wrapper" [ngStyle]="{'pointer-events': !assinado ? 'auto' : 'none'}">
                    <canvas id="signature-pad" class="signature-pad" width="360" height="300"></canvas>
                </div>
                <div class="row" style="padding-top:1em;">
                    <div class="col" style="text-align: center;">
                        <button class="btn btn-danger" id="clear">Limpar</button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="checkAss"  id="checkAss" [disabled]="assinado">
                            <label class="form-check-label" for="checkAss" style="margin-left:20px">Reconheço esta assinatura.
                            </label>
                        </div>
                    </div>
                    <div class="col col-3">
                        &nbsp;
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row" style="margin-left:25px">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="indicaSim" [(ngModel)]="indicacaoPremiada">
                            <label class="form-check-label" for="indicaSim" style="margin-left:20px">É INDICAÇÃO PREMIADA
                            </label>
                        </div>
                    </div>
                </div>
                <br>
                <div *ngIf="indicacaoPremiada">
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="semDadosIndicaco" [(ngModel)]="semDadosIndicacao">
                                <label class="form-check-label" for="semDadosIndicaco" style="margin-left:20px">NÃO POSSUO TODOS OS DADOS DA INDICAÇÃO
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-md-5 col-6">
                            <label>Data Crédito no valor da mensalidade:</label><br>
                            <input type="month" maxlength="2" [(ngModel)]="mesIndicacaoPremiada" class="form-control" [ngStyle]="{'border-color': (indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col col-md-2 col-6">
                            <label>Matricula</label><br>
                            <div class="input-group">
                                <input type="number" [(ngModel)]="matriculaIndicacaoPremiada" class="form-control">
                                <div class="input-group-prepend">
                                <span class="input-group-text">
                                <fa-icon [icon]="['fas', 'eye']" size="1x" (click)="buscarAsssociadoPorMatricula(matriculaIndicacaoPremiada)" style="cursor:pointer"></fa-icon>
                                </span>
                                </div>
                              </div>
                        </div>
                        <div class="col col-md-5 col-12">
                            <label>Nome de quem indicou</label><br>
                            <input type="text" [(ngModel)]="nomeIndicacaoPremiada" oninput="this.value = this.value.toUpperCase()" class="form-control" [ngStyle]="{'border-color': (indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                    <br>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="dadosBanco" [(ngModel)]="checkDadosBancarios" [disabled]="checkPix || checkVindi || checkConsignet">
                                <label class="form-check-label" for="dadosBanco" style="margin-left:20px">DADOS BANCARIOS
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="pixChave" [(ngModel)]="checkPix" [disabled]="checkDadosBancarios || checkVindi || checkConsignet">
                                <label class="form-check-label" for="pixChave" style="margin-left:20px">CHAVE PIX
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="vindi" [(ngModel)]="checkVindi" [disabled]="checkDadosBancarios || checkPix || checkConsignet">
                                <label class="form-check-label" for="vindi" style="margin-left:20px">VINDI
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="consignet" [(ngModel)]="checkConsignet" [disabled]="checkDadosBancarios || checkPix || checkVindi">
                                <label class="form-check-label" for="consignet" style="margin-left:20px">CONSIGNET
                                </label>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <div *ngIf="checkDadosBancarios">
                        <div class="row">
                            <div class="col col-md-5 col-12">
                                <label>Data Crédito no valor da mensalidade:</label><br>
                                <input type="month" maxlength="2" [(ngModel)]="mesIndicacaoPremiada" class="form-control" [ngStyle]="{'border-color': (indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) ? '#dd443e' : '#3cb42c'}">
                            </div>
                                <label>Banco</label>
                                <input type="text" [(ngModel)]="banco" class="form-control" oninput="this.value = this.value.toUpperCase()">
                            <div class="col col-md-2 col-10">
                                <label>Agência</label>
                                <input type="number" min="0" [(ngModel)]="agencia" class="form-control">
                            </div>
                            <div class="col col-md-1 col-2">
                                <label>Digito</label>
                                <input type="text" [(ngModel)]="agenciaDigito" class="form-control">
                            </div>
                            <div class="col col-md-2 col-10">
                                <label>Conta Corrente</label>
                                <input type="number" min="0" [(ngModel)]="contaCorrente" class="form-control">
                            </div>
                            <div class="col col-md-1 col-2">
                                <label>Digito</label>
                                <input type="text" [(ngModel)]="contaCorrenteDigito" class="form-control">
                            </div>
                            <div class="col col-md-2 col-12">
                                <label>CPF</label>
                                <input type="text" class="form-control" [(ngModel)]="cpfBanco" [textMask]="{mask: MASKS.cpf.textMask}">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="checkPix">
                        <div class="row">
                            <div class="col col-4">
                            <label>Tipo de Chave</label>
                            <select [(ngModel)]="tipoPix" class="form-control">
                                <option selected></option>
                                <option value="CNPJ">CNPJ</option>
                                <option value="CPF">CPF</option>
                                <option value="E-MAIL">E-MAIL</option>
                                <option value="TELEFONE">TELEFONE</option>
                            </select>
                            </div>
                            <div class="col col-8">
                                <label>Chave Pix</label>
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CNPJ'" [textMask]="{mask: MASKS.cnpj.textMask}">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CPF'" [textMask]="{mask: MASKS.cpf.textMask}">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'E-MAIL'">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'TELEFONE'" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" style="padding-top:50px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="pendente" [disabled]="(indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) || !assinado || !formaPagamento || plano == '' || (subContrato != '' && !diaVencimento) || !cpf || !nascimento || !estadoCivil || !rua || !numero || !bairro || !municipio || !estado || !botao || !dataAdesao">CADASTRAR ASSOCIADO</button>
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [hidden]="!pendente" [disabled]="(indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) || !assinado || !motivoPendencia || !observacaoPendente || plano == '' || !nome || !dataAdesao">CADASTRAR ASSOCIADO PENDENTE</button>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>     
</div>