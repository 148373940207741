<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            AGENDA
        </div>
    </div>
    <div class="corpo">
        <div class="row" style="padding:10px; align-items: center;">
            <div class="col">
                <label>Colaborador</label>
                <select class="form-control" [(ngModel)]="vendedorBusca" (change)="getAgenda()">
                    <option value="TODOS">TODOS</option>
                    <option *ngFor="let vend of vendedores" [value]="vend.nome">{{vend.nome}}</option>
                </select>
            </div>
        </div>
        <dx-scheduler *ngIf="agendaShow"
            [dataSource]="appointments"
            textExpr="title"
            allDayExpr="dayLong"
            recurrenceRuleExpr="recurrence"
            [(currentDate)]="dataHojeAgenda"
            [adaptivityEnabled]="false"
            (onAppointmentAdding)="addEvento($event)"
            (onAppointmentUpdating)="attEvento($event)"
            (onAppointmentDeleting)="delEvento($event)"
            (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
            (onAppointmentRendered)="renderAppointment($event)"
            [views]="['month']"
            currentView="month"
            >
        </dx-scheduler>
        <br>
        <label>LEGENDAS:</label>
        <div class="row" style="padding:10px">
            <div class="col col-6" *ngFor="let v of vendedores">
                <div class="row" style="align-items: center;margin:10px;">
                    <div class="col col-2" class="squareLegenda" [style.backgroundColor]="v?.color"></div>
                    <div class="col col-10">{{v?.nome}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
