<div class="modal">
    <div id="imprimir">
        <div class="row" style="font-size:18px; text-align:center; font-weight: 600;">
            <div class="col">
                CONTROLE DE VENDAS - {{vendaSelecionada?.vendedor}}
            </div>
        </div>
        <div class="row" style="font-size:12px; text-align:center">
            <div class="col">
                Inicio Cadastro: {{vendaSelecionada?.data | date:'dd/MM/yyyy'}} - Cadastro Finalizado em: {{vendaSelecionada?.dataPDF | date:'dd/MM/yyyy'}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Município:</label> {{vendaSelecionada?.municipio}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Locais Visitados:</label> {{vendaSelecionada?.locais}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$5.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro5 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$10.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro10 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$15.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro15 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$20.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro20 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$25.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro25 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$35.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro35 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Seguro R$ 50.000,00</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeSeguro50 }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Funeral Extra</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeFuneralExtra }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Funeral Complementar</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdefuneralComplementar }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Convênio de Saúde</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeConvenioSaude }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Adicional Telemedicina</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeTelemedicina }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-5"><strong>Novas Filiações</strong></div>
            <div class="col col-2">
                Total: {{ vendaSelecionada?.qtdeFiliacoes }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-12">
                <label>Faturamento: {{ vendaSelecionada?.faturamento | currency: 'BRL'}}</label>
            </div>
        </div>
        <br>
    </div>
</div>
<div class="modal" id="modalVenda">
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Município</label>
            <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Locais Visitados</label>
            <textarea row="3" class="form-control" [(ngModel)]="locais"></textarea>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$5.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro5 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro5(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro5(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$10.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro10 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro10(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro10(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$15.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro15 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro15(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro15(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$20.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro20 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro20(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro20(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$25.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro25 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro25(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro25(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$35.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro35 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro35(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro35(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$50.000,00</strong></div>
        <div class="col col-2">
            Total: {{ qtdeSeguro50 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro50(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro50(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Extra</strong></div>
        <div class="col col-2">
            Total: {{ qtdeFuneralExtra }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFuneralExtra(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFuneralExtra(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Complementar</strong></div>
        <div class="col col-2">
            Total: {{ qtdefuneralComplementar }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFuneralComplementar(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFuneralComplementar(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Convênio de Saúde</strong></div>
        <div class="col col-2">
            Total: {{ qtdeConvenioSaude }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmConvenioSaude(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addConvenioSaude(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Telemedicina</strong></div>
        <div class="col col-2">
            Total: {{ qtdeTelemedicina }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmTelemedicina(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addTelemedicina(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Novas Filiações</strong></div>
        <div class="col col-2">
            Total: {{ qtdeFiliacoes }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFiliacoes(0)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFiliacoes(0)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Faturamento</label><br>
            {{ faturamento | currency: 'BRL'}}
        </div>
    </div>
    <br>
    <div class="row" style="text-align: right;">
        <div class="col" style="text-align: right;">
            <button class="btn btn-danger btn-block" (click)="closeVenda()">CANCELAR</button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-success btn-block" (click)="cadastrarVenda()" [disabled]="!municipio">CADASTRAR</button>
        </div>
    </div>
</div>
<div class="modal" id="modalVendaEditar">
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Município</label>
            <input type="text" class="form-control" [(ngModel)]="vendaSelecionada.municipio" oninput="this.value = this.value.toUpperCase()">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Locais Visitados</label>
            <textarea row="3" class="form-control" [(ngModel)]="vendaSelecionada.locais"></textarea>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$5.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro5 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro5(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro5(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$10.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro10 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro10(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro10(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$15.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro15 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro15(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro15(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$20.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro20 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro20(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro20(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$25.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro25 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro25(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro25(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$35.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro35 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro35(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro35(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Seguro R$ 50.000,00</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeSeguro50 }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmSeguro50(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addSeguro50(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Extra</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeFuneralExtra }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFuneralExtra(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFuneralExtra(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Funeral Complementar</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdefuneralComplementar }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFuneralComplementar(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFuneralComplementar(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Convênio de Saúde</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeConvenioSaude }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmConvenioSaude(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addConvenioSaude(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Adicional Telemedicina</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeTelemedicina }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmTelemedicina(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addTelemedicina(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5"><strong>Novas Filiações</strong></div>
        <div class="col col-2">
            Total: {{ vendaSelecionada?.qtdeFiliacoes }}
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-danger" (click)="rmFiliacoes(1)">
                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-1">
            <button class="btn btn-outline-success" (click)="addFiliacoes(1)">
                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Faturamento</label><br>
            {{ vendaSelecionada.faturamento | currency: 'BRL'}}
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-12 col-md-12">
            <label>Filiações Novas</label>
            <textarea row="3" class="form-control" [(ngModel)]="vendaSelecionada.filiacoes"></textarea>
        </div>
    </div>
    <br>
    <div class="row" style="text-align: right;">
        <div class="col" style="text-align: right;">
            <button class="btn btn-danger btn-block" (click)="closeVendaEditar()">CANCELAR</button>
            &nbsp;&nbsp;&nbsp;
            <button class="btn btn-success btn-block" (click)="editarVenda()" [disabled]="!vendaSelecionada.municipio">SALVAR</button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            CONTROLE DE VENDAS
        </div>
        <div class="col" style="text-align:right">
            <button class="btn btn-outline-light" (click)="openVenda()">CADASTRAR</button>
        </div>
    </div>
    <div class="corpo">
        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Criação</th>
                    <th>Município</th>
                    <th>Faturamento</th>
                    <th>Situação</th>
                    <th>Editar</th>
                    <th>PDF</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let v of vendas; let i = index">
                    <td>{{i + 1}}</td>
                    <td>{{v?.data | date: 'dd/MM/yyyy'}}</td>
                    <td>{{v?.municipio}}</td>
                    <td>{{v?.faturamento | currency: 'BRL'}}</td>
                    <td>{{v?.situacao}}</td>
                    <td>
                        <button class="btn btn-primary" [disabled]="(v?.situacao == 'ENVIADO' && v?.dataPDF != this.dataHoje)" (click)="openVendaEditar(v)">
                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                        </button>
                    </td>
                    <td>
                    <button class="btn btn-success" (click)="gerarPDF(v)">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
