import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Dexie from 'dexie';
import { DataBaseService } from './provider.service';

@Injectable({
    providedIn: 'root'
})

export class OnlineOfflineService{
    isOnline:boolean = navigator.onLine; 
    urlDataBase:any = "https://data.mongodb-api.com/app/database-msxfu/endpoint/http/dbhookweb";
    urlSite:any = "https://app.vivamax.com.br";
    apiUsuarios:any = this.urlDataBase+'?coll=usuarios';
    apiLogs:any = this.urlDataBase+'?coll=logs';
    apiAssociados:any = this.urlDataBase+'?coll=associados';
    apiConfiguracoes:any = this.urlDataBase+'?coll=configuracoes';

    constructor(public http:HttpClient, public db:DataBaseService){
        window.addEventListener('online', () => {this.isOnline = true;this.db.verificaFilaEspera()});
        window.addEventListener('offline', () => this.isOnline = false);
    }

  cadastrarAssociadoOffline(id,dados,selectedFiles,assinatura,bodyLogs){
    this.db.getSorteLivre().subscribe((dataNum:any) => {
      let idNumSorte = dataNum[0]._id;
      if(dados.plano.indexOf("PLUS") >= 0 || dados.localTrabalho.indexOf("APCS") >= 0){
        dados.numSorte = dataNum[0].numero;
        if(dados.dependentes.length > 0){
          for(let i = 0;dados.dependentes.length < i;i++){
            if(dados.dependentes[i].asf == "FUNERAL CONTRATADO" || dados.dependentes[i].asf == "FUNERAL AUTOMÁTICO VITALÍCIO" || dados.dependentes[i].asf == "FUNERAL AUTOMÁTICO ATÉ 18 ANOS"){
              dados.dependentes[i].numSorte = dataNum[i+3].numero;
              this.db.patchSorte(dataNum[i+3]._id,{usado:true}).subscribe(resN => {
                console.log(resN)
              }, errN => {console.log(errN)})
            }
          }
        }
        if(dados.dependentesExtra.length > 0){
          for(let iE = 0;dados.dependentesExtra.length < iE;iE++){
            if(dados.dependentesExtra[iE].asf == "FUNERAL CONTRATADO" || dados.dependentesExtra[iE].asf == "AUTOMÁTICO SEM CUSTO"){
              dados.dependentesExtra[iE].numSorte = dataNum[iE+13].numero;
              this.db.patchSorte(dataNum[iE+3]._id,{usado:true}).subscribe(resN => {
                console.log(resN)
              }, errN => {console.log(errN)})
            }
          }
       }
      }
    this.getConfigs().subscribe((x:any) => {
        let matricula = x[10].matricula;
        let idMatricula = x[10]._id;
        let emails = x[23].emails;
        let emailIndicacao:any = "";
        let idIndicacao:any = "";
        let responsavelIndicacao:any = "";
        let emailLinkCartao:any = "";
        let idLinkCartao:any = "";
        let responsavelLinkCartao:any = "";
        let emailCartaoVindi:any = "";
        let idCartaoVindi:any = "";
        let responsavelCartaoVindi:any = "";
        let emailMargemConsignet:any = "";
        let idMargemConsignet:any = "";
        let responsavelMargemConsignet:any = "";
        let emailBoletoVindi:any = "";
        let idBoletoVindi:any = "";
        let responsavelBoletoVindi:any = "";
        let emailCarne:any = "";
        let idCarne:any = "";
        let responsavelCarne:any = "";
        let emailVendaTelefone:any = "";
        let idVendaTelefone:any = "";
        let responsavelVendaTelefone:any = "";
        let idxIndicacao = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["indicacaoPremiada"]');
        if(idxIndicacao >= 0){
          let dadosIndicacao:any = Object.values(emails[idxIndicacao])[0];
          emailIndicacao = dadosIndicacao.email;
          idIndicacao = dadosIndicacao.idResponsavel;
          responsavelIndicacao = dadosIndicacao.nomeResponsavel;
        }
        let idxLinkCartao = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["linkCartao"]');
        if(idxLinkCartao >= 0){
          let dadosLinkCartao:any = Object.values(emails[idxLinkCartao])[0];
          emailLinkCartao = dadosLinkCartao.email;
          idLinkCartao = dadosLinkCartao.idResponsavel;
          responsavelLinkCartao = dadosLinkCartao.nomeResponsavel;
        }
        let idxCartaoVindi = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["cartaoVindi"]');
        if(idxCartaoVindi >= 0){
          let dadosCartaoVindi:any = Object.values(emails[idxCartaoVindi])[0];
          emailCartaoVindi = dadosCartaoVindi.email;
          idCartaoVindi = dadosCartaoVindi.idResponsavel;
          responsavelCartaoVindi = dadosCartaoVindi.nomeResponsavel;
        }
        let idxMargemConsignet = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["margemConsignet"]');
        if(idxMargemConsignet >= 0){
          let dadosMargemConsignet:any = Object.values(emails[idxMargemConsignet])[0];
          emailMargemConsignet = dadosMargemConsignet.email;
          idMargemConsignet = dadosMargemConsignet.idResponsavel;
          responsavelMargemConsignet = dadosMargemConsignet.nomeResponsavel;
        }
        let idxBoletoVindi = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["boletoVindi"]');
        if(idxBoletoVindi >= 0){
          let dadosBoletoVindi:any = Object.values(emails[idxBoletoVindi])[0];
          emailBoletoVindi = dadosBoletoVindi.email;
          idBoletoVindi = dadosBoletoVindi.idResponsavel;
          responsavelBoletoVindi = dadosBoletoVindi.nomeResponsavel;
        }
        let idxCarne = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["gerarCarne"]');
        if(idxCarne >= 0){
          let dadosCarne:any = Object.values(emails[idxCarne])[0];
          emailCarne = dadosCarne.email;
          idCarne = dadosCarne.idResponsavel;
          responsavelCarne = dadosCarne.nomeResponsavel;
        }
        let idxVendaTelefone = emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["conectaVendaTelefone"]');
        if(idxVendaTelefone >= 0){
          let dadosVendaTelefone:any = Object.values(emails[idxVendaTelefone])[0];
          emailVendaTelefone = dadosVendaTelefone.email;
          idVendaTelefone = dadosVendaTelefone.idResponsavel;
          responsavelVendaTelefone = dadosVendaTelefone.nomeResponsavel;
        }
        this.getAssociadosMatricula(matricula).subscribe((m:any) => {
          if(m && m.length > 0){
            dados.matricula = matricula+10
          }else{
            dados.matricula = matricula;
          }
          this.cadastroProposta(dados).subscribe(res => {
            console.log(res);
            let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
            if(selectedFiles.length > 0){
              const imagedata = new FormData();
              for(let i=0; i<selectedFiles.length; i++){imagedata.append('files[]', selectedFiles[i], selectedFiles[i].name)};
              imagedata.append('files[]',assinatura);
              this.enviarDocAssociado(res['insertedId'], imagedata).subscribe((res) => {
                  console.log(res);
                },(error) => {
                  console.log(error);
                });
            }else{
                const imagedata = new FormData();
                imagedata.append('files[]',assinatura);
                this.enviarDocAssociado(res['insertedId'], imagedata).subscribe((res) => {
                    console.log(res);
                  },(error) => {
                    console.log(error);
                  });
            }
            this.postLogs(bodyLogs).subscribe(res => {
              let jsonDate = new Date().toJSON();
              let hora = new Date(jsonDate).toLocaleTimeString();
              console.log(res);
              this.getAssociado(dados.matricula,dados.cpf,dados.nome).subscribe((ass:any) => {
                if(ass && ass.length > 0){
                  new Dexie("dados").open().then(function(d){
                      d.table('associados').delete(id);
                      this.patchMatricula(idMatricula,{'matricula':dados.matricula+1}).subscribe(res => {
                        console.log(res);
                        if(this.plano.indexOf("PLUS") > 0){
                          this.db.patchSorte(idNumSorte,{usado:true}).subscribe(resN => {
                          console.log(resN)
                        }, errN => {console.log(errN)})
                      }
                        if(dados.cpfAnalise){
                          alert("Cliente possui cancelamento por inadimplência - em análise");
                          let rdmCpf = Math.random().toString(36).slice(-8);
                          let cpfAnalisar = {
                            "id": rdmCpf,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "CADASTRO CPF INADIMPLENTE",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idBoletoVindi,
                            "responsavel" : responsavelBoletoVindi,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.db.postTarefa(cpfAnalisar).subscribe(res => console.log(res),err => console.log(err));
                        }
                        if(dados.indicacaoPremiada){
                          let randomInd = Math.random().toString(36).slice(-8);
                          let dadosPgto = dados.checkDadosBancarios ? " - Dados Bancarios: "+dados.banco+" - Agencia: "+dados.agencia+" - "+dados.agenciaDigito+" - Conta: "+dados.contaCorrente+" - "+dados.contaCorrenteDigito+" - CPF: "+dados.cpfBanco : dados.checkPix ? " - Tipo de Pix: "+dados.tipoPix+" - Chave Pix: "+dados.pix : dados.checkConsignet ? " - CONSIGNET" : dados.checkVindi ? " - VINDI" : "";
                          let obsInd:any = dados.semDadosIndicacao == true ? 'Dados Incompletos: ' : '';
                          let indicacaoPrem = {
                            "id": randomInd,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "INDICAÇÃO PREMIADA",
                            "descricao" : obsInd+"Associado: "+ dados.nome + " - Matricula: " + dados.matricula+ " - Quem Indicou: "+ dados.nomeIndicacaoPremiada + " - Matricula: " + dados.matriculaIndicacaoPremiada+ " - Data Crédito: 15/" + dados.mesIndicacaoPremiada.split('-').reverse().join('/') + dadosPgto,
                            "idResponsavel" : idIndicacao,
                            "responsavel" : responsavelIndicacao,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.postTarefa(indicacaoPrem).subscribe(res => console.log(res),err => console.log(err))
                         }
                        if(dados.formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO'){
                          if(dados.prefeituraConsignet){
                            let randomConfCard = Math.random().toString(36).slice(-8);
                            let confirmarCartaoAgenda = {
                              "id": randomConfCard,
                              "data" : dataHoje,
                              "hora" : hora,
                              "titulo" : "CONSULTAR MARGEM NO CONSIGNET",
                              "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                              "idResponsavel" : idMargemConsignet,
                              "responsavel" : responsavelMargemConsignet,
                              "resolvido":false,
                              "dataResolucao" : "",
                              "horaResolucao" : "",
                              "prioridade" : "ALTA",
                              "origem":"Sistema",
                              "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                              "nomeSolicitante":localStorage.getItem('usuarioVendas')
                            }
                            this.postTarefa(confirmarCartaoAgenda).subscribe(res => console.log(res),err => console.log(err))
                          }else{
                            let randomInd = Math.random().toString(36).slice(-8);
                            let bolGera = {
                              "id": randomInd,
                              "data" : this.dataHoje,
                              "hora" : hora,
                              "titulo" : "CONFERIR AUTORIZAÇÃO DE DESCONTO",
                              "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                              "idResponsavel" : idMargemConsignet,
                              "responsavel" : responsavelMargemConsignet,
                              "resolvido":false,
                              "dataResolucao" : "",
                              "horaResolucao" : "",
                              "prioridade" : "ALTA",
                              "origem":"Sistema",
                              "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                              "nomeSolicitante":localStorage.getItem('usuarioVendas')
                            }
                            this.db.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err));
                        }
                         }else{
                        if(dados.gerarLinkCartao){
                          let randomGeraLink = Math.random().toString(36).slice(-8);
                          let linkCartaoAgenda = {
                            "id": randomGeraLink,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "GERAR LINK CARTÃO",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idLinkCartao,
                            "responsavel" : responsavelLinkCartao,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.postTarefa(linkCartaoAgenda).subscribe(res => console.log(res),err => console.log(err))
                        }
                        if(dados.formaPagamento == 'BOLETO'){
                          let randomInd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": randomInd,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "CADASTRAR BOLETO NA VINDI",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idBoletoVindi,
                            "responsavel" : responsavelBoletoVindi,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err))
                        }
                        if(dados.formaPagamento == 'CARNÊ'){
                          let randomInd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": randomInd,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "GERAR CARNÊ",
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idCarne,
                            "responsavel" : responsavelCarne,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.postTarefa(bolGera).subscribe(res => console.log(res),err => console.log(err))
                        }
                        if(dados.formaPagamento == 'DEBITO RECORRENTE' && !dados.gerarLinkCartao){
                          let rnd = Math.random().toString(36).slice(-8);
                          let bolGera = {
                            "id": rnd,
                            "data" : dataHoje,
                            "hora" : hora,
                            "titulo" : "VERIFICAR CADASTRO E COBRANÇA NA VINDI: "+dados.formaPagamento,
                            "descricao" : "Associado: "+ dados.nome + " - Matricula: "+dados.matricula,
                            "idResponsavel" : idBoletoVindi,
                            "responsavel" : responsavelBoletoVindi,
                            "resolvido":false,
                            "dataResolucao" : "",
                            "horaResolucao" : "",
                            "prioridade" : "ALTA",
                            "origem":"Sistema",
                            "idUserSolicitacao":localStorage.getItem('idUserVendas'),
                            "nomeSolicitante":localStorage.getItem('usuarioVendas')
                          }
                          this.db.postTarefa(bolGera).subscribe(res => {
                            console.log(res);
                          }, err => {
                            console.log(err)
                          })
                        }
                      }
                    }, err => console.log(err))
                }.bind(this)).catch("NoSuchDatabaseError", function(e){
                  console.log("nao tem nada aqui")
              }.bind(this)).then(() => {
                //void
                });
              }else{
                alert("Não Foi Possivel realizar o Cadastro do Associado "+dados.nome+". Verifique sua conexão e tente novamente mais tarde!");
              }
              });
            }, err => {
                console.log(err)
            });
          }, err => {
            console.log(err);
            alert("Não foi possivel cadastrar o Associado "+dados.nome+" , por favor tente novamente!");
          })
        });
      });
    });
  }


  getAssociado(matricula,cpf,nome){
      return this.http.get(this.apiAssociados+'&method=GET&filtro={"matricula":'+matricula+',"cpf":"'+cpf+'","nome":"'+nome+'"}&sort={"nome":1}');
    }

  enviarOnline(){
    if(window.confirm("CERTIFIQUE-SE DE QUE SUA CONEXÃO ESTA ADEQUADA, CASO CONTRÁRIO PERDERÁ TODOS OS DADOS SALVOS, DESEJA CONTINUAR MESMO ASSIM?")){
      new Dexie("dados").open().then(function(database){
      database.table('associados').toArray().then((result:any) => {
        if(result.length > 0){
          this.getConfigs().subscribe((x:any) => {
            let matricula = x[10].matricula;
            this.db.userTop = x[20].userTelemedicina;
            this.db.senhaTop = x[20].passTelemedicina;
            this.db.sandboxTop = x[20].passSandbox;
            result.forEach((item,i) => {
              setTimeout(() => {
                matricula++;
                this.getAssociadosMatricula(matricula).subscribe((m:any) => {
                  if(m && m.length > 0){
                    item.dados.matricula = Number(matricula+10);
                  }else{
                    item.dados.matricula = Number(matricula);
                  }
                  this.cadastrarAssociadoOffline(item.id,item.dados,item.files,item.assinatura,item.bodyLog);
              })
              },i*1000)
            });
          });
        }else{
          console.log("Sem associados para sincronizar");
        }
      }).catch((e) => {
          console.log(e)
      });
      }.bind(this)).catch("NoSuchDatabaseError", function(e){
          console.log("nao tem nada aqui")
      }.bind(this)).then(() => {
      //void
    });
  }
}

  getUsuariosByEmail(email:any){
    return this.http.get(this.apiUsuarios+'&method=GET&filtro={"email":"'+email+'"}');
  }

  patchMatricula(id:any,dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiConfiguracoes+'&method=PATCH&id='+id+'&body='+body);
  }

  getConfigs(){
    return this.http.get(this.apiConfiguracoes+'&method=GET&sort={"_id":1}');
  }

  postLogs(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiLogs+'&method=POST&body='+body);
  }
   
  enviarDocAssociado(id:any,imagedata:any){
    let url = this.urlSite+'/upload-doc-associado.php?id='+id;
    return this.http.post(url, imagedata);
  }

  cadastroProposta(dados:any){
    let body = JSON.stringify(dados);
    return this.http.get(this.apiAssociados+'&method=POST&body='+body);
  }

  getAssociadosMatricula(key:any){
    return this.http.get(this.apiAssociados+'&method=GET&filtro={"matricula":'+key+'}&sort={"nome":1}');
  }
  
  postTarefa(dados:any){
    let body = JSON.stringify({"tarefas":dados});
    return this.http.get(this.apiUsuarios+'&method=POSTINSIDE&id='+dados.idResponsavel+'&body='+body);
  }

}