<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            CADASTRAR ASSOCIADO CONECTA
        </div>
    </div>
    <div class="corpo">
        <div class="row align-items-center" style="padding: 20px;">
            <div class="col col-6 col-md-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="possuiAssDoc" id="possuiAss" [disabled]="vendaTelefone">
                    <label class="form-check-label" for="possuiAss" style="margin-left:20px">Possuo Assinatura no Documento
                    </label>
                </div>
            </div>
            <div class="col col-6 col-md-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="vendaTelefone" id="vendaTel" [disabled]="possuiAssDoc">
                    <label class="form-check-label" for="vendaTel" style="margin-left:20px">Venda Por Telefone
                    </label>
                </div>
            </div>
        </div>
        <div class="row align-items-center" *ngIf="vendaTelefone || possuiAssDoc">     
            <div class="col col-12 col-md-4">
                <label>Tipo de Plano:</label>
                <select class="form-control" [(ngModel)]="tipoPlano" (change)="escolherTipoPlano(tipoPlano)" [ngStyle]="{'border-color': !tipoPlano ? '#dd443e' : '#3cb42c'}">
                    <option value="FISICO">FÍSICO</option>
                    <option value="SERVIDOR">SERVIDOR PÚBLICO</option>
                    <option value="EMPRESARIAL">EMPRESARIAL</option>
                </select>
            </div>
            <div class="col col-12 col-md-8" *ngIf="tipoPlano == 'EMPRESARIAL'">
                <label>Empresa</label>
                <ng-select class="form-control" [items]="db.empresas" bindLabel="fantasiaEmpresa" appendTo="body" [(ngModel)]="empresa" (change)="selectEmpresa(empresa)"></ng-select>
            </div>
            <div class="col col-12 col-md-6" *ngIf="tipoPlano == 'EMPRESARIAL'">
                <label>Plano Empresarial:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('empresarial',plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosEmpresariais" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-12 col-md-8" *ngIf="tipoPlano == 'FISICO'">
                <label>Plano Físico:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('fisico',plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosFisicos" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-12 col-md-8" *ngIf="tipoPlano == 'SERVIDOR'">
                <label>Plano Servidor:</label>
                <select class="form-control" [(ngModel)]="plano" (change)="selectPlano('servidor',plano)" [ngStyle]="{'border-color': !plano ? '#dd443e' : '#3cb42c'}">
                    <option value=""></option>
                    <option *ngFor="let plan of planosServidores" [value]="plan.nome">{{plan.nome}}</option>
                </select>
            </div>
            <div class="col col-12 col-md-12" *ngIf="tipoPlano == 'SERVIDOR'">
                <label>Sub-Contrato:</label>
                <ng-select class="form-control" [items]="db.tableSubContratos" bindLabel="nome" appendTo="body" (change)="escolheSubContrato(subContrato)" [(ngModel)]="subContrato"></ng-select>
            </div>
        </div>
        <div class="row" *ngIf="subContrato != '' && subContrato != null">
            <div class="col col-6">
                <label>Login E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="loginEconsig">
            </div>
            <div class="col col-6">
                <label>Senha E-Consig:</label>
                <input type="text" class="form-control" [(ngModel)]="senhaEconsig">
            </div>
        </div>
        <br>
        <div class="row" *ngIf="plano && ((tipoPlano == 'SERVIDOR' && subContrato) || (tipoPlano == 'EMPRESARIAL' && empresa) || tipoPlano == 'FISICO')">
            <div class="col col-6 col-md-3">
                <label>CPF:</label>
                <input type="text" class="form-control" [(ngModel)]="cpf" cpf [textMask]="{mask: MASKS.cpf.textMask}"
                [ngStyle]="{'border-color': !cpfCheck || !db.checkCPF(cpf) ? '#dd443e' : '#3cb42c'}"
                (keyup)="db.checkCPF(cpf);cpfUnico(cpf)">
                <div class="errorMsg" *ngIf="cpf && !db.checkCPF(cpf)">CPF incorreto!</div>
                <div class="errorMsg" *ngIf="cpf && !cpfCheck">CPF Já Cadastrado!</div>
            </div>
            <div class="col col-6 col-md-3">
                <label>Nascimento:</label>
                <input type="text" class="form-control" [(ngModel)]="nascimento" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimento.length != 10 ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-4" *ngIf="(nascimento.length == 10 && plano && cpfCheck)">
                <label>Nome Completo:</label>
                <input type="text" class="form-control" [(ngModel)]="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-2" *ngIf="(nascimento.length == 10 && plano && cpfCheck)">
                <label>Sexo:</label>
                <select class="form-control" [(ngModel)]="sexo" [ngStyle]="{'border-color': !sexo ? '#dd443e' : '#3cb42c'}">
                    <option value="MASCULINO">MASCULINO</option>
                    <option value="FEMININO">FEMININO</option>
                </select>
            </div>
        </div>
        <div [hidden]="!cpfCheck || nascimento.length != 10 || !plano || !vendedor || !nome || !sexo">
            <div class="row align-items-center">
                <div class="col col-6 col-md-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone">
                </div>
                <div class="col col-6 col-md-3">
                    <label>Celular:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celular" [ngStyle]="{'border-color': !celular ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-3">
                    <div class="form-check" style="padding: 1em 0px 0px 2em">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="whatsapp" id="telewhats">
                        <label class="form-check-label" for="telewhats" style="margin-left:20px">Possui Whatsapp</label>
                    </div>
                </div>
            </div>
            <br>
            <div class="row align-items-center">
                <div class="col col-12 col-md-12">
                    <label>E-mail:</label>
                    <input type="text" class="form-control" [(ngModel)]="email" (keyup)="db.checkEmail(email)" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !db.checkEmail(email) ? '#dd443e' : null}">
                    <div class="errorMsg" *ngIf="email && !db.checkEmail(email)">E-mail inválido!</div>
                </div>
            </div>
            <br>
            <div class="row align-items-center">
                <div class="col col-12 col-md-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cep" (keyup)="buscarCep(cep)">
                </div>
                <div class="col col-12 col-md-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="rua" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !rua ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numero" [ngStyle]="{'border-color': !numero ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairro" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairro ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col col-12 col-md-6">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complemento" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-9 col-md-4">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipio" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipio ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3 col-md-2">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estado" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estado ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
        </div>

        <div [hidden]="!email  || !nome || !rua || !numero || !bairro || !municipio || !estado">
            <br>
            <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
            <br>
            <form [formGroup]="dependentes">
                    <div class="row" formArrayName="dependente">
                        <div class="form-group col-3" style="text-align: left;"
                            [hidden]="listaDeDependentes?.length > 0">
                            <br>
                            <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                            </button>
                        </div>
                        <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                            &nbsp;
                        </div>
                        <div class="col col-12" *ngFor="let dependente of dependentesFormGroup.controls; let i = index;">
                            <div [formGroupName]="i" class="row align-items-center">
                                <div class="form-group col-2">
                                    <label>Tipo:</label>
                                    <select class="form-control" formControlName="tipo" id="tipoSmart{{i}}" style="text-align: left;" (change)="tipoDependenteSmart(i)">
                                        <option value=""></option>
                                        <option value="CÔNJUGE">CÔNJUGE</option>
                                        <option value="FILHO">FILHO</option>
                                        <option value="FILHA">FILHA</option>
                                        <option value="OUTROS">OUTROS</option>
                                    </select>
                                </div>
                                <div class="form-group col-7">
                                    <label>Nome:</label>
                                    <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-3">
                                    <label>Nascimento:</label>
                                    <input type="text" class="form-control" formControlName="nascimento" id="nascimentoSmart{{i}}" maxlength="10"
                                        onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) ? '#dd443e' : null}">
                                </div>
                                <div class="form-group col-6" *ngIf="hibrido">
                                    <label>ASF:</label>
                                    <select class="form-control" formControlName="asf" id="asfSmart{{i}}"
                                        (change)="selectAsf()">
                                        <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                        <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                        <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                        <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                    </select>
                                </div>
                                 <div class="form-group col-3">
                                    <label>CPF:</label>
                                    <input type="text" class="form-control" formControlName="cpf" cpf (keyup)="db.checkCPF(dependentes.controls.dependente.value[i].cpf)"
                                        id="cpfSmart{{i}}" [textMask]="{mask: MASKS.cpf.textMask}"
                                        [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null || !db.checkCPF(dependentes.controls.dependente.value[i].cpf)) ? '#dd443e' : null}">
                                        <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !db.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                                </div>
                                <div class="form-group col-3">
                                    <label>Sexo:</label>
                                    <select class="form-control" formControlName="sexo" id="sexoSmart{{i}}"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].sexo == '' || dependentes.controls.dependente.value[i].sexo == null) ? '#dd443e' : null}">
                                        <option value=""></option>
                                        <option value="M">MASCULINO</option>
                                        <option value="F">FEMININO</option>
                                    </select>
                                </div>
                                <div class="form-group col-3">
                                    <div class="checkCor">
                                        <label class="form-check-label" for="conectaCheckSmart{{i}}">Conecta</label><br>
                                        <input class="form-check-input" type="checkbox" id="conectaCheckSmart{{i}}" formControlName="conecta"
                                        [ngStyle]="{'pointer-events': !dependentes.controls.dependente.value[i].conecta && (qtdeConectaDependentes() >= maxDependentesConecta) ? 'none' : 'all'}">
                                    </div>
                                </div>
                                <div class="form-group col-9">
                                    <label>E-mail:</label>
                                    <input type="text" class="form-control" id="emailSmart{{i}}" formControlName="email"
                                    (keyup)="db.checkEmail(dependentes.controls.dependente.value[i].email)"
                                    [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].email == '' || dependentes.controls.dependente.value[i].email == null || !db.checkEmail(dependentes.controls.dependente.value[i].email)) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].email && !db.checkEmail(dependentes.controls.dependente.value[i].email)">E-mail inválido!</div>
                                </div>
                                <div style="width:100%;text-align:right">
                                    <br>
                                    <button class="btn btn-outline-danger" (click)="removeDependente(i)" style="margin:5px" [disabled]="dependentes.controls.dependente.value[i].idDoc">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                    </button>
                                    <button class="btn btn-outline-success" (click)="addDependente()" style="margin:5px"
                                    [hidden]="empresaAssociado != 'VIVA MAX' && !hibrido && qtdeConectaDependentes() >= maxDependentesConecta"
                                        [disabled]="(dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null)">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>
            </form>
            <form [formGroup]="dependentesExtra" *ngIf="hibrido">
                <div class="row" formArrayName="dependenteExtra">
                    <div class="form-group col-3" style="text-align: left;"
                        [hidden]="listaDeDependentesExtra?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                            <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                        </button>
                    </div>
                    <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12"
                        *ngFor="let dependenteExtra of dependentesExtraFormGroup.controls; let i = index;">
                        <div class="row align-items-center" [formGroupName]="i">
                            <div class="form-group col-2">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" (change)="tipoDependenteExtraSmart(i)" id="tipoSmartExtra{{100+i}}" style="text-align: left;">
                                    <option value=""></option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                    <option value="OUTRO">OUTRO</option>
                                </select>
                            </div>
                            <div class="form-group col-7">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome"
                                    oninput="this.value = this.value.toUpperCase()"
                                    [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-3">
                                <label>Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento"
                                    id="nascimentoSmartExtra{{100+i}}" maxlength="10"
                                    onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-6" *ngIf="hibrido">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfSmartExtra{{i}}" (change)="selectAsfExtras()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO">FUNERAL CONTRATADO</option>
                                </select>
                            </div>                              
                            <div class="form-group col-3">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf
                                    id="cpfSmartExtra{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="db.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)"
                                    [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null || !db.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf) ? '#dd443e' : null}">
                                    <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].cpf && !db.checkCPF(dependentesExtra.controls.dependenteExtra.value[i].cpf)">CPF incorreto!</div>
                                </div>
                            <div class="form-group col-3">
                                <label>Sexo:</label>
                                <select class="form-control" formControlName="sexo" id="sexoSmartExtra{{i}}"
                                [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].conecta && (dependentesExtra.controls.dependenteExtra.value[i].sexo == '' || dependentesExtra.controls.dependenteExtra.value[i].sexo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="M">MASCULINO</option>
                                    <option value="F">FEMININO</option>
                                </select>
                            </div>
                            <div class="form-group col-3" *ngIf="hibrido">
                                <div class="checkCor">
                                    <label class="form-check-label" for="conectaCheckSmartExtra{{i}}">Conecta</label><br>
                                    <input class="form-check-input" type="checkbox" id="conectaCheckSmartExtra{{i}}" formControlName="conecta"
                                    [ngStyle]="{'pointer-events': !dependentesExtra.controls.dependenteExtra.value[i].conecta && (qtdeConectaDependentes() >= maxDependentesConecta) ? 'none' : 'all'}">
                                </div>
                            </div>
                            <div class="form-group col-9">
                                <label>E-mail:</label>
                                <input type="text" class="form-control" id="emailSmartExtra{{i}}" formControlName="email"
                                (keyup)="db.checkEmail(dependentesExtra.controls.dependenteExtra.value[i].email)"
                                [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].conecta && (dependentesExtra.controls.dependenteExtra.value[i].email == '' || dependentesExtra.controls.dependenteExtra.value[i].email == null) ? '#dd443e' : null}">
                                <div class="errorMsg" *ngIf="dependentesExtra.controls.dependenteExtra.value[i].email && !db.checkEmail(dependentesExtra.controls.dependenteExtra.value[i].email)">E-mail inválido!</div>
                            </div>
                            <div style="width:100%;text-align:right">
                                <button class="btn btn-outline-danger" type="button" style="margin:5px" [disabled]="dependentesExtra.controls.dependenteExtra.value[i].idDoc" (click)="removeDependenteExtra(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>
                                <button class="btn btn-outline-success" type="button" style="margin:5px"
                                    (click)="addDependenteExtra()"
                                    [hidden]="empresaAssociado != 'VIVA MAX' && !hibrido && qtdeConectaDependentes() >= maxDependentesConecta"
                                    [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || (dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </form>
            <br>
            <label>Proponente Possui Problemas Pré Existentes?</label>
            <div style="padding:10px">
                <div class="row">
                    <div class="col col-3">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaSimSmart"
                                [disabled]="doenca == 'NÃO'" (change)="selectDoencaSmart($event)">
                            <label class="form-check-label" for="doencaSimSmart" style="margin-left:10px">SIM</label>
                        </div>
                    </div>
                    <div class="col col-3">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="doencaNaoSmart"
                                [disabled]="doenca == 'SIM'" (change)="selectDoencaSmart($event)">
                            <label class="form-check-label" for="doencaNaoSmart" style="margin-left:10px">NÃO</label>
                        </div>
                    </div>
                    <div class="col col-6" *ngIf="doenca == 'SIM'">
                        <label>Especificar:</label>
                        <input type="text" class="form-control" [(ngModel)]="especificarDoenca"
                            oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="!rua || !numero || !bairro || !municipio || !estado || !email || !celular">
            <label>ADESÃO E MANUTENÇÃO</label> 
            <div class="row">
                <div class="col col-6 col-md-2">
                    <label>Data Adesão</label>
                    <input type="date" class="form-control" [(ngModel)]="dataAdesao" [ngStyle]="{'border-color': !dataAdesao ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-6 col-md-2">
                    <label>Taxa de Adesão:</label>
                    <input type="number" class="form-control" [(ngModel)]="taxaAdesao" [disabled]="editaAdesao != 'SIM'">
                </div>
                <div class="col col-6 col-md-2">
                    <label>Mensalidade:</label>
                    <input type="number" class="form-control" [(ngModel)]="mensalidade" [disabled]="editaMensalidade != 'SIM'">
                </div>
                <div class="col col-6 col-md-2" *ngIf="tipoPlano != 'SERVIDOR'">
                    <label>Dia Vencimento:</label>
                    <input type="date" class="form-control" [(ngModel)]="diaVencimento" [ngStyle]="{'border-color': (tipoPlano != 'SERVIDOR' && !diaVencimento) ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div>
                <label>FORMA DE PAGAMENTO</label>
                <br>
                <div class="row" *ngIf="estado == 'SC'">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="convenioCelesc" [disabled]="formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'PIX' || bloqueioPagamentos" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="convenioCelesc" style="margin-left:20px">CONVÊNIO CELESC</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'CONVÊNIO CELESC'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <br>
                    <div class="row" style="margin-left:20px">
                        <div class="col col-10 col-md-4">
                            <label>UNIDADE CONSUMIDORA:</label>
                            <input type="number" [(ngModel)]="unidadeConsumidora" class="form-control">
                        </div>
                    </div>
                    <br><br>
                </div>
                <br>
                <div class="row" [hidden]="tipoPlano != 'SERVIDOR'">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="teledescontoFolha" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'PIX' || bloqueioPagamentos" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="teledescontoFolha" style="margin-left:20px">DESCONTO EM FOLHA DE PAGAMENTO</label>
                        </div>
                    </div>
                    <div style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                        <br>
                        <div class="row" style="margin-left:20px">
                            <div class="col col-10 col-md-4">
                                <label>A PARTIR DO MÊS:</label>
                                <input type="month" [(ngModel)]="mesFolhaPagamento" class="form-control">
                            </div>
                        </div>
                        <br><br>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="teledebitoRecorrente" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'CARNÊ' || formaPagamento == 'PIX'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="teledebitoRecorrente" style="margin-left:20px">DEBITO RECORRENTE</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="formaPagamento == 'DEBITO RECORRENTE'" style="margin-left:40px; background-color:#ddd; border-radius: 10px;">
                    <div class="row" style="margin-left:20px">
                        <div class="col col-10 col-md-4" *ngFor="let cartao of cartoes; let i = index" style="padding:10px">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="telecard{{i}}" name="ch" (change)="cartaoSelect($event,i)">
                                <label class="form-check-label" for="telecard{{i}}" style="margin-left: 15px; font-size:0.8em;">{{cartao}}</label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="padding:10px" *ngIf="cartaoSelecionado != ''">
                        <div class="row" style="padding-top:10px">
                            <div class="col col-12 col-md-4">
                                <label>Nome</label>&nbsp;<small>(idêntico ao do cartão de crédito)</small>
                                <input type="text" class="form-control" [(ngModel)]="nomeCartao" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-12 col-md-4">
                                <label>Número do Cartão</label>
                                <input type="text" class="form-control" [(ngModel)]="numeroCartao" maxlength="19" onkeyup="var val = this.value;var newval = '';val = val.replace(/\s/g, '');for(var i = 0; i < val.length; i++) {if(i%4 == 0 && i > 0) newval = newval.concat(' ');newval = newval.concat(val[i]);}this.value = newval;"> 
                            </div>
                            <div class="col col-6 col-md-2">
                                <label>Validade</label>
                                <input type="text" class="form-control" [(ngModel)]="validadeCartao" maxlength="5" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v}">
                            </div>
                            <div class="col col-6 col-md-2">
                                <label>Cód. Segurança</label>
                                <input type="text" class="form-control" [(ngModel)]="codigoCartao" maxlength="3">
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="telegeraLink" [(ngModel)]="gerarLinkCartao" [disabled]="nomeCartao  != ''|| numeroCartao  != ''|| validadeCartao  != ''|| codigoCartao != ''">
                                <label class="form-check-label" for="telegeraLink" style="margin-left:20px">Gerar Link
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
                <br>
                <div class="row" [hidden]="plano != 'CONECTA GRUPO ANUAL'">
                    <div class="col col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="teleboletoMensal" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'DEBITO RECORRENTE' || formaPagamento == 'CARNÊ' || formaPagamento == 'PIX'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="teleboletoMensal" style="margin-left:20px">BOLETO</label>
                        </div>
                    </div>
                    <div class="col col-12"*ngIf="formaPagamento == 'BOLETO'">
                        <label style="color:rgb(180, 19, 19)">ATENÇÃO!</label><br>
                        <strong>Serão emitidos 4 boletos de R$ 135,00 sendo necessário o pagamento do primeiro em até 3 dias úteis para a ativação do Plano!</strong>
                    </div>
                </div>
                <br>
                <div class="row" [hidden]="plano != 'CONECTA GRUPO ANUAL'">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="telepix" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="telepix" style="margin-left:20px">PIX</label>
                        </div>
                    </div>
                    <div class="col col-12" *ngIf="formaPagamento == 'PIX'">
                        <label style="color:rgb(180, 19, 19)">ATENÇÃO!</label><br>
                        <strong>Serão emitidos 4 cobranças Pix de R$ 135,00 sendo necessário o pagamento do primeiro em até 3 dias úteis para a ativação do Plano!</strong>
                    </div>
                </div>
                <!-- 
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="telecarne" [disabled]="formaPagamento == 'CONVÊNIO CELESC' || formaPagamento == 'DESCONTO EM FOLHA DE PAGAMENTO' || formaPagamento == 'BOLETO' || formaPagamento == 'DEBITO RECORRENTE'" (change)="selectFormaPagamento($event)">
                            <label class="form-check-label" for="telecarne" style="margin-left:20px">CARNÊ</label>
                        </div>
                    </div>
                </div> -->
                <br>
                <div class="row">
                    <div class="col" style="text-align: center; color:#5e5e5e">
                        <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChangeCadastro($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClick($event)" multiple>
                        </div>
                        <div class="files-list" *ngFor="let file of files;let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                </div>
                <br>
                <div style="text-align: center;width: 100%;">
                    <label>ASSINATURA DO ASSOCIADO</label>
                </div>
                <div class="wrapper" [ngStyle]="{'pointer-events': !assinado ? 'auto' : 'none'}">
                    <canvas id="telesignature-pad" class="signature-pad" width="360" height="300"></canvas>
                </div>
                <div class="row" style="padding-top:1em;">
                    <div class="col" style="text-align: center;">
                        <button class="btn btn-danger" id="teleclear">Limpar</button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        &nbsp;
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="telecheckAss" [disabled]="assinado">
                            <label class="form-check-label" for="telecheckAss" style="margin-left:20px">Reconheço esta assinatura.
                            </label>
                        </div>
                    </div>
                    <div class="col col-3">
                        &nbsp;
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row" style="margin-left:25px">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="teleindicaSim" [(ngModel)]="indicacaoPremiada">
                            <label class="form-check-label" for="teleindicaSim" style="margin-left:20px">É INDICAÇÃO PREMIADA
                            </label>
                        </div>
                    </div>
                </div>
                <br>
                <div *ngIf="indicacaoPremiada">
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="telesemDadosIndicaco" [(ngModel)]="semDadosIndicacao">
                                <label class="form-check-label" for="telesemDadosIndicaco" style="margin-left:20px">NÃO POSSUO TODOS OS DADOS DA INDICAÇÃO
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col col-6 col-md-2">
                            <label>Matricula</label><br>
                            <div class="input-group">
                                <input type="number" [(ngModel)]="matriculaIndicacaoPremiada" class="form-control">
                                <div class="input-group-prepend">
                                <span class="input-group-text">
                                <fa-icon [icon]="['fas', 'eye']" size="1x" (click)="buscarAsssociadoPorMatricula(matriculaIndicacaoPremiada)" style="cursor:pointer"></fa-icon>
                                </span>
                                </div>
                              </div>
                        </div>
                        <div class="col col-12 col-md-2">
                            <label>Nome de quem indicou</label><br>
                            <input type="text" [(ngModel)]="nomeIndicacaoPremiada" oninput="this.value = this.value.toUpperCase()" class="form-control" [ngStyle]="{'border-color': (indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) ? '#dd443e' : '#3cb42c'}">
                        </div>
                        <div class="col  col-2 col-md-5">
                            <label>Data Crédito no valor da mensalidade:</label><br>
                            <input type="month" maxlength="2" [(ngModel)]="mesIndicacaoPremiada" class="form-control" [ngStyle]="{'border-color': (indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) ? '#dd443e' : '#3cb42c'}">
                        </div>
                    </div>
                    <br>
                    <div class="row" style="margin-left:25px">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="teledadosBanco" [(ngModel)]="checkDadosBancarios" [disabled]="checkPix || checkVindi || checkConsignet">
                                <label class="form-check-label" for="teledadosBanco" style="margin-left:20px">DADOS BANCARIOS
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="telepixChave" [(ngModel)]="checkPix" [disabled]="checkDadosBancarios || checkVindi || checkConsignet">
                                <label class="form-check-label" for="telepixChave" style="margin-left:20px">CHAVE PIX
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="televindi" [(ngModel)]="checkVindi" [disabled]="checkDadosBancarios || checkPix || checkConsignet">
                                <label class="form-check-label" for="televindi" style="margin-left:20px">VINDI
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="teleconsignet" [(ngModel)]="checkConsignet" [disabled]="checkDadosBancarios || checkPix || checkVindi">
                                <label class="form-check-label" for="teleconsignet" style="margin-left:20px">CONSIGNET
                                </label>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <div *ngIf="checkDadosBancarios">
                        <label>Dados Bancários</label>
                        <div class="row">
                            <div class="col col-4">
                                <label>Banco</label>
                                <input type="text" [(ngModel)]="banco" class="form-control" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-2">
                                <label>Agência</label>
                                <input type="number" min="0" [(ngModel)]="agencia" class="form-control">
                            </div>
                            <div class="col col-1">
                                <label>Digito</label>
                                <input type="text" [(ngModel)]="agenciaDigito" class="form-control">
                            </div>
                            <div class="col col-2">
                                <label>Conta Corrente</label>
                                <input type="number" min="0" [(ngModel)]="contaCorrente" class="form-control">
                            </div>
                            <div class="col col-1">
                                <label>Digito</label>
                                <input type="text" [(ngModel)]="contaCorrenteDigito" class="form-control">
                            </div>
                            <div class="col col-2">
                                <label>CPF</label>
                                <input type="text" class="form-control" [(ngModel)]="cpfBanco" [textMask]="{mask: MASKS.cpf.textMask}">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="checkPix">
                        <div class="row">
                            <div class="col col-4">
                            <label>Tipo de Chave</label>
                            <select [(ngModel)]="tipoPix" class="form-control">
                                <option selected></option>
                                <option value="CNPJ">CNPJ</option>
                                <option value="CPF">CPF</option>
                                <option value="E-MAIL">E-MAIL</option>
                                <option value="TELEFONE">TELEFONE</option>
                            </select>
                            </div>
                            <div class="col col-8">
                                <label>Chave Pix</label>
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CNPJ'" [textMask]="{mask: MASKS.cnpj.textMask}">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'CPF'" [textMask]="{mask: MASKS.cpf.textMask}">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'E-MAIL'">
                                <input type="text" class="form-control" [(ngModel)]="pix" *ngIf="tipoPix == 'TELEFONE'" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row" style="padding-top:50px">
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-block" (click)="cadastrarAssociado()" [disabled]="(indicacaoPremiada && !semDadosIndicacao && (!nomeIndicacaoPremiada || !mesIndicacaoPremiada)) || (!vendaTelefone && !possuiAssDoc) || (!vendaTelefone && !assinado) || !formaPagamento || plano == '' || (tipoPlano != 'SERVIDOR' && !diaVencimento) || !cpf || !nascimento || !sexo || !rua || !numero || !bairro || !municipio || !estado || !botao || !dataAdesao">CADASTRAR ASSOCIADO</button>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
        </div>
    </div>     
</div>