<ng-http-loader></ng-http-loader>
<router-outlet id="main"></router-outlet>
<div style="height:4em" *ngIf="onOff.isOnline && db.filaEspera > 0"></div>
<div class="online" *ngIf="onOff.isOnline && db.filaEspera > 0">
    Há {{db.filaEspera}} Associados para serem Cadastrados!<br>
    <small>Certifique-se que sua conexão está boa e Sincronize!</small>
    <button class="btn btn-warning" (click)="onOff.enviarOnline()">SINCRONIZAR</button>
</div>
<div class="offline" *ngIf="!onOff.isOnline">Você está Offline</div>

<div *ngIf="message;" style="height:4em">
  <h1>{{ message.notification.title }}</h1>
  <h2>{{ message.notification.body }}</h2>
</div>
