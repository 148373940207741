<div class="modalScript" id="modal-script">
    <div class="row">
        <div class="col">
            <label>Titulo:</label>
            <input type="text" class="form-control" [(ngModel)]="tituloSelecionado">
        </div>
        <div class="col col-2">
            <label>Ordem:</label>
            <input type="number" class="form-control" [(ngModel)]="ordemSelecionado">
        </div>
    </div>
    <br><br>
    <label>Script:</label>
    <textarea class="form-control" rows="10" [(ngModel)]="scriptSelecionado"></textarea>
    <br><br>
    <div class="row" style="text-align:center">
        <div class="col">
            <button class="btn btn-danger" (click)="cancelarEdit()">
                CANCELAR
            </button>
        </div>
        <div class="col">
            <button class="btn btn-dark" (click)="editarScript()">
                SALVAR
            </button>
        </div>
    </div>
</div>
<div class="modalNovoScript" id="modal-novo-script">
    <div class="row">
        <div class="col">
            <label>Titulo:</label>
            <input type="text" class="form-control" [(ngModel)]="titulo">
        </div>
        <div class="col col-2">
            <label>Ordem:</label>
            <input type="number" class="form-control" [(ngModel)]="ordem">
        </div>
    </div>
    <br><br>
    <label>Script:</label>
    <textarea class="form-control" rows="10" [(ngModel)]="script"></textarea>
    <br><br>
    <div class="row" style="text-align:center">
        <div class="col">
            <button class="btn btn-danger" (click)="cancelarNovo()">
                CANCELAR
            </button>
        </div>
        <div class="col">
            <button class="btn btn-dark" (click)="criarScript()">
                SALVAR
            </button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            SCRIPTS DE VENDA
        </div>
        <div class="col" style="text-align:right">
            <button class="btn btn-sm btn-outline-light" (click)="novoScript()">CADASTRAR SCRIPT</button>
        </div>
    </div>
    <div class="corpo">
        <div class="row" *ngFor="let s of scripts">
            <div class="col col-10" (click)="copiar(s.script)">
                <strong>{{s.titulo}}</strong><br>
                <div class="script">
                    {{s.script}}
                </div>
            </div>
            <div class="col col-2" style="display:flex;flex-wrap: nowrap;align-items: center;justify-content: space-evenly;">
                <button class="btn btn-outline-success" (click)="openEditScript(s)">
                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </button>
                <button class="btn btn-outline-danger" (click)="excluirScript(s)">
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                </button>
            </div>
        </div>
    </div>