import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit {
  relatorio:any = [];
  totalComissaoVendedor:number = 0;

  constructor(public router:Router,public db:DataBaseService) { }

  ngOnInit(){
    this.buscarComissao();
    }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  buscarComissao(){
    this.relatorio = [];
    this.totalComissaoVendedor = 0;
    var date = new Date();
    let dataInicioComissao = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
    let dataFimComissao = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
    let vendedorComissao = localStorage.getItem('usuarioVendas');
     this.db.getAssociadosByComissao(dataInicioComissao,dataFimComissao,vendedorComissao).subscribe((data:any) => {
       this.relatorio = data;
       let totalDePlanosFisicos = 0;
       let totalDePlanosServidores = 0;
       if(this.relatorio){
         let control = 1;
         this.relatorio.forEach((item) => {
           if(item.planoFisicoCheck == true){
             totalDePlanosFisicos++
           }else{
             totalDePlanosServidores++
           }
           if(this.relatorio.length == control){
             this.totalComissaoVendedor += (totalDePlanosFisicos * this.db.comissaoPlanoFisico);
             this.totalComissaoVendedor += (totalDePlanosServidores * this.db.comissaoPlanoServidor);
           }else{
             control++
           }
         })
        }
     })
   }

}
