import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { CadastroConectaComponent } from './pages/cadastro-conecta/cadastro-conecta.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './pages/login/login.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DataBaseService } from './provider.service';
import { AuthService } from './auth.service';
import { ReplacePipe } from './replace.pipe';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DragDropDirective } from './drag-drop.directive';
import { FontAwesomeModule,FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from "@fortawesome/free-brands-svg-icons";
import { NgBrazil } from 'ng-brazil' 
import { TextMaskModule } from 'angular2-text-mask';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { OnlineOfflineService } from './online-offline.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { RelatoriosComponent } from './pages/relatorios/relatorios.component';
import { CredenciadosComponent } from './pages/credenciados/credenciados.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AssociadosComponent } from './pages/associados/associados.component';
import { DxSchedulerModule } from 'devextreme-angular';
import { FunilComponent } from './pages/funil/funil.component';
import { ControleVendasComponent } from './pages/controle-vendas/controle-vendas.component'; 
import { FiltroPipe } from './filter.pipe';
import { ScriptComponent } from './pages/script/script.component';
import { CanceladosComponent } from './pages/cancelados/cancelados.component';
import { PendentesComponent } from './pages/pendentes/pendentes.component';
import { CadastroSeguroComponent } from './pages/cadastro-seguro/cadastro-seguro.component';
import { VendasComponent } from './pages/vendas/vendas.component';
//import { initializeApp } from '@angular/fire/app';
//import { AngularFireAuthModule } from '@angular/fire/compat/auth';
//import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
//import { AngularFireModule } from '@angular/fire/compat';
//initializeApp(environment.firebase); 
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    CadastroComponent,
    CadastroConectaComponent,
    LoginComponent,
    InicioComponent,
    ReplacePipe,
    DragDropDirective,
    RelatoriosComponent,
    CredenciadosComponent,
    AgendaComponent,
    AssociadosComponent,
    FunilComponent,
    FiltroPipe,
    ControleVendasComponent,
    ScriptComponent,
    CanceladosComponent,
    PendentesComponent,
    CadastroSeguroComponent,
    VendasComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgBrazil,
    DxSchedulerModule,
    NgSelectModule,
    TextMaskModule,
    PdfJsViewerModule, 
    //AngularFireModule.initializeApp(environment.firebase),
    //AngularFireAuthModule,
    //AngularFireMessagingModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [DataBaseService, AuthService,OnlineOfflineService,DeviceDetectorService,AsyncPipe,{
    provide: LOCALE_ID,
    useValue: "pt-BR"
  }],
  bootstrap: [AppComponent]
})

export class AppModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
   }
}
