import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './auth.service';
import { DataBaseService } from './provider.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnlineOfflineService } from './online-offline.service';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  url:any = "";
  isDesktop:boolean = false;
  mensagem:any = "";
  title = 'af-notification';
  message:any = null;
  
  constructor(public router:Router, public onOff: OnlineOfflineService, public auth:AuthService, public db:DataBaseService,private swUpdate: SwUpdate,private deviceService: DeviceDetectorService){
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(){
      if(this.onOff.isOnline){
          this.swUpdate.available.subscribe(event => {
            location.reload(); 
            this.swUpdate.checkForUpdate();
        });
      //  this.requestPermission();
     //   this.listen();
    }
  }

/* 
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, 
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           console.log("Hurraaa!!! we got the token.....");
           console.log(currentToken);
         } else {
           console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message=payload;
    });
  }
 */

  goTo(local){ 
    this.router.navigate(['/'+local]);
  }

}
