import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { OnlineOfflineService } from 'src/app/online-offline.service';
import { DataBaseService } from 'src/app/provider.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  compromissosVencidos:number = 0;
  nomeUsuario:any = localStorage.getItem('usuarioVendas');
  equipe:any = [];
  
  constructor(public auth:AuthService,public db:DataBaseService,public onOff: OnlineOfflineService) { }

  ngOnInit(){
    this.compromissosVencidos = 0;
    this.nomeUsuario = localStorage.getItem('usuarioVendas');
    if(localStorage.getItem('idUserVendas') && this.onOff.isOnline){
      this.db.getUsuarioById(localStorage.getItem('idUserVendas')).subscribe((usr:any) => {
        if(usr.situacao == 'DEMITIDO'){
          this.auth.logOut()
        }else{
          this.compromissosVencidos = 0;
          this.equipe = usr.equipe;
          setTimeout(() => {
            let dataHoje = new Date(Date.now() - 10800000);
            if(this.db.agendaVendedor && this.db.agendaVendedor.length > 0){
              this.db.agendaVendedor.forEach((a) => {
                let b = a.startDate?.split('T');
                let c = b[0]?.split('-');
                let m;
                if(Number(c[1]) == 1){
                  m = 12
                }else{
                  m =  Number(c[1])-1;
                }
                if(new Date(c[0],m,c[2]) <= dataHoje){
                  this.compromissosVencidos++
                }
              })
            }
          },2000)
        }
      })
    }
  }
  
 /* getToken(chaveSecreta:any,chaveAcesso:any){
  this.firebase.getToken().then((token:any) => {
    let idUsuario = localStorage.getItem('idUserVM');
    this.db.getAssociadosById(idUsuario).subscribe((w:any) => {
    let device = w.arnDevice;
    let SESConfig = {
       apiVersion: "latest",
       accessKeyId: chaveAcesso, 
       secretAccessKey: chaveSecreta,  
       region: "sa-east-1"
     }
    AWS.config.update(SESConfig);
    let sns = new AWS.SNS();
    let params = {
      PlatformApplicationArn : 'arn:aws:sns:sa-east-1:353120821317:app/GCM/vivamax',
      Token : token
    }
    sns.createPlatformEndpoint(params).promise().then((data:any) => {
      console.log(data)
      let p = {
        Protocol: 'application',
        TopicArn : 'arn:aws:sns:sa-east-1:353120821317:marketing',
        Endpoint: data.EndpointArn
      }
      sns.subscribe(p).promise().then((res:any) => {
        console.log(res);
        if(data.EndpointArn != device){
          let dados = {
            arnDevice: data.EndpointArn
          }
          this.db.patchAssociado(idUsuario,dados).subscribe(ress => {
            console.log(ress);
          }, err => console.log(err))
        }
        }).catch((error:any) => {
            console.error(error, error.stack);
            });
          }).catch((err:any) =>  {
          console.error(err, err.stack);
        });
      })
    }).catch(error => console.error('Error getting token', error));
  } */

  sair(){
    if(window.confirm("Deseja realmente fazer logoff ?")){
      this.auth.logOut()
    }
  }
  
openModalAssociados(){
  let modalTurmas = document.getElementById('associadoModal')
  modalTurmas.style.display = "block";
  modalTurmas.scrollTo(0,0)
}

closeModalAssociados(){
  let modalTurmas = document.getElementById('associadoModal')
  modalTurmas.style.display = "none";
}


}
