import { Component} from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  show:boolean = false;
  email:any = "";
  senha:any = "";

  constructor(public authService: AuthService, public db:DataBaseService) {}

  showSenha(){
    this.show = !this.show
  }

}
