<div class="container-fluid">
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            RELATÓRIOS
        </div>
    </div>
    <div class="corpo table-responsive">
        <table class="table table-striped" style="font-size: 0.85em;">
            <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th>Adesão</th>
                    <th>Matricula</th>
                    <th>Titular</th>
                    <th>Tipo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rel of relatorio; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rel?.matricula }}</td>
                    <td>{{ rel?.nome }}</td>
                    <td>
                        <span *ngIf="">{{!rel?.planoFisicoCheck && !planoEmpresarialCheck ? 'PLANO SERVIDOR' : rel?.planoFisicoCheck ? 'PLANO FÍSICO' : 'PLANO EMPRESARIAL'}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>