import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-pendentes',
  templateUrl: './pendentes.component.html',
  styleUrls: ['./pendentes.component.scss']
})

export class PendentesComponent implements OnInit {
  relPendente:any = [];
  motivosCancelamento:any = [];
  dataHoje:any = "";
  mensagem:any = "";
  motivoCancelamento:any = "";
  observacoesCancelamento:any = "";
  idAssociado:any = "";
  nome:any = "";
  matricula:any = "";
  permissaoCancelamento:boolean = false;

  constructor(public router:Router,public db:DataBaseService) { }

  ngOnInit(): void {
    let permissoes = JSON.parse(localStorage.getItem('permissoes'));
    this.permissaoCancelamento = permissoes.cancelamentos == "EDITAR" ? true : false;
    this.relPendente = [];
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getAssociadosPendentesByVendedor(localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl:number = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.relPendente.push(a);
          if(ctrl == data.length){
            this.relPendente.sort((x:any,y:any) => {if(x.diasPendente < y.diasPendente){return 1}else{return -1}});
          }else{
            ctrl++;
          }
        })
      }
      this.db.getConfigs().subscribe((conf:any) => {
        this.motivosCancelamento = conf[13].motivosCancelamento;
      })
    })
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  mostrarAtendimento(i:any){
    let hist:any = document.getElementById('historico'+i);
    if(hist.style.display == 'block'){
      hist.style.display = 'none';
    }else{
      hist.style.display = 'block';
    }
  }

  cadastrarHistorico(id:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let dados = {
      "data" : this.dataHoje, 
      "hora" : hora, 
      "motivo" : "OUTROS", 
      "msg" : this.replaceStrings(this.mensagem), 
      "usuario" : localStorage.getItem('usuarioVendas')
    }
    this.db.newHistorico(id,dados).subscribe(res => {
      console.log(res);
      alert("Atendimento Cadastrado com Sucesso!");
      this.mensagem = "";
      this.ngOnInit();
    }, err => {
      console.log(err);
      alert("Erro ao cadastrar atendimento, por favor tente novamente!")
    })
  }

  cancelarContrato(){ 
    if(window.confirm("Deseja realmente cancelar esse Associado? Essa ação não pode ser revertida!")){
      let jsonDate = new Date().toJSON();
      let horaAgora = new Date(jsonDate).toLocaleTimeString();
      let body = {
        motivoCancelamento : this.replaceStrings(this.motivoCancelamento),
        observacoesCancelamento : this.replaceStrings(this.observacoesCancelamento),
        spcCancelamento : false,
        situacao : "CANCELADO",
        dataCancelamento: this.dataHoje
      }
      this.db.patchAssociado(this.idAssociado,body).subscribe(res => {
        console.log(res);
        let dados = {
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "motivo" : "CANCELAMENTO", 
          "msg" : this.replaceStrings(this.motivoCancelamento), 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
          console.log(res);

        let jsonDate = new Date().toJSON();
        let horaAgora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "CANCELAMENTO", 
          "descricao" : "Cancelamento do Associado: "+this.nome+" matricula nº "+this.matricula, 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
          this.closeCancelar();
        }, err => {
          console.log(err)
        })
        }, err => {
          console.log(err)
        })
      }, err => {
        console.log(err)
      })
    }
  }

  openCancelar(key:any){
    this.idAssociado = key._id;
    this.nome = key.nome;
    this.matricula = key.matricula;
    let modalCancel:any = document.getElementById('modalCancelar');
    modalCancel.style.display = "block";
  }

  closeCancelar(){
    let modalCancel:any = document.getElementById('modalCancelar');
    modalCancel.style.display = "none";
    this.motivoCancelamento = "";
    this.observacoesCancelamento = "";
    this.idAssociado = "";
    this.nome = "";
    this.matricula = "";
    this.ngOnInit();
  }
  
  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B').replace(/\#/gi,'%23').replace(/\$/gi,'%24');
  }

  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%25/gi,'%').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%26/gi,'&').replace(/%3B/gi,';').replace(/\%23/gi,'#').replace(/\%24/gi,'$');
  }

}