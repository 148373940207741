<div class="modalAssociado" id="associadoModal">
    <div class="back" (click)="closeModalAssociados()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
    <div class="bgInicio">
          <div class="contentHome">
              <div class="menuCol" routerLink="/cadastro" (click)="closeModalAssociados()">
                  <img src="../assets/img/logo-icon.png" style="height: 70px;">
                  <br>Viva Max
              </div>
              <div class="menuCol" routerLink="/cadastro-seguro" (click)="closeModalAssociados()">
                <img src="../assets/img/logo-icon.png" style="height: 70px;">
                <br>Seguro
            </div>
              <div class="menuCol" routerLink="/cadastro-conecta" (click)="closeModalAssociados()">
                  <img src="../../../assets/img/logo-conecta.png" style="height: 70px;">
                  <br>Conecta
              </div>
          </div>
    </div>
</div>
<div class="container-fluid">
    <div class="d-flex justify-content-center" style="padding:1em;">
        <img src="../../../assets/img/logo.png">
    </div>
    <div class="row" style="justify-content: space-evenly;">
        <div class="col col-5 col-md-5 buttonIcon red" (click)="openModalAssociados()">
            <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'user-plus']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>CADASTRAR</p>
            </div>
        </div>  
        <div class="col col-5 col-md-5 buttonIcon blue" [routerLink]="'/associados'">
          <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'users']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>ASSOCIADOS</p>
          </div>
        </div>
     <!--    <div class="col col-5 col-md-5 buttonIcon orange" [routerLink]="'/relatorios'">
          <div class="conteudoBotao">
            <fa-icon [icon]="['fas', 'clipboard-list']" size="2x" style="color: white"></fa-icon>
              <br>
              <p>RELATÓRIOS</p>
          </div>
        </div>  -->
        <div class="col col-5 col-md-5 buttonIcon green" [routerLink]="'/credenciados'">
          <div class="conteudoBotao">
            <fa-icon [icon]="['fas', 'hospital-user']" size="2x" style="color: white"></fa-icon>
              <br>
              <p>CREDENCIADOS</p>
          </div>
        </div> 
        <div class="col col-5 col-md-5 buttonIcon funghi" [routerLink]="'/agenda'" *ngIf="equipe?.length > 0">
          <div class="conteudoBotao">
            <fa-icon [icon]="['fas', 'book']" size="2x" style="color: white"></fa-icon>
              <br>
              <p>CONTROLE DE AGENDA</p>
          </div>
        </div>
        <div class="col col-5 col-md-5 buttonIcon clean" [routerLink]="'/vendas'">
          <div class="conteudoBotao">
            <fa-icon [icon]="['fas', 'list']" size="2x" style="color: white"></fa-icon>
              <br>
              <p>CONTROLE DE VENDAS</p>
          </div>
        </div>
        <div class="col col-5 col-md-5 buttonIcon blue" [routerLink]="'/script'">
          <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'scroll']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>SCRIPT DE VENDAS</p>
          </div>
        </div>
        <div class="col col-5 col-md-5 buttonIcon orange" [routerLink]="'/pendentes'">
          <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'user-minus']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>PENDENTES</p>
          </div>
        </div>
        <div class="col col-5 col-md-5 buttonIcon red" [routerLink]="'/cancelados'">
          <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'user-slash']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>CANCELADOS</p>
          </div>
        </div>
        <div class="col col-5 col-md-5 buttonIcon purple" [routerLink]="'/funil'">
          <div class="conteudoBotao">
              <fa-icon [icon]="['fas', 'funnel-dollar']" size="2x" style="color: white"></fa-icon>
                <br>
                <p>FUNIL DE VENDAS</p>
          </div>
        </div>
      </div>
    <div style="height: 4em;"></div>
    <div class="row" style="text-align: center;">
      <div class="col">
        <button class="btn btn-dark btn-lg" (click)="sair()"><fa-icon [icon]="['fas', 'sign-out-alt']" style="color: white"></fa-icon>&nbsp;SAIR </button>
      </div>
    </div>
    <div style="padding-top:3em;text-align: center; width: 100%; font-size: 0.7em; color:#5a5a5a">
      <strong>{{nomeUsuario}}</strong><br>
      V.1.9.6 - 19/07/2024
      <div style="height: 4em;"></div>
    </div>
  </div>