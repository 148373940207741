import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-script',
  templateUrl: './script.component.html',
  styleUrls: ['./script.component.scss']
})
export class ScriptComponent implements OnInit {
  idScript:any = "";
  tituloSelecionado:any = "";
  scriptSelecionado:any = "";
  ordemSelecionado:number = 0;
  titulo:any = "";
  script:any = "";
  ordem:number = 0
  scripts:any = [];
  dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];

  constructor(public db:DataBaseService,public router:Router){}

  ngOnInit(): void {
    this.db.getScripts().subscribe((data:any) => {
      this.scripts = data;
    })
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  copiar(frase:any){
    const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = frase;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    openEditScript(key){
      let modScript:any = document.getElementById('modal-script');
      modScript.style.display = "block";
      this.idScript = key._id;
      this.tituloSelecionado = key.titulo;
      this.scriptSelecionado = key.script;
      this.ordemSelecionado = key.ordem;
    }

    cancelarEdit(){
      let modScript:any = document.getElementById('modal-script');
      modScript.style.display = "none";
      this.idScript = "";
      this.tituloSelecionado = "";
      this.scriptSelecionado = "";
      this.ordemSelecionado = 0;
    }

    editarScript(){
        if(window.confirm('Deseja Realmente Editar esse Script?')){
          let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let dados = {
          "titulo": this.replaceStrings(this.tituloSelecionado),
          "script": this.replaceStrings(this.scriptSelecionado),
          "ordem": this.ordemSelecionado
        }
        this.db.patchScript(this.idScript,dados).subscribe(res => {
          console.log(res);
          let body = {
            "menu" : "SCRIPT VENDAS", 
            "descricao" : "Script Editado: "+this.replaceStrings(this.tituloSelecionado), 
            "data" : this.dataHoje, 
            "hora" : hora, 
            "usuario" : localStorage.getItem('usuarioVendas')
          }
          this.db.postLogs(body).subscribe(res => {
            console.log(res);
            this.cancelarEdit();
            this.ngOnInit();
          }, err => {
            console.log(err)
          })
        }, err => {
          console.log(err)
      })
      }
    }

    excluirScript(key){
      if(window.confirm('Deseja Realmente Excluir esse Script?')){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteScript(key._id).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "SCRIPT VENDAS", 
          "descricao" : "Script Excluido: "+this.replaceStrings(key.titulo), 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : localStorage.getItem('usuarioVendas')
        }
        this.db.postLogs(body).subscribe(res => {
          console.log(res);
         this.ngOnInit();
        }, err => {
          console.log(err)
        })
      }, err => {
        console.log(err)
     })
    }
  }

  novoScript(){
    let modNovoScript:any = document.getElementById('modal-novo-script');
    modNovoScript.style.display = "block";
  }

  cancelarNovo(){
    let modNovoScript:any = document.getElementById('modal-novo-script');
    modNovoScript.style.display = "none";
    this.titulo = "";
    this.script = "";
    this.ordem = 0;
  }

  criarScript(){
    if(window.confirm('Deseja Realmente Criar esse Script?')){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let dados = {
        "titulo": this.replaceStrings(this.titulo),
        "script": this.replaceStrings(this.script),
        "ordem": this.ordem
      }
    this.db.cadastroScript(dados).subscribe(res => {
      console.log(res);
      let body = {
        "menu" : "SCRIPT VENDAS", 
        "descricao" : "Script Criado: "+this.replaceStrings(this.tituloSelecionado), 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVendas')
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.cancelarNovo();
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
  })
  }
  }

  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
    }

}
