<div (click)="openMenu()" class="iconMenu">
    <img src="../../../assets/img/logo-icon.png" style="width: 2em;">MENU
</div>
<div id="mySidenav" class="sidenav">
    <div class="voltarSticky">
      <span class="closebtn" (click)="closeMenu()">
        <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>&nbsp;&nbsp;ESCONDER MENU
     </span>
    </div>
    <br>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'inicio'}" (click)="goTo('inicio')">
        <fa-icon [icon]="['fas', 'home']"></fa-icon>
        <label>INICIO</label> 
    </div>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'ganhos-perdas'}" (click)="goTo('ganhos-perdas')">
        <fa-icon [icon]="['fas', 'trophy']"></fa-icon>
        <label>GANHOS / PERDAS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'painel'}" (click)="goTo('painel')">
        <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
        <label>PAINEL DE VENDAS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'funil'}" (click)="goTo('funil')">
        <fa-icon [icon]="['fas', 'filter']"></fa-icon>
        <label>FUNIL DE VENDAS</label>
    </div>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'cadastro'}" (click)="goTo('cadastro')">
        <fa-icon [icon]="['fas', 'user-plus']"></fa-icon>
        <label>CADASTRO DE CLIENTE</label>
    </div>
    <div class="btnMenu" [ngClass]="{'ativo': sessao == 'agenda'}" (click)="goTo('agenda')">
        <fa-icon [icon]="['fas', 'book']"></fa-icon>
        <label>AGENDA</label>
        <span class="badge bg-danger ms-2" style="right:10px;top:-20px;position:relative;" *ngIf="compromissosDiaDash + compromissosAtrasadoDash > 0">{{compromissosDiaDash + compromissosAtrasadoDash}}</span>
    </div>
    <div class="btnMenu" [routerLink]="'/inicio'">
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
        <label>SAIR DO FUNIL</label>
    </div>
</div>
<!-- FIM MENU -->
<div class="conteudo" id="principal">
    <!-- AGENDA -->
    <div *ngIf="sessao == 'agenda'">
        <div class="row align-items-center header">
            <div class="col">
                <fa-icon [icon]="['fas', 'book']"></fa-icon>&nbsp;AGENDA
            </div>
        </div>
        <dx-scheduler
            [dataSource]="appointments"
            textExpr="title"
            allDayExpr="dayLong"
            recurrenceRuleExpr="recurrence"
            [(currentDate)]="dataHojeAgenda"
            [adaptivityEnabled]="false"
            (onAppointmentAdding)="addEvento($event)"
            (onAppointmentUpdating)="attEvento($event)"
            (onAppointmentDeleting)="delEvento($event)"
            (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
            (onAppointmentRendered)="renderAppointment($event)"
            [views]="['month']"
            currentView="month"
            >
        </dx-scheduler>
    </div>
    <!-- INICIO -->
    <div *ngIf="sessao == 'inicio'">
        <div class="row align-items-center header">
            <div class="col">
                <fa-icon [icon]="['fas', 'home']"></fa-icon>&nbsp;INICIO
            </div>
        </div>
        <div class="row topico">
            <div class="col">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>&nbsp;Funil de Vendas
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="dashRow">
                    <div class="dashTitulo">
                        Conversão do Mês
                    </div>
                    <div class="dashResultado">
                        {{percentualConversaoMes}}%
                    </div>
                    <div class="row dashLegenda">
                        <div class="col dashPositivo">
                            {{saldoPositivoMes | currency:'BRL'}}
                        </div>
                        <div class="col dashNegativo">
                            {{saldoNegativoMes | currency:'BRL'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="dashRow">
                    <div class="dashTitulo">
                        Em Andamento
                    </div>
                    <div class="dashResultado">
                        {{totalPropostasEmAndamento}}
                    </div>
                    <div class="row dashLegenda">
                        <div class="col dashPositivo">
                            {{valorTotalPropostasEmAndamento | currency:'BRL'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row topico">
            <div class="col">
                <fa-icon [icon]="['fas', 'book']"></fa-icon>&nbsp;Compromissos
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="dashRow">
                    <div class="dashTitulo">
                        Compromissos do Dia
                    </div>
                    <div class="dashResultado">
                        {{compromissosDiaDash}}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="dashRow">
                    <div class="dashTitulo">
                        Compromissos Atrasadas
                    </div>
                    <div class="dashResultado">
                        {{compromissosAtrasadoDash}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- PAINEL DE VENDAS -->
    <div *ngIf="sessao == 'painel'">
        <div class="row align-items-center header">
            <div class="col">
                <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>&nbsp;PAINEL DE VENDAS
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col col-5 col-md-4">
                <label>Data Inicio:</label>
                <input type="date" class="form-control" [(ngModel)]="dataInicio">
            </div>
            <div class="col col-5 col-md-4">
                <label>Data Fim:</label>
                <input type="date" class="form-control" [(ngModel)]="dataFim">
            </div>
            <div class="col col-2 col-md-4">
                <br>
                <button class="btn btn-primary" (click)="painelVendas(dataInicio,dataFim)" [disabled]="!dataInicio || !dataFim">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
        </div>
        <br>
        <div class="container-totais">
            <div class="totais green">
                <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{propostasEmAndamentoDash}}</div>
                <div class="descricao">Em Andamento</div>
                <div class="valor">{{valorEmAndamentoDash | currency:'BRL'}}</div>
            </div>
            <div class="totais yellow">
                <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{propostasNovas}}</div>
                <div class="descricao">Novas</div>
                <div class="valor">{{valorPropostasNovas | currency:'BRL'}}</div>
            </div>
            <div class="totais orange">
                <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroPerdidasGeral}}</div>
                <div class="descricao">Perdidas</div>
                <div class="valor">{{saldoNegativoMes | currency:'BRL'}}</div>
            </div>
            <div class="totais blue">
                <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroTotalGeral}}</div>
                <div class="descricao">Total</div>
                <div class="valor">{{valorTotalGeral | currency:'BRL'}}</div>
            </div>
        </div>
        <br>
       <div class="row no-gutters">
           <div class="col col-md-8 col-6">
            <ul class="funnel">
                <li [ngClass]="{'activeFunil': funil == 'leads'}" style="width: 100%;cursor:pointer" (click)="openFunil('leads')">
                    <div class="titulo">Leads<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numLeadsFunil}}</div>
                </li>
                <li [ngClass]="{'activeFunil': funil == 'contato'}" style="width: 90%;cursor:pointer" (click)="openFunil('contato')">
                    <div class="titulo">Contato<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numContatoFunil}}</div>
                </li>
                <li [ngClass]="{'activeFunil': funil == 'apresentacao'}" style="width: 85%;cursor:pointer" (click)="openFunil('apresentacao')">
                    <div class="titulo">Apresentação<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numApresentacaoFunil}}</div>
                </li>
                <li [ngClass]="{'activeFunil': funil == 'proposta'}" style="width: 80%;cursor:pointer" (click)="openFunil('proposta')">
                    <div class="titulo">Proposta<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numPropostaFunil}}</div>
                </li>
                <li [ngClass]="{'activeFunil': funil == 'fechamento'}" style="width: 75%;cursor:pointer" (click)="openFunil('fechamento')">
                    <div class="titulo">Fechamento<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numFechamentoFunil}}</div>
                </li>
                <li [ngClass]="{'activeFunil': funil == 'ganhas'}" style="width: 65%;cursor:pointer" (click)="openFunil('ganhas')">
                    <div class="titulo">Ganho<br>
                    <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numGanhasFunil}}</div>
                </li>
              </ul>
           </div>
           <div class="col col-md-4 col-6">
               <div class="conteudo-aba" id="aba-lateral" *ngIf="funil != 'ganhas'">
                   <span class="titulo">Total</span><br>
                   <div class="blue-resumo" *ngIf="numeroTotal > 0">
                        <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroTotal}}</div>
                        <div class="valor">{{valorTotal | currency:'BRL'}}</div>
                   </div>
                   <span *ngIf="numeroTotal == 0">Nenhuma Negociação Entrou nesta Etapa</span>
                    <div style="height: 2em;"></div>
                    <div class="perdidas">&times;&nbsp;Perdidas</div>
                        <div class="orange-resumo" *ngIf="numeroPerdidas > 0">
                            <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroPerdidas}}</div>
                            <div class="valor">{{valorPerdidas | currency:'BRL'}}</div>
                        </div>
                        <span *ngIf="numeroPerdidas == 0">Nenhuma Negociação foi Perdida nesta Etapa</span>
                    <div style="height: 2em;"></div>
                    <div class="em-andamento"><fa-icon [icon]="['fas', 'comments-dollar']"></fa-icon>&nbsp;Em Andamento</div>
                    <div class="cyan-resumo" *ngIf="numeroEmAndamento > 0">
                            <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroEmAndamento}}</div>
                            <div class="valor">{{valorEmAndamento | currency:'BRL'}}</div>
                    </div>
                    <span *ngIf="numeroEmAndamento == 0">Nenhuma Negociação está em andamento nesta etapa</span>
                    <div style="height: 2em;"></div>
                    <div class="ganhas">&radic;&nbsp;Ganho</div>
                    <div class="green-resumo" *ngIf="numeroGanha > 0">
                        <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numeroGanha}}</div>
                        <div class="valor">{{valorGanha | currency:'BRL'}}</div>
                    </div>
                    <span *ngIf="numeroGanha == 0">Nenhuma Negociação Ganha Nesta Etapa</span>                  
               </div>
               <div class="conteudo-aba" id="aba-lateral" *ngIf="funil == 'ganhas'">
                    <div class="ganhas">&radic;&nbsp;Ganho</div>
                        <div class="green-resumo" *ngIf="numGanhasFunil > 0">
                        <div class="numero"><fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{numGanhasFunil}}</div>
                        <div class="valor">{{saldoPositivoMes | currency:'BRL'}}</div>
                    </div>
                    <span *ngIf="numGanhasFunil == 0">Nenhuma Negociação Ganha Neste Periodo</span>                  
                </div>
           </div>
       </div>
       <div style="height: 5em;"></div>
    </div>
    <!-- FUNIL DE VENDAS -->
    <div *ngIf="sessao == 'funil'">
        <!-- MODAL PERDER -->
        <div class="modalPerderNegociacao" id="modalPerder">
            <div class="boxPerder">
                <div class="row">
                    <div class="col">
                        <label>Motivo da Perda</label>
                        <textarea class="form-control" [(ngModel)]="motivoPerdaEditar"></textarea>
                    </div>
                </div>
                <br>
                <div class="row" style="text-align: center;">
                    <div class="col col-md-6 col-6">
                        <button class="btn btn-dark" (click)="closePerderNegociacao()">CANCELAR</button>
                    </div>
                    <div class="col col-md-6 col-6">
                        <button class="btn btn-danger" (click)="enviarPerderNegociacao()" [disabled]="!motivoPerdaEditar">PERDER NEGOCIAÇÃO</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL NOVA NEGOCIAÇÃO -->
        <div class="modalNegociacao" id="modNovaNegociacao">
            <div class="box">
                <div class="row align-items-center header">
                    <div class="col col-1" (click)="closeModalNovaNegociacao()" style="padding-left:2em; text-align: left; cursor: pointer;">
                        <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                    </div>
                    <div class="col">
                        CADASTRAR NOVA NEGOCIAÇÃO
                    </div>
                </div>
                <div class="forms">
                    <div class="row">
                        <div class="col col-6 col-md-6">
                            <label>Nome Negociação</label>
                            <select class="form-control" [(ngModel)]="nomeNegociacao">
                                <option *ngFor="let nn of nomesNegociacao" [value]="nn.nome">{{nn.nome}}</option>
                            </select>
                        </div>
                        <div class="col col-6 col-md-6">
                            <label>Cliente</label>
                            <ng-select class="form-control" [items]="clientesFunil" bindLabel="nome" appendTo="body" [(ngModel)]="clienteSelecionado" (change)="cadastrarCliente(clienteSelecionado)"></ng-select>
                        </div>
                        <div class="col col-6 col-md-3">
                            <label>Valor Negociação:</label>
                            <input type="number" class="form-control" [(ngModel)]="valor" mim="0">
                        </div>
                        <div class="col col-6 col-md-3">
                            <label>Grupo Pessoas:</label>
                            <input type="number" class="form-control" [(ngModel)]="grupo" mim="0">
                        </div>
                        <div class="col col-6 col-md-3">
                            <label>Etapa:</label>
                            <select class="form-control" [(ngModel)]="etapa">
                                <option value="Leads">Leads</option>
                                <option value="Contato">Contato</option>
                                <option value="Apresentação">Apresentação</option>
                                <option value="Proposta">Proposta</option>
                                <option value="Fechamento">Fechamento</option>
                            </select>
                        </div>
                        <div class="col col-6 col-md-3">
                            <label>Status:</label>
                            <select class="form-control" [(ngModel)]="statusNegociacao">
                                <option value="Em Andamento">Em Andamento</option>
                                <option value="Ganha">Ganho</option>
                                <option value="Perdida">Perdido</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12 col-md-12">
                            <label>Tags</label>
                            <div class="tags">
                                <div class="tagSel" *ngFor="let tag of tagsSelecionadas">{{tag}}<span class="botaoFechar" (click)="removerTag(tag)" >x</span></div>
                            </div>
                            <div class="tagContainer">
                                <div class="tag" [ngClass]="{'activeTag': tagsSelecionadas.includes(t.nome)}" *ngFor="let t of tagsFunil" (click)="selecionarTag(t.nome)">{{t.nome}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="text-align: center;">
                        <div class="col col-md-12 col-12">
                            <button class="btn btn-danger" (click)="cadastrarNegociacao()" [disabled]="!nomeNegociacao || !etapa || !statusNegociacao || !clienteSelecionado  || contatos.length < 1">CADASTRAR NEGOCIAÇÃO</button>
                        </div>
                    </div>
                    <div style="height: 4em;"></div>
                </div>
            </div>
        </div>
        <!-- FIM MODAL NOVA NEGOCIAÇÃO -->
        <!-- MODAL EDITAR NEGOCIAÇÃO -->
        <div class="modalNegociacao" id="modEditNegociacao">
            <div class="box" id="boxxx">
                <!-- NOVA AGENDA -->
                <div class="modalAgendinha" id="modalNovaAgenda">
                    <div class="boxAgenda">
                        <div class="row align-items-center header">
                            <div class="col col-1" (click)="closeModalNovaAgenda()" style="padding-left:2em; text-align: left; cursor: pointer;">
                                <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                            </div>
                            <div class="col">
                                CADASTRAR NOVO COMPROMISSO
                            </div>
                        </div>
                        <br>
                        <div class="forms">
                            <div class="row">
                                <div class="col col-12 col-md-12">
                                    <label>Titulo</label>
                                    <input type="text" class="form-control" [(ngModel)]="agendaTitulo">
                                </div>
                                <div class="col col-12 col-md-12">
                                    <label>Descrição</label>
                                    <input type="text" class="form-control" [(ngModel)]="agendaDescricao">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Data Inicio</label>
                                    <input type="date" class="form-control" [(ngModel)]="agendaStart">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Data Fim</label>
                                    <input type="date" class="form-control" [(ngModel)]="agendaEnd">
                                </div>
                            </div>
                            <br>
                            <div class="row" style="text-align: center;">
                                <div class="col col-md-12 col-12">
                                    <button class="btn btn-danger" (click)="criarAgenda()" [disabled]="!agendaTitulo || !agendaDescricao || !agendaStart">CRIAR COMPROMISSO</button>
                                </div>
                            </div>
                            <div style="height: 4em;"></div>
                        </div>
                    </div>
                </div>
                <!-- EDITAR AGENDA -->
                <div class="modalAgendinha" id="modalEditAgenda">
                    <div class="boxAgenda">
                        <div class="row align-items-center header">
                            <div class="col col-1" (click)="closeModalAgenda()" style="padding-left:2em; text-align: left; cursor: pointer;">
                                <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                            </div>
                            <div class="col">
                                AGENDA
                            </div>
                        </div>
                        <br>
                        <div class="forms">
                            <div class="row">
                                <div class="col col-12 col-md-12">
                                    <label>Titulo</label>
                                    <input type="text" class="form-control" [(ngModel)]="agendaEditar.title" readonly>
                                </div>
                                <div class="col col-12 col-md-12">
                                    <label>Descrição</label>
                                    <input type="text" class="form-control" [(ngModel)]="agendaEditar.description">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Data Inicio</label>
                                    <input type="date" class="form-control" [(ngModel)]="agendaEditar.startDate">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Data Fim</label>
                                    <input type="date" class="form-control" [(ngModel)]="agendaEditar.endDate">
                                </div>
                            </div>
                            <br>
                            <div class="row" style="text-align: center;">
                                <div class="col col-md-12 col-12">
                                    <button class="btn btn-danger" (click)="editarAgenda()" [disabled]="!agendaEditar.description || !agendaEditar.startDate || !agendaEditar.title">EDITAR COMPROMISSO</button>
                                </div>
                            </div>
                            <div style="height: 4em;"></div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center header">
                    <div class="col col-1" (click)="closeModalEditarNegociacao()" style="padding-left:2em; text-align: left; cursor: pointer;">
                        <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                    </div>
                    <div class="col">
                        EDITAR NEGOCIAÇÃO
                    </div>
                </div>
                <div class="forms">
                    <div class="row">
                        <div class="col col-12 col-md-6">
                            <div class="row">
                                <div class="col col-12 col-md-12">
                                    <label>Nome Negociação</label>
                                    <select class="form-control" [(ngModel)]="nomeNegociacaoEditar">
                                        <option *ngFor="let nn of nomesNegociacao" [value]="nn.nome">{{nn.nome}}</option>
                                    </select>
                                </div>
                                <div class="col col-12 col-md-12">
                                    <label>Cliente</label>
                                    <ng-select class="form-control" [items]="clientesFunil" bindLabel="nome" appendTo="body" [(ngModel)]="clienteSelecionado" (change)="cadastrarCliente(clienteSelecionado)"></ng-select>
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Valor Negociação:</label>
                                    <input type="number" class="form-control" [(ngModel)]="valorEditar" mim="0">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Grupo Pessoas:</label>
                                    <input type="number" class="form-control" [(ngModel)]="grupoEditar" mim="0">
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Etapa:</label>
                                    <select class="form-control" [(ngModel)]="etapaEditar">
                                        <option value="Leads">Leads</option>
                                        <option value="Contato">Contato</option>
                                        <option value="Apresentação">Apresentação</option>
                                        <option value="Proposta">Proposta</option>
                                        <option value="Fechamento">Fechamento</option>
                                    </select>
                                </div>
                                <div class="col col-6 col-md-6">
                                    <label>Status:</label>
                                    <select class="form-control" [(ngModel)]="statusNegociacaoEditar">
                                        <option value="Em Andamento">Em Andamento</option>
                                        <option value="Ganha">Ganho</option>
                                        <option value="Perdida">Perdido</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" *ngIf="statusNegociacaoEditar == 'Perdida'">
                                <div class="col col-12 col-md-12">
                                    <label>Motivo da Perda:</label>
                                    <textarea class="form-control" [(ngModel)]="motivoPerdaEditar"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label>Vendedor:</label>
                                    <input type="text" class="form-control" [(ngModel)]="vendedorEditar" readonly>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-12 col-md-12">
                                    <label>Tags</label>
                                    <div class="tags">
                                        <div class="tagSel" *ngFor="let tag of tagsSelecionadasEditar">{{tag}}<span class="botaoFechar" (click)="removerTagEditar(tag)" >x</span></div>
                                    </div>
                                    <div class="tagContainer">
                                        <div class="tag" [ngClass]="{'activeTag': tagsSelecionadasEditar.includes(t.nome)}" *ngFor="let t of tagsFunil" (click)="selecionarTagEditar(t.nome)">{{t.nome}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="text-align: center;">
                                <div class="col col-md-12 col-12">
                                    <button class="btn btn-danger" (click)="editarNegociacao()" [disabled]="(statusNegociacaoEditar == 'Perdida' && !motivoPerdaEditar) || !nomeNegociacaoEditar || !etapaEditar || !statusNegociacaoEditar || contatos.length < 1 || !valorEditar || !clienteSelecionado">ALTERAR DADOS</button>
                                </div>
                            </div>
                        </div>
                        <div class="col col-12 col-md-6">
                            <div class="chatBox">
                                <div class="messages" id="msgBox">
                                    <ul *ngFor="let msg of mensagens; let i = index">
                                        <div class="topoChat">
                                            <div>
                                                <small>{{msg?.usuario}} - {{msg?.data | date:'dd/MM/yyyy'}} - {{msg?.hora}}</small>
                                            </div>
                                            <div>
                                                <fa-icon tooltip="Fixar Chat" placement="left" [icon]="['fas', 'thumbtack']" class="iconePin" (click)="mudarPrioridade(msg?.id,'fixado')"></fa-icon>
                                            </div>
                                        </div>
                                        <li class="sent">
                                            <p *ngIf="msg?.tipo == 'agenda'" (click)="openModalAgenda(msg?.idAgenda)" style="cursor: pointer;text-decoration: underline;"><fa-icon [icon]="['fas', 'address-book']" class="iconeChat"></fa-icon>{{msg?.mensagem | replace}}</p>
                                            <p *ngIf="msg?.tipo == 'texto'"><fa-icon [icon]="['fas', 'comment']" class="iconeChat"></fa-icon>{{msg?.mensagem | replace}}</p>
                                            <p *ngIf="msg?.tipo == 'anexo'"><fa-icon [icon]="['fas', 'paperclip']" class="iconeChat"></fa-icon><a [href]="msg?.mensagem" target="_blank" class="link">{{msg?.mensagem}}</a></p>
                                        </li>
                                    </ul>
                                    <ul *ngFor="let msg of mensagensFixadas; let i = index">
                                        <fa-icon tooltip="Desafixar Chat" placement="right" [icon]="['fas', 'thumbtack']" class="iconePin" (click)="mudarPrioridade(msg?.id,'normal')"></fa-icon>
                                        <li class="sent">
                                            <small>{{msg?.usuario}} - {{msg?.data | date:'dd/MM/yyyy'}} - {{msg?.hora}}</small><br>
                                            <p *ngIf="msg?.tipo == 'agenda'" (click)="openModalAgenda(msg?.idAgenda)" style="cursor: pointer;text-decoration: underline;background: #4289cc"><fa-icon [icon]="['fas', 'address-book']" class="iconeChat"></fa-icon>{{msg?.mensagem | replace}}</p>
                                            <p *ngIf="msg?.tipo == 'texto'" style="background: #4289cc"><fa-icon [icon]="['fas', 'comment']" class="iconeChat"></fa-icon>{{msg?.mensagem | replace}}</p>
                                            <p *ngIf="msg?.tipo == 'anexo'" style="background: #4289cc"><fa-icon [icon]="['fas', 'paperclip']" class="iconeChat"></fa-icon><a [href]="msg?.mensagem" target="_blank" class="link">{{msg?.mensagem}}</a></p>
                                        </li>
                                    </ul> 
                                </div>
                                <div class="message-input">
                                    <div class="wrap">
                                        <input type="text" [(ngModel)]="msgChat" placeholder="Escreva sua mensagem..." (keyup.enter)="sendChat(msgChat)">
                                        <button (click)="openModalNovaAgenda()" tooltip="Criar Compromisso na Agenda" placement="auto">
                                            <fa-icon [icon]="['fas', 'address-book']"></fa-icon>
                                        </button>
                                        <button (click)="fileInput.click()" tooltip="Anexar Documentos" placement="auto">
                                            <fa-icon [icon]="['fas', 'paperclip']"></fa-icon>
                                        </button>
                                        <input hidden type="file" #fileInput (change)="enviarDoc($event)" style="display: none;">
                                        <button (click)="sendChat(msgChat)">
                                            <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div style="height: 1em;"></div>
                </div>
            </div>
        </div>
        <!-- FIM MODAL EDITAR NEGOCIAÇÃO -->
        <div class="row align-items-center header">
            <div class="col col-8">
                <fa-icon [icon]="['fas', 'filter']"></fa-icon>&nbsp;FUNIL DE VENDAS
            </div>
            <div class="col col-4" style="text-align: right;">
                <button class="btn btn-light" (click)="openModalNovaNegociacao()">
                    NOVA NEGOCIAÇÃO
                </button>
            </div> 
        </div>
        <tabset #staticTabs>
            <tab heading="Funil de Vendas" style="padding:10px;">
            <div class="container-funil">
                <div class="coluna-funil">
                    <div class="titulo-funil">
                        <label>Leads</label>
                        <div class="row" style="padding:7px">
                            <div class="col">
                                <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{totalNegociosLeads}}
                            </div>
                            <div class="col">
                                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;{{totalGrupoLeads}}
                            </div>
                            <div class="col">
                                {{totalValorLeads | currency:'BRL'}}
                            </div>
                        </div>
                    </div>
                    <div class="negociacao-funil" *ngFor="let n of funilLeads; let i = index">
                        <label>{{n?.nomeNegociacao}}</label><br>
                        <span class="responsavel"><fa-icon [icon]="['fas', 'address-card']"></fa-icon>&nbsp;{{n?.contatos[0]?.nome}}</span><br>
                        <div class="row">
                            <div class="col col-11">
                                <span class="crono" tooltip="Dias nessa etapa"><fa-icon [icon]="['fas', 'clock']"></fa-icon>&nbsp;{{n?.diasEtapa}}d</span>&nbsp;&nbsp; <span class="info" tooltip="Dias Negociando"><fa-icon [icon]="['fas', 'info-circle']"></fa-icon>&nbsp;{{n?.diasNegociacao}}d</span>&nbsp;&nbsp; <span class="data" tooltip="Data Criação"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;{{n?.dataCriacao | date:'dd/MM/yyyy'}}</span>&nbsp;<span class="data" tooltip="Ultima Atualização"><fa-icon [icon]="['fas', 'sync']"></fa-icon>&nbsp;{{n?.dataAtualizacao | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="col col-1" style="display: flex; justify-content: space-evenly">
                                <fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon><div class="menuFloat" style="cursor:pointer">&nbsp;&nbsp;&nbsp;
                                    <div class="dropdown">
                                        <span (click)="ganharNegociacao(n)"><fa-icon [icon]="['fas', 'check']" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n)"><fa-icon [icon]="['fas', 'times']" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="openEditarNegociacao(n)"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event)">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkLeads{{i}}" id="checkLeads{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkLeads{{i}}" style="pointer-events: none;">
                                                Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkLeads{{i}}" id="checkLeads{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkLeads{{i}}" style="pointer-events: none;">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkLeads{{i}}" id="checkLeads{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkLeads{{i}}" style="pointer-events: none;">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkLeads{{i}}" id="checkLeads{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkLeads{{i}}" style="pointer-events: none;">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkLeads{{i}}" id="checkLeads{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkLeads{{i}}" style="pointer-events: none;">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <!--  -->
                <div class="coluna-funil">
                    <div class="titulo-funil">
                        <label>Contato</label>
                        <div class="row" style="padding:7px">
                            <div class="col">
                                <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{totalNegociosContato}}
                            </div>
                            <div class="col">
                                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;{{totalGrupoContato}}
                            </div>
                            <div class="col">
                                {{totalValorContato | currency:'BRL'}}
                            </div>
                        </div>
                    </div>
                    <div class="negociacao-funil" *ngFor="let n of funilContato; let i = index">
                        <label>{{n?.nomeNegociacao}}</label><br>
                        <span class="responsavel"><fa-icon [icon]="['fas', 'address-card']"></fa-icon>&nbsp;{{n?.contatos[0].nome}}</span><br>
                        <div class="row">
                            <div class="col col-11">
                                <span class="crono" tooltip="Dias nessa etapa"><fa-icon [icon]="['fas', 'clock']"></fa-icon>&nbsp;{{n?.diasEtapa}}d</span>&nbsp;&nbsp; <span class="info" tooltip="Dias Negociando"><fa-icon [icon]="['fas', 'info-circle']"></fa-icon>&nbsp;{{n?.diasNegociacao}}d</span>&nbsp;&nbsp; <span class="data" tooltip="Data Criação"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;{{n?.dataCriacao | date:'dd/MM/yyyy'}}</span>&nbsp;<span class="data" tooltip="Ultima Atualização"><fa-icon [icon]="['fas', 'sync']"></fa-icon>&nbsp;{{n?.dataAtualizacao | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="col col-1" style="display: flex; justify-content: space-evenly">
                                <fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon><div class="menuFloat" style="cursor:pointer">&nbsp;&nbsp;&nbsp;
                                    <div class="dropdown">
                                        <span (click)="ganharNegociacao(n)"><fa-icon [icon]="['fas', 'check']" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n)"><fa-icon [icon]="['fas', 'times']" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="openEditarNegociacao(n)"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event)">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkContrato{{i}}" id="checkContrato{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkContrato{{i}}" style="pointer-events: none;">
                                                Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkContrato{{i}}" id="checkContrato{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkContrato{{i}}" style="pointer-events: none;">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkContrato{{i}}" id="checkContrato{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkContrato{{i}}" style="pointer-events: none;">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkContrato{{i}}" id="checkContrato{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkContrato{{i}}" style="pointer-events: none;">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkContrato{{i}}" id="checkContrato{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkContrato{{i}}" style="pointer-events: none;">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <!--  -->
                <div class="coluna-funil">
                    <div class="titulo-funil">
                        <label>Apresentação</label>
                        <div class="row" style="padding:7px">
                            <div class="col">
                                <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{totalNegociosApresentacao}}
                            </div>
                            <div class="col">
                                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;{{totalGrupoApresentacao}}
                            </div>
                            <div class="col">
                                {{totalValorApresentacao | currency:'BRL'}}
                            </div>
                        </div>
                    </div>
                    <div class="negociacao-funil" *ngFor="let n of funilApresentacao; let i = index">
                        <label>{{n?.nomeNegociacao}}</label><br>
                        <span class="responsavel"><fa-icon [icon]="['fas', 'address-card']"></fa-icon>&nbsp;{{n?.contatos[0]?.nome}}</span><br>
                        <div class="row">
                            <div class="col col-11">
                                <span class="crono" tooltip="Dias nessa etapa"><fa-icon [icon]="['fas', 'clock']"></fa-icon>&nbsp;{{n?.diasEtapa}}d</span>&nbsp;&nbsp; <span class="info" tooltip="Dias Negociando"><fa-icon [icon]="['fas', 'info-circle']"></fa-icon>&nbsp;{{n?.diasNegociacao}}d</span>&nbsp;&nbsp; <span class="data" tooltip="Data Criação"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;{{n?.dataCriacao | date:'dd/MM/yyyy'}}</span>&nbsp;<span class="data" tooltip="Ultima Atualização"><fa-icon [icon]="['fas', 'sync']"></fa-icon>&nbsp;{{n?.dataAtualizacao | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="col col-1" style="display: flex; justify-content: space-evenly">
                                <fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon><div class="menuFloat" style="cursor:pointer">&nbsp;&nbsp;&nbsp;
                                    <div class="dropdown">
                                        <span (click)="ganharNegociacao(n)"><fa-icon [icon]="['fas', 'check']" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n)"><fa-icon [icon]="['fas', 'times']" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="openEditarNegociacao(n)"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event)">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkApresentacao{{i}}" id="checkApresentacao{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkApresentacao{{i}}" style="pointer-events: none;">
                                                Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkApresentacao{{i}}" id="checkApresentacao{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkApresentacao{{i}}" style="pointer-events: none;">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkApresentacao{{i}}" id="checkApresentacao{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkApresentacao{{i}}" style="pointer-events: none;">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkApresentacao{{i}}" id="checkApresentacao{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkApresentacao{{i}}" style="pointer-events: none;">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkApresentacao{{i}}" id="checkApresentacao{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkApresentacao{{i}}" style="pointer-events: none;">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <!--  -->
                <div class="coluna-funil">
                    <div class="titulo-funil">
                        <label>Proposta</label>
                        <div class="row" style="padding:7px">
                            <div class="col">
                                <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{totalNegociosProposta}}
                            </div>
                            <div class="col">
                                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;{{totalGrupoProposta}}
                            </div>
                            <div class="col">
                                {{totalValorProposta | currency:'BRL'}}
                            </div>
                        </div>
                    </div>
                    <div class="negociacao-funil" *ngFor="let n of funilProposta; let i = index">
                        <label>{{n?.nomeNegociacao}}</label><br>
                        <span class="responsavel"><fa-icon [icon]="['fas', 'address-card']"></fa-icon>&nbsp;{{n?.contatos[0].nome}}</span><br>
                        <div class="row">
                            <div class="col col-11">
                                <span class="crono" tooltip="Dias nessa etapa"><fa-icon [icon]="['fas', 'clock']"></fa-icon>&nbsp;{{n?.diasEtapa}}d</span>&nbsp;&nbsp; <span class="info" tooltip="Dias Negociando"><fa-icon [icon]="['fas', 'info-circle']"></fa-icon>&nbsp;{{n?.diasNegociacao}}d</span>&nbsp;&nbsp; <span class="data" tooltip="Data Criação"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;{{n?.dataCriacao | date:'dd/MM/yyyy'}}</span>&nbsp;<span class="data" tooltip="Ultima Atualização"><fa-icon [icon]="['fas', 'sync']"></fa-icon>&nbsp;{{n?.dataAtualizacao | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="col col-1" style="display: flex; justify-content: space-evenly">
                                <fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon><div class="menuFloat" style="cursor:pointer">&nbsp;&nbsp;&nbsp;
                                    <div class="dropdown">
                                        <span (click)="ganharNegociacao(n)"><fa-icon [icon]="['fas', 'check']" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n)"><fa-icon [icon]="['fas', 'times']" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="openEditarNegociacao(n)"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event)">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkProposta{{i}}" id="checkProposta{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkProposta{{i}}" style="pointer-events: none;">
                                                Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkProposta{{i}}" id="checkProposta{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkProposta{{i}}" style="pointer-events: none;">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkProposta{{i}}" id="checkProposta{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkProposta{{i}}" style="pointer-events: none;">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkProposta{{i}}" id="checkProposta{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkProposta{{i}}" style="pointer-events: none;">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkProposta{{i}}" id="checkProposta{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkProposta{{i}}" style="pointer-events: none;">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="coluna-funil">
                    <div class="titulo-funil">
                        <label>Fechamento</label>
                        <div class="row" style="padding:7px">
                            <div class="col">
                                <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>&nbsp;{{totalNegociosFechamento}}
                            </div>
                            <div class="col">
                                <fa-icon [icon]="['fas', 'users']"></fa-icon>&nbsp;{{totalGrupoFechamento}}
                            </div>
                            <div class="col">
                                {{totalValorFechamento | currency:'BRL'}}
                            </div>
                        </div>
                    </div>
                    <div class="negociacao-funil" *ngFor="let n of funilFechamento;let i = index">
                        <label>{{n?.nomeNegociacao}}</label><br>
                        <span class="responsavel"><fa-icon [icon]="['fas', 'address-card']"></fa-icon>&nbsp;{{n?.contatos[0].nome}}</span><br>
                        <div class="row">
                            <div class="col col-11">
                                <span class="crono" tooltip="Dias nessa etapa"><fa-icon [icon]="['fas', 'clock']"></fa-icon>&nbsp;{{n?.diasEtapa}}d</span>&nbsp;&nbsp; <span class="info" tooltip="Dias Negociando"><fa-icon [icon]="['fas', 'info-circle']"></fa-icon>&nbsp;{{n?.diasNegociacao}}d</span>&nbsp;&nbsp; <span class="data" tooltip="Data Criação"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>&nbsp;{{n?.dataCriacao | date:'dd/MM/yyyy'}}</span>&nbsp;<span class="data" tooltip="Ultima Atualização"><fa-icon [icon]="['fas', 'sync']"></fa-icon>&nbsp;{{n?.dataAtualizacao | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div class="col col-1" style="display: flex; justify-content: space-evenly">
                                <fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon><div class="menuFloat" style="cursor:pointer">&nbsp;&nbsp;&nbsp;
                                    <div class="dropdown">
                                        <span (click)="ganharNegociacao(n)"><fa-icon [icon]="['fas', 'check']" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n)"><fa-icon [icon]="['fas', 'times']" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="openEditarNegociacao(n)"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event)">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkFechamento{{i}}" id="checkFechamento{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkFechamento{{i}}" style="pointer-events: none">
                                                    Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkFechamento{{i}}" id="checkFechamento{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkFechamento{{i}}" style="pointer-events: none">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkFechamento{{i}}" id="checkFechamento{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkFechamento{{i}}" style="pointer-events: none">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkFechamento{{i}}" id="checkFechamento{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkFechamento{{i}}" style="pointer-events: none">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkFechamento{{i}}" id="checkFechamento{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkFechamento{{i}}" style="pointer-events: none">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="Relatório" style="padding:10px">
            <div class="row">
                <div class="col col-3 col-md-3">
                    <label>Data Inicio</label>
                    <input class="form-control" type="date" (change)="filtrarRelatorio(dataInicioRel,dataFimRel,vendedorRel)" [(ngModel)]="dataInicioRel">
                </div>
                <div class="col col-3 col-md-3">
                    <label>Data Fim</label>
                    <input class="form-control" type="date" (change)="filtrarRelatorio(dataInicioRel,dataFimRel,vendedorRel)" [(ngModel)]="dataFimRel">
                </div>
                <div class="col col-4 col-md-4">
                    <label>Vendedor</label>
                    <select class="form-control" [(ngModel)]="vendedorRel" (change)="filtrarRelatorio(dataInicioRel,dataFimRel,vendedorRel)">
                        <option value="" selected></option>
                        <option *ngFor="let v of db.tableVendedores" [value]="v.nome">{{v.nome}}</option>
                    </select>
                </div>
                <div class="col col-2 col-md-2">
                    <br>
                    <button class="btn btn-primary" (click)="showTodosRelatorios()">
                        TODOS
                    </button>
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th (click)="sortTable('nomeNegociacao')" class="sortTitulo">Nome da Negociação&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('contato')" class="sortTitulo">Cliente&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('vendedor')" class="sortTitulo">Responsável&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('etapa')" class="sortTitulo">Etapa&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('status')" class="sortTitulo">Status&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('motivoPerda')" class="sortTitulo">Motivo da Perda&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('valor')" class="sortTitulo">Valor&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('dataCriacao')" class="sortTitulo">Data Criação&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th (click)="sortTable('dataTermino')" class="sortTitulo">Data Término&nbsp;&nbsp;<fa-icon [icon]="['fas', 'sort']"></fa-icon></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let n of negociacoes; let i = index">
                            <td>{{ n?.nomeNegociacao }}</td>
                            <td>{{ n?.contatos[0]?.nome }}</td>
                            <td>{{ n?.vendedor }}</td>
                            <td>{{ n?.etapa }}</td>
                            <td>{{ n?.status }}</td>
                            <td>{{ n?.motivoPerda }}</td>
                            <td>{{ n?.valor | currency:'BRL' }}</td>
                            <td>{{ n?.dataCriacao | date:'dd/MM/yyyy' }}</td>
                            <td>{{ n?.dataTermino | date:'dd/MM/yyyy' }}</td>
                            <td>  
                                <div class="menuRelatorio">
                                    <input type="checkbox" id="c{{i}}" name="relList" class="checkCheck" (click)="uncheck($event)">
                                    <label for="c{{i}}"><fa-icon [icon]="['fas', 'bars']" style="cursor:pointer"></fa-icon></label>
                                    <div class="dropRelatorio">
                                        <span (click)="ganharNegociacao(n);uncheckAll()" [ngStyle]="{'cursor': n.status != 'Ganha' ? 'pointer' : 'default','color': n.status != 'Ganha' ? '#128546' : '#727272'}"><fa-icon [icon]="['fas', 'check']" ></fa-icon>&nbsp;Ganhou</span>
                                        <span (click)="perderNegociacao(n);uncheckAll()" [ngStyle]="{'cursor': n.status != 'Perdida' ? 'pointer' : 'default','color': n.status != 'Perdida' ? '#c7444a' : '#727272'}"><fa-icon [icon]="['fas', 'times']"></fa-icon>&nbsp;Perdeu</span>
                                        <span (click)="retomarNegociacao(n);uncheckAll()" [ngStyle]="{'cursor': n.status != 'Em Andamento' ? 'pointer' : 'default','color': n.status != 'Em Andamento' ? '#35afb8' : '#727272'}"><fa-icon [icon]="['fas', 'retweet']"></fa-icon>&nbsp;Retomar</span>
                                        <span (click)="openEditarNegociacao(n);uncheckAll()"><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp;Editar</span>
                                        <hr>
                                        <label>Mudar Etapa:</label>
                                        <div class="checkboxes" (change)="alterarEtapa(n,$event);uncheckAll()">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkRel{{i}}" id="checkRel{{i}}" [checked]="n.etapa == 'Leads'" value="Leads">
                                                <label class="form-check-label" for="checkRel{{i}}" style="pointer-events: none;">
                                                    Leads
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkRel{{i}}" id="checkRel{{i}}" [checked]="n.etapa == 'Contato'" value="Contato">
                                                <label class="form-check-label" for="checkRel{{i}}" style="pointer-events: none;">
                                                    Contato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkRel{{i}}" id="checkRel{{i}}" [checked]="n.etapa == 'Apresentação'" value="Apresentação">
                                                <label class="form-check-label" for="checkRel{{i}}" style="pointer-events: none;">
                                                    Apresentação
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkRel{{i}}" id="checkRel{{i}}" [checked]="n.etapa == 'Proposta'" value="Proposta">
                                                <label class="form-check-label" for="checkRel{{i}}" style="pointer-events: none;">
                                                    Proposta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="checkRel{{i}}" id="checkRel{{i}}" [checked]="n.etapa == 'Fechamento'" value="Fechamento">
                                                <label class="form-check-label" for="checkRel{{i}}" style="pointer-events: none;">
                                                    Fechamento
                                                </label>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </tab>
    </tabset>
    </div>
     <!-- CADSTRO DE CLIENTES -->
     <div *ngIf="sessao == 'cadastro'">
         <!-- MODAL NOVO CLIENTE -->
        <div class="modal" id="modNovoCliente">
            <div class="row align-items-center header">
                <div class="col col-1" (click)="closeModalNovoCliente()" style="text-align: left; cursor: pointer;">
                    <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                </div>
                <div class="col">
                    CADASTRAR NOVO CLIENTE
                </div>
            </div>
            <div class="forms">
                <div class="row">
                    <div class="col col-12 col-md-4">
                        <label>Nome Contato</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Tipo</label>
                        <select class="form-control" [(ngModel)]="tipoCliente">
                            <option value="" selected></option>
                            <option value="ÓRGÃO PÚBLICO">ÓRGÃO PÚBLICO</option>
                            <option value="PESSOA FÍSICA">PESSOA FÍSICA</option>
                            <option value="PESSOA JURÍDICA">PESSOA JURÍDICA</option>
                        </select>
                    </div>
                    <div class="col col-6 col-md-4" *ngIf="tipoCliente == 'PESSOA FÍSICA'">
                        <label>CPF</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfCnpjCliente" cpf [textMask]="{mask: MASKS.cpf.textMask}">
                    </div>
                    <div class="col col-6 col-md-4" *ngIf="tipoCliente != 'PESSOA FÍSICA'">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfCnpjCliente" cnpj [textMask]="{mask: MASKS.cnpj.textMask}">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoCliente == 'PESSOA FÍSICA'">
                        <label>Local Trabalho</label>
                        <input type="text" class="form-control" [(ngModel)]="localTrabalhoCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoCliente == 'PESSOA FÍSICA'">
                        <label>Função</label>
                        <input type="text" class="form-control" [(ngModel)]="funcaoCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoCliente == 'PESSOA JURÍDICA' || tipoCliente == 'ÓRGÃO PÚBLICO'">
                        <label>Nome Empresa</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeEmpresaCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoCliente == 'PESSOA JURÍDICA' || tipoCliente == 'ÓRGÃO PÚBLICO'">
                        <label>Nome Responsável</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeResponsavelCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneCliente">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Telefone 2:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone2Cliente">
                    </div>
                    <div class="col col-12 col-md-4">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" [(ngModel)]="emailCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-4 col-4">
                        <label>CEP:</label>
                        <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepCliente" (keyup)="buscarCep(cepCliente)">
                    </div>
                    <div class="col col-md-5 col-8">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="ruaCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-4">
                        <label>Número:</label>
                        <input type="text" class="form-control" [(ngModel)]="numeroCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-4 col-8">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="bairroCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-12">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="complementoCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-9">
                        <label>Município:</label>
                        <input type="text" class="form-control" [(ngModel)]="municipioCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-2 col-3">
                        <label>UF:</label>
                        <input type="text" class="form-control" [(ngModel)]="estadoCliente" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Tags</label>
                        <div class="tags">
                            <div class="tagSel" *ngFor="let tag of tagsSelecionadas">{{tag}}<span class="botaoFechar" (click)="removerTag(tag)" >x</span></div>
                        </div>
                        <div class="tagContainer">
                            <div class="tag" [ngClass]="{'activeTag': tagsSelecionadas.includes(t.nome)}" *ngFor="let t of tagsFunil" (click)="selecionarTag(t.nome)">{{t.nome}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-12">
                        <label>Informações Adicionais</label>
                        <textarea class="form-control" [(ngModel)]="informacoesAdicionaisCliente" rows="3"></textarea>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col col-md-6 col-6">
                        <button class="btn btn-dark" (click)="closeModalNovoCliente()">CANCELAR</button>
                    </div>
                    <div class="col col-md-6 col-6">
                        <button class="btn btn-danger" (click)="cadastrarNovoCliente()" [disabled]="!tipoCliente || (tipoCliente == 'PESSOA FÍSICA' && (!nomeCliente || !ruaCliente || !numeroCliente || !bairroCliente || !municipioCliente || !estadoCliente || !telefoneCliente || tagsSelecionadas.length < 1)) || (tipoCliente == 'ÓRGÃO PÚBLICO' && (!nomeCliente || !nomeEmpresaCliente || !nomeResponsavelCliente || !emailCliente || !ruaCliente || !numeroCliente || !bairroCliente || !municipioCliente || !estadoCliente || !telefoneCliente || tagsSelecionadas.length < 1)) || (tipoCliente == 'PESSOA JURÍDICA' && (!nomeCliente || !nomeEmpresaCliente || !nomeResponsavelCliente || !emailCliente || !ruaCliente || !numeroCliente || !bairroCliente || !municipioCliente || !estadoCliente || !telefoneCliente || !cpfCnpjCliente || tagsSelecionadas.length < 1))">CADASTRAR</button>
                    </div>
                </div>
                <div style="height: 4em;"></div>
            </div>
        </div>
        <!-- FIM MODAL NOVO CLIENTE -->
         <!-- MODAL EDITAR CLIENTE -->
         <div class="modal" id="modEditarCliente">
            <div class="row align-items-center header">
                <div class="col col-1" (click)="closeModalEditarCliente()" style="text-align: left; cursor: pointer;">
                    <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
                </div>
                <div class="col">
                    EDITAR CLIENTE
                </div>
            </div>
            <div class="forms">
                <div class="row">
                    <div class="col col-12 col-md-4">
                        <label>Nome Contato</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Tipo</label>
                        <select class="form-control" [(ngModel)]="tipoClienteEditar">
                            <option value="" selected></option>
                            <option value="ÓRGÃO PÚBLICO">ÓRGÃO PÚBLICO</option>
                            <option value="PESSOA FÍSICA">PESSOA FÍSICA</option>
                            <option value="PESSOA JURÍDICA">PESSOA JURÍDICA</option>
                        </select>
                    </div>
                    <div class="col col-6 col-md-4" *ngIf="tipoClienteEditar == 'PESSOA FÍSICA'">
                        <label>CPF</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfCnpjClienteEditar" cpf [textMask]="{mask: MASKS.cpf.textMask}">
                    </div>
                    <div class="col col-6 col-md-4" *ngIf="tipoClienteEditar != 'PESSOA FÍSICA'">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" [(ngModel)]="cpfCnpjClienteEditar" cnpj [textMask]="{mask: MASKS.cnpj.textMask}">
                    </div>
                     <div class="col col-6 col-md-6" *ngIf="tipoClienteEditar == 'PESSOA FÍSICA'">
                        <label>Local Trabalho</label>
                        <input type="text" class="form-control" [(ngModel)]="localTrabalhoClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoClienteEditar == 'PESSOA FÍSICA'">
                        <label>Função</label>
                        <input type="text" class="form-control" [(ngModel)]="funcaoClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoClienteEditar == 'PESSOA JURÍDICA' || tipoClienteEditar == 'ÓRGÃO PÚBLICO'">
                        <label>Nome Empresa</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeEmpresaClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-6" *ngIf="tipoClienteEditar == 'PESSOA JURÍDICA' || tipoClienteEditar == 'ÓRGÃO PÚBLICO'">
                        <label>Nome Responsável</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeResponsavelClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneClienteEditar">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Telefone 2:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefone2ClienteEditar">
                    </div>
                    <div class="col col-12 col-md-4">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" [(ngModel)]="emailClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-4 col-4">
                        <label>CEP:</label>
                        <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepClienteEditar" (keyup)="buscarCepEditar(cepClienteEditar)">
                    </div>
                    <div class="col col-md-5 col-8">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="ruaClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-4">
                        <label>Número:</label>
                        <input type="text" class="form-control" [(ngModel)]="numeroClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-4 col-8">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="bairroClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-12">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="complementoClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-3 col-9">
                        <label>Município:</label>
                        <input type="text" class="form-control" [(ngModel)]="municipioClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-md-2 col-3">
                        <label>UF:</label>
                        <input type="text" class="form-control" [(ngModel)]="estadoClienteEditar" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Tags</label>
                        <div class="tags">
                            <div class="tagSel" *ngFor="let tag of tagsSelecionadasEditar">{{tag}}<span class="botaoFechar" (click)="removerTagEditar(tag)" >x</span></div>
                        </div>
                        <div class="tagContainer">
                            <div class="tag" [ngClass]="{'activeTag': tagsSelecionadasEditar.includes(t.nome)}" *ngFor="let t of tagsFunil" (click)="selecionarTagEditar(t.nome)">{{t.nome}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-12">
                        <label>Informações Adicionais</label>
                        <textarea class="form-control" [(ngModel)]="informacoesAdicionaisClienteEditar" rows="3"></textarea>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <button class="btn btn-dark" (click)="closeModalEditarCliente()">CANCELAR</button>
                    </div>
                    <div class="col col-md-6 col-6" [hidden]="(idUsuario != idVendedorCliente) && (tipoUsuario != 'MASTER' && tipoUsuario != 'GERENTE')">
                        <button class="btn btn-danger" (click)="editarCliente()" [disabled]="!tipoClienteEditar || (tipoClienteEditar == 'PESSOA FÍSICA' && (!nomeClienteEditar || !ruaClienteEditar || !numeroClienteEditar || !bairroClienteEditar || !municipioClienteEditar || !estadoClienteEditar || !telefoneClienteEditar || tagsSelecionadasEditar.length < 1)) || (tipoClienteEditar == 'ÓRGÃO PÚBLICO' && (!nomeClienteEditar || !nomeEmpresaClienteEditar || !nomeResponsavelClienteEditar || !emailClienteEditar || !ruaClienteEditar || !numeroClienteEditar || !bairroClienteEditar || !municipioClienteEditar || !estadoClienteEditar || !telefoneClienteEditar || tagsSelecionadasEditar.length < 1)) || (tipoClienteEditar == 'PESSOA JURÍDICA' && (!nomeClienteEditar || !nomeEmpresaClienteEditar || !nomeResponsavelClienteEditar || !emailClienteEditar || !ruaClienteEditar || !numeroClienteEditar || !bairroClienteEditar || !municipioClienteEditar || !estadoClienteEditar || !telefoneClienteEditar || !cpfCnpjClienteEditar || tagsSelecionadasEditar.length < 1))">SALVAR</button>
                    </div>
                </div>
                <div style="height: 4em;"></div>
            </div>
        </div>
        <!-- FIM MODAL EDITAR CLIENTE -->
        <div class="row align-items-center header">
            <div class="col col-8">
                <fa-icon [icon]="['fas', 'user-plus']"></fa-icon>&nbsp;CADASTRO DE CLIENTES
            </div>
            <div class="col col-4" style="text-align: right;">
                <button class="btn btn-light" (click)="openModalNovoCliente()">
                    NOVO CLIENTE
                </button>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col col-4">
                <label>Nome</label>
                <input type="text" class="form-control" [(ngModel)]="nomeBusca" (keyup)="filterClientes(nomeBusca,vendedorBusca,dataBuscaInicio,dataBuscaFim,empresaBusca)">
            </div>
            <div class="col col-4">
                <label>Vendedor</label>
                <select class="form-control" [(ngModel)]="vendedorBusca" (change)="filterClientes(nomeBusca,vendedorBusca,dataBuscaInicio,dataBuscaFim,empresaBusca)">
                    <option value="" selected></option>
                    <option *ngFor="let v of db.tableVendedores" [value]="v.nome">{{v.nome}}</option>
                </select>
            </div>
            <div class="col col-4">
                <label>Empresa</label>
                <input type="text" class="form-control" [(ngModel)]="empresaBusca" (keyup)="filterClientes(nomeBusca,vendedorBusca,dataBuscaInicio,dataBuscaFim,empresaBusca)">
            </div>
        </div>
        <div class="row">
            <div class="col col-3">
                <label>Data Inicio</label>
                <input type="date" class="form-control" [(ngModel)]="dataBuscaInicio" (change)="filterClientes(nomeBusca,vendedorBusca,dataBuscaInicio,dataBuscaFim,empresaBusca)">
            </div>
            <div class="col col-3">
                <label>Data Fim</label>
                <input type="date" class="form-control" [(ngModel)]="dataBuscaFim" (change)="filterClientes(nomeBusca,vendedorBusca,dataBuscaInicio,dataBuscaFim,empresaBusca)">
            </div>
            <div class="col col-3">
                <br>
                <button class="btn btn-primary" (click)="showClientes()">MOSTRAR TODOS</button>
            </div>
        </div>
        <br>
        <table class="table table-striped" style="text-align: center;">
            <thead class="thead-dark">
                <tr>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>Empresa</th>
                    <th>Cliente</th>
                    <th>Nome Responsável</th>
                    <th>Telefone</th>
                    <th>Cidade</th>
                    <th *ngIf="tipoUsuario != 'VENDEDOR'">Vendedor</th>
                    <th>Ver</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cliente of clientesFunil">
                    <td>{{ cliente?.data | date:'dd/MM/yyyy' }}</td>
                    <td>{{ cliente?.tipo }}</td>
                    <td>
                        <span *ngIf="cliente.tipo != 'PESSOA FÍSICA'">{{ cliente?.nomeEmpresa }}</span>
                        <span *ngIf="cliente.tipo == 'PESSOA FÍSICA'">{{ cliente?.localTrabalhoCliente }}</span>
                    </td>
                    <td>{{ cliente?.nome }}</td>
                    <td>{{ cliente?.nomeResponsavel }}</td>
                    <td>{{ cliente?.telefone }}</td>
                    <td>{{ cliente?.municipio }}</td>
                    <td *ngIf="tipoUsuario != 'VENDEDOR'">{{cliente?.vendedor}}</td>
                    <td>
                        <button class="btn btn-outline-success btn-sm" (click)="openEditarCliente(cliente)">
                            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>