<div class="container-fluid">
    <div class="modal" id="modalCancelar">
        <div class="row align-items-center">
            <div class="col">
                <label>Motivo do Cancelamento</label>
                <select class="form-control" [(ngModel)]="motivoCancelamento">
                    <option value="" selected></option>
                    <option *ngFor="let mot of motivosCancelamento" [value]="mot.nome">{{mot.nome}}</option>
                </select>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="col">
                <label>Observações do Cancelamento</label>
                <textarea class="form-control" [(ngModel)]="observacoesCancelamento" rows="4" oninput="this.value = this.value.toUpperCase()"></textarea>
            </div>
        </div>
        <br>
        <div class="row" style="text-align:center">
            <div class="col">
                <button class="btn btn-danger btn-block" (click)="closeCancelar()">
                    VOLTAR
                </button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-block" (click)="cancelarContrato()" [disabled]="!motivoCancelamento || !observacoesCancelamento">
                    CANCELAR CONTRATO
                </button>
            </div>
        </div>
    </div>
    <div class="row align-items-center header">
        <div class="col col-1" (click)="backToDash()" style="text-align: left; cursor: pointer;">
            <fa-icon [icon]="['fas', 'angle-left']" size="2x" style="color: white"></fa-icon>
        </div>
        <div class="col">
            PENDENTES
        </div>
    </div>
    <div class="corpo">
        <div class="row" style="margin:5px;font-weight: bold;text-align: center">
            <div class="col">
                Adesão
            </div>
            <div class="col">
                Matricula
            </div>
            <div class="col">
                Nome
            </div>
            <div class="col">
                Celular
            </div>
            <div class="col">
                Dias
            </div>
            <div class="col">
                
            </div>
            <div class="col">
                
            </div>
        </div>
        <div class="row" *ngFor="let rel of relPendente; let i = index" style="margin:5px;text-align: center;border-bottom: 1px solid #c4c4c4;">
            <div class="col">
                {{ rel?.dataAdesao | date: 'dd/MM/yyyy' }}
            </div>
            <div class="col">
                {{ rel?.matricula }}
            </div>
            <div class="col">
                {{ rel?.nome }}
            </div>
            <div class="col">
                {{ rel?.celular }}
            </div>
            <div class="col">
                {{ rel?.diasPendente }}
            </div>
            <div class="col">
                <button class="btn btn-primary" (click)="mostrarAtendimento(i)">
                    <fa-icon [icon]="['fas', 'search']" style="color: white"></fa-icon>
                </button>
            </div>
            <div class="col" *ngIf="permissaoCancelamento">
                <button class="btn btn-danger" (click)="openCancelar(rel)">
                    <fa-icon [icon]="['fas', 'trash']" style="color: white"></fa-icon>
                </button>
            </div>
            <div class="col col-12 historicoDisplay" id="historico{{i}}">
                <div class="card" style="margin:14px" *ngFor="let a of rel?.historicos">
                    <h5 class="card-header" style='font-size:16px; background-color: #274562; color:white'>{{a?.motivo}}</h5>
                    <div class="card-body">
                        <h5 class="card-title" style="font-size:16px !important">{{a?.data | date:'dd/MM/yyyy'}} - {{a?.hora}}</h5>
                        <h6 class="card-title" style="font-size:16px !important">Atendente: {{a?.usuario}}</h6>
                        <p class="card-text" style="font-size:16px !important">{{reverseReplaceStrings(a?.msg)}}</p>
                    </div>
                </div>
                <br>
                <textarea [(ngModel)]="mensagem" rows="4" style="width:100%"></textarea>
                <button class="btn btn-success" (click)="cadastrarHistorico(rel?._id)">ADICIONAR NOVO ATENDIMENTO</button>
                <br><br>
            </div>
        </div>
    </div>
</div>