import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AssociadosComponent } from './pages/associados/associados.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { CadastroConectaComponent } from './pages/cadastro-conecta/cadastro-conecta.component';
import { CredenciadosComponent } from './pages/credenciados/credenciados.component';
import { AuthGuard } from './pages/guard/auth.guard';
import { InicioComponent } from './pages/inicio/inicio.component';
import { LoginComponent } from './pages/login/login.component';
import { FunilComponent } from './pages/funil/funil.component';
import { RelatoriosComponent } from './pages/relatorios/relatorios.component';
import { ControleVendasComponent } from './pages/controle-vendas/controle-vendas.component';
import { ScriptComponent } from './pages/script/script.component';
import { PendentesComponent } from './pages/pendentes/pendentes.component';
import { CanceladosComponent } from './pages/cancelados/cancelados.component';
import { CadastroSeguroComponent } from './pages/cadastro-seguro/cadastro-seguro.component';
import { VendasComponent } from './pages/vendas/vendas.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'login', component:LoginComponent },
  { path: 'cadastro', component:CadastroComponent, canActivate: [AuthGuard] },
  { path: 'cadastro-conecta', component:CadastroConectaComponent, canActivate: [AuthGuard] },
  { path: 'cadastro-seguro', component:CadastroSeguroComponent, canActivate: [AuthGuard] },
  { path: 'inicio', component:InicioComponent, canActivate: [AuthGuard] },
  { path: 'associados', component:AssociadosComponent, canActivate: [AuthGuard] },
  { path: 'relatorios', component:RelatoriosComponent, canActivate: [AuthGuard] },
  { path: 'credenciados', component:CredenciadosComponent, canActivate: [AuthGuard] },
  { path: 'funil', component:FunilComponent, canActivate: [AuthGuard] },
  { path: 'controle-vendas', component:ControleVendasComponent, canActivate: [AuthGuard] },
  { path: 'agenda', component:AgendaComponent, canActivate: [AuthGuard] },
  { path: 'vendas', component:VendasComponent, canActivate: [AuthGuard] },
  { path: 'script', component:ScriptComponent, canActivate: [AuthGuard] },
  { path: 'pendentes', component:PendentesComponent, canActivate: [AuthGuard] },
  { path: 'cancelados', component:CanceladosComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
