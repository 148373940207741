import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MASKS } from 'ng-brazil';
import { OnlineOfflineService } from 'src/app/online-offline.service';
import { DataBaseService } from 'src/app/provider.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-credenciados',
  templateUrl: './credenciados.component.html',
  styleUrls: ['./credenciados.component.scss']
})

export class CredenciadosComponent implements OnInit{
  public MASKS = MASKS;
  cidade:any = null;
  especialidade:any = null;
  credenciados:any = [];
  cidades:any = [];
  especialidades:any = [];
  procurado:boolean = false;
  cidadeProcurada:any = "";
  especialidadeProcurada:any = "";
  load:boolean = true;
  intervalo:any = "";
  todosCredenciados:any = [];
  nomeAcesso:any = "";
  emailAcesso:any = "";
  celularAcesso:any = "";
  dataHoje:any = "";
  lead:any = "";

  constructor(public app:AppComponent, public router:Router,public db:DataBaseService,public onOff: OnlineOfflineService) { }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.intervalo = setInterval(() => {
      if(this.db.credenciados.length == 0){
        this.load = true;
      }else{
        this.todosCredenciados = this.db.credenciados;
        this.load = false;
        clearInterval(this.intervalo)
      }
    },1000)
  }

  search(){
    this.credenciados = [];
    this.procurado = true;
    let cid =  this.cidade == null ? "" :  this.cidade.cidade;
    let esp = this.especialidade == null ? "" : this.especialidade.nome;
    if(cid == "" && esp == ""){
      this.credenciados = this.todosCredenciados;
    }else{
      this.todosCredenciados.forEach((item) => {
        if(item.municipio.match(cid) && item.especialidade.match(esp)){
          let index = this.credenciados.map((x) => {
            return x._id;
            }).indexOf(item._id)
            if(index < 0){
              this.credenciados.push(item)
            }
        }
        if(item.municipio.match(cid) && esp == 'LABORATORIO' && item.laboratorioCheck == true){
          let index = this.credenciados.map((x) => {
            return x._id;
          }).indexOf(item._id)
          if(index < 0){
            this.credenciados.push(item)
            }
        }
        if(item.municipio.match(cid) && item.profissionais.length > 0){
          item.profissionais.forEach((p) => {
            if(p?.especialidade?.match(esp)){
                let index = this.credenciados.map((x) => {
                return x._id;
                }).indexOf(item._id)
              if(index < 0){
                this.credenciados.push(item)
              }
            }
          })
        }
      })
    }
  }

  procurar(cidade,especialidade){
    this.credenciados = [];
    this.procurado = true;
    let cid = cidade == null ? "" : cidade.cidade;
    let esp = especialidade == null ? "" : especialidade.nome;
    if(this.onOff.isOnline){
      if(cid == "" && esp == ""){
        this.credenciados = this.todosCredenciados;
      }else{
        this.todosCredenciados.forEach((item) => {
          if(item.municipio.match(cid) && item.especialidade.match(esp)){
            this.credenciados.push(item)
          }
          if(item.municipio.match(cid) && esp == 'LABORATORIO' && item.laboratorioCheck == true){
            this.credenciados.push(item)
          }
          if(item.municipio.match(cid) && item.profissionais.length > 0){
            item.profissionais.forEach((p) => {
              if(p?.especialidade?.match(esp)){
                this.credenciados.push(item)
              }
            })
          }
        })
      }
    }else{
      if(cid == "" && esp == ""){
        this.credenciados = this.todosCredenciados[0];
      }else{
        this.todosCredenciados[0].forEach((item) => {
          if(item.municipio.match(cid) && item.especialidade.match(esp)){
            this.credenciados.push(item)
          }
          if(item.municipio.match(cid) && esp == 'LABORATORIO' && item.laboratorioCheck == true){
            this.credenciados.push(item)
          }
          if(item.municipio.match(cid) && item.profissionais.length > 0){
            item.profissionais.forEach((p) => {
              if(p?.especialidade?.match(esp)){
                this.credenciados.push(item)
              }
            })
          }
        })
      }
    }
  }

  backToDash(){
    this.router.navigate(['/inicio']);
  }

  openModalAcesso(){
    let modalAcesso = document.getElementById('modalAcesso');
    modalAcesso.style.display = "block";
  }

  closeModalAcesso(){
    let modalAcesso = document.getElementById('modalAcesso');
    modalAcesso.style.display = "none";
    this.emailAcesso = "";
    this.nomeAcesso = "";
    this.celularAcesso = "";
    this.lead = "";
  }

  cadastrarEmailLead(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.getEmailLeads(this.emailAcesso).subscribe((data:any) => {
      if(data && data.length > 0){
        this.lead = data[0];
      }else{
        let obj = {
          nome: this.nomeAcesso,
          email: this.emailAcesso,
          celular: this.celularAcesso,
          dataCadastro: this.dataHoje,
          data: this.dataHoje,
          logs:[{
            data:this.dataHoje,
            hora: hora,
            usuario : localStorage.getItem('usuarioVendas')
        }]
        }
        this.db.postEmailLead(obj).subscribe((res) => {
          console.log(res);
          alert('Cadastro Realizado com Sucesso!');
          this.closeModalAcesso();
        }, (err) => {
          console.log(err)
        })
      }
    })
  }

  renovarCadastro(){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let newLog = {
      data:this.dataHoje,
      hora: hora,
      usuario : localStorage.getItem('usuarioVendas')
    }
    this.lead.logs.push(newLog);
    let obj = {
      nome: this.lead.nome,
      email: this.lead.email,
      celular: this.lead.celular,
      dataCadastro: this.dataHoje,
      data: this.dataHoje,
      logs: this.lead.logs
    }
    this.db.patchEmailLead(this.lead._id,obj).subscribe((res) => {
      console.log(res);
      alert('Renovação Realizada com Sucesso!');
      this.closeModalAcesso();
    }, (err) => {
      console.log(err)
    })
  }

}
