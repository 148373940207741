export const environment = {
  production: false,
  firebase: {
    projectId: 'push-app-vivamax',
    appId: '1:280254278967:web:fa05461e7b2891f27b3eb2',
    storageBucket: 'push-app-vivamax.appspot.com',
    apiKey: 'AIzaSyDKiAFw81llEZMuPVUe27nqHekfJl5hI8s',
    authDomain: 'push-app-vivamax.firebaseapp.com',
    messagingSenderId: '280254278967',
    measurementId: 'G-PN5PKRFKYB',
    vapidKey: "BGL_u-BBfqCrGx-xb9jg6xbQ7xjoLjK3_7PmW5wTT04bLRz66gMReYaS4-tQocpscV8vaE5NRG_klhWu_0l8mz4"
  } 
};