import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MASKS } from 'ng-brazil';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { OnlineOfflineService } from 'src/app/online-offline.service';
import { DataBaseService } from 'src/app/provider.service';

@Component({
  selector: 'app-associados',
  templateUrl: './associados.component.html',
  styleUrls: ['./associados.component.scss']
})
export class AssociadosComponent implements OnInit {
  public MASKS = MASKS;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;
  associadosPendentes:any = [];
  associadosAtivos:any = [];
  nomeTitular:any = "";
  nomeBeneficiario:any = "";
  matriculaTitular:any = "";
  codigoEmpresa:any = "";
  tipoAssociado:any = "";
  empresaAssociado:any = "";
  todosDependentes:any = [];
  dependentesEditar:FormGroup;
  dependentesExtraEditar:FormGroup;
  public listaDeDependentesEditar:FormArray;
  public listaDeDependentesExtraEditar:FormArray;
  get dependentesEditarFormGroup(){return this.dependentesEditar.get('dependenteEditar') as FormArray;};
  get dependentesExtraEditarFormGroup(){return this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;};
  idAssociado:any = "";
  backup:any = [];
  matriculaEditar:any = "";
  planoFisicoCheckEditar:any = "";
  subContratoEditar:any = "";
  cpfEditar:any = "";
  sexoEditar:any = "";
  situacaoEditar:any = "";
  situacaoOriginal:any = "";
  observacaoPendenteEditar:any = "";
  estadoCivilEditar:any = "";
  nascimentoEditar:any = "";
  nomeEditar:any = "";
  telefoneEditar:any = "";
  celularEditar:any = "";
  ruaEditar:any = "";
  numeroEditar:any = "";
  complementoEditar:any = "";
  bairroEditar:any = "";
  municipioEditar:any = "";
  estadoEditar:any = "";
  emailEditar:any = "";
  localTrabalhoEditar:any = "";
  funcaoEditar:any = "";
  doencaEditar:any = "";
  especificarDoencaEditar:any = "";
  formaPagamentoEditar:any = "";
  mensalidadeEditar:any = "";
  taxaAdesaoEditar:any = "";
  diaVencimentoEditar:any = "";
  whatsappEditar:any = "";
  cepEditar:any = "";
  dataAdesaoEditar:any = "";
  totalMensalidadeEditar:number = 0;
  nomeCartaoEditar:any = "";
  planosFisicosEditar:any = [];
  planosServidoresEditar:any = [];
  planoEditar:any = "";
  numeroCartaoEditar:any = "";
  validadeCartaoEditar:any = "";
  codigoCartaoEditar:any = "***";
  cartaoSelecionadoEditar:any = "";
  bloqueioPagamentos:boolean = false;
  mesFolhaPagamentoEditar:any = "";
  dep:any = [];
  depExtras:any = [];
  mesSeguinte:any = "";
  mesFolhaPagamentoEditarPrint:any = "";
  docs:any = [];
  pdfs:any = [];
  images:any = [];
  funeralConjugeEditar:any = "";
  funeralFilhosMenorEditar:any = "";
  filhoMenorIdadeCheck:boolean = false;
  totalAsfDependentesEditar:number = 0;
  totalAsfDependentesExtrasEditar:number = 0;
  funeralExtraEditar:any = "";
  funeralFilhosMaiorEditar:any = "";
  editaAdesaoEditar:any = "";
  editaMensalidadeEditar:any = "";
  editaTotalEditar:any = "";
  valorCapitalSeguradoEditar:any = "";
  valorFuneralConjugeEditar:any = "";
  valorFuneralExtraEditar:any = "";
  valorFuneralFilhosMaiorEditar:any = "";
  valorFuneralFilhosMenorEditar:any = "";
  mesFolhaPagamento:any = "";
  dataHoje:any = "";
  matriculaCheckEditar:boolean = false;
  selectedFiles:any = [];
  files:any = [];
  imagemSelecionada:any = "";
  indexImage:number = 0;
  cpfCheckEditar:boolean = false;
  editaOn:boolean = false;
  matriculaIndicacaoPremiada:number = 0;
  nomeIndicacaoPremiada:any = "";
  mesIndicacaoPremiada:any = "";
  checkDadosBancarios:boolean = false;
  checkPix:boolean = false;
  checkVindi:boolean = false;
  checkConsignet:boolean = false;
  banco:any = "";
  agencia:number = 0;
  agenciaDigito:any = "";
  contaCorrente:number = 0;
  contaCorrenteDigito:any = "";
  cpfBanco:any = "";
  tipoPix:any = "";
  pix:any = "";
  empresa:any = "";
  indicacaoEnviada:boolean = false;

  constructor(public db:DataBaseService, private formBuilder: FormBuilder,public router:Router,public onOff: OnlineOfflineService){ }
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;
  selectTab(tabId: number){this.staticTabs.tabs[tabId].active = true;}

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.associadosPendentes = [];
    this.associadosAtivos = [];
    this.dependentesEditar = this.formBuilder.group({
      dependenteEditar: this.formBuilder.array([])
    });
    this.dependentesExtraEditar = this.formBuilder.group({
      dependenteExtraEditar: this.formBuilder.array([])
    });
    this.listaDeDependentesEditar = this.dependentesEditar.get('dependenteEditar') as FormArray;
    this.listaDeDependentesExtraEditar = this.dependentesExtraEditar.get('dependenteExtraEditar') as FormArray;
  }
    
  verificaData(data){
    let ano = data.split('-')[0];
    if(Number(ano) > 9999){
      alert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
    }
  }

  verificaIdade(e){
    if(e){
      var d = new Date;
      let dt = e.split('/').reverse().join('-');
      let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
      let ano_atual = d.getFullYear();
      let mes_atual = d.getMonth() + 1;
      let dia_atual = d.getDate();
      let ano_aniversario = data.getFullYear();;
      let mes_aniversario = data.getMonth() + 1;
      let dia_aniversario = data.getDate();
      let idade = ano_atual - ano_aniversario;
      if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
          idade--;
      }
      if(idade >= 18){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  
 backToDash(){
   this.router.navigate(['/inicio']);
 }
  
  buscarTodos(){
    this.associadosPendentes = [];
    this.db.getAssociadosPendentesByVendedor(localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.associadosPendentes.push(a);
          if(ctrl != data.length){
            ctrl++;
          }
        })
      }else{
        this.associadosPendentes = data;
      }
      this.db.getAssociadosByVendedor(localStorage.getItem('usuarioVendas')).subscribe((dt:any) => {
        this.associadosAtivos = dt;
      });
    }) 
   }

   buscarMatricula(matriculaTitular){
    this.associadosPendentes = [];
    this.db.getAssociadosMatriculaPendentesByVendedor(matriculaTitular,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.associadosPendentes.push(a);
          if(ctrl != data.length){
            ctrl++;
          }
        })
      }else{
        this.associadosPendentes = data;
      }
      this.db.getAssociadosMatriculaMesByVendedor(matriculaTitular,localStorage.getItem('usuarioVendas')).subscribe((dt:any) => {
        this.associadosAtivos = dt;
      });
    })
   }

   buscarTitular(nomeTitular){
    this.associadosPendentes = [];
    this.db.getAssociadosTitularPendentesByVendedor(nomeTitular,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.associadosPendentes.push(a);
          if(ctrl != data.length){
            ctrl++;
          }
        })
      }else{
        this.associadosPendentes = data;
      }
      this.db.getAssociadosTitularMesByVendedor(nomeTitular,localStorage.getItem('usuarioVendas')).subscribe((dt:any) => {
        this.associadosAtivos = dt;
      });
    })
   }

   buscarTipoAssociado(tipo){
    this.associadosPendentes = [];
    this.db.getAssociadosByTipoPendentesByVendedor(tipo,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.associadosPendentes.push(a);
          if(ctrl != data.length){
            ctrl++;
          }
        })
      }else{
        this.associadosPendentes = data;
      }
      this.db.getAssociadosByTipoByVendedor(tipo,localStorage.getItem('usuarioVendas')).subscribe((dt:any) => {
        this.associadosAtivos = dt;
      });
    })
   }

   buscarTipoEmpresa(empresa){
    this.associadosPendentes = [];
    this.db.getAssociadosByEmpresaPendentesByVendedor(empresa,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      let ctrl = 1;
      if(data && data.length > 0){
        data.forEach((a:any) => {
          let date1 = new Date(a.data);
          let date2 = new Date(this.dataHoje);
          let timeDiff = Math.abs(date2.getTime() - date1.getTime());
          let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          a.diasPendente = diffDays;
          this.associadosPendentes.push(a);
          if(ctrl != data.length){
            ctrl++;
          }
        })
      }else{
        this.associadosPendentes = data;
      }
      this.db.getAssociadosByEmpresaByVendedor(empresa,localStorage.getItem('usuarioVendas')).subscribe((dt:any) => {
        this.associadosAtivos = dt;
      });
    })
   }

   buscarBeneficiario(nomeBeneficiario){
    this.associadosPendentes = [];
    this.associadosAtivos = [];
    let ctrlDep = 1;
    let ctrlDepExtra = 1;
    let ctrlDepAtivo = 1;
    let ctrlExtraAtivo = 1;
    this.db.getAssociadosBeneficiarioPendenteByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
      if(data.length > 0){
          data.forEach((item:any) => {
            let idx = this.associadosPendentes.map(function(w){
              return w.nome
            }).indexOf(item.nome);
           if(idx < 0){
            this.associadosPendentes.push(item)
           } 
          if(data.length == ctrlDep){
            this.db.getAssociadosBeneficiarioPendenteExtraByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((dataExtra:any) => {
              if(dataExtra.length > 0){
                dataExtra.forEach(function(itemExtra){   
                  let idxE = this.associadosPendentes.map(function(w){
                    return w.nome
                  }).indexOf(itemExtra.nome);
                 if(idxE < 0){
                  this.associadosPendentes.push(itemExtra)
                 } 
                 if(dataExtra.length == ctrlDepExtra){ 
                  this.associadosPendentes.sort(function(a,b){
                    if(a.nome < b.nome){
                      return -1;
                    }else{
                      return 1;
                    }
                  });
                }else{
                  ctrlDepExtra++
                }
              });
            }else{
              this.associadosPendentes.sort(function(a,b){
                if(a.nome < b.nome){
                  return -1;
                }else{
                  return 1;
                }
              });
            }
          })
          }else{
            ctrlDep++
          }
        });
      }else{
         this.db.getAssociadosBeneficiarioPendenteExtraByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((dataExtra:any) => {
          if(dataExtra.length > 0){
            dataExtra.forEach((item:any) => {
              let idx = this.associadosPendentes.map(function(w){
                return w.nome
              }).indexOf(item.nome);
             if(idx < 0){
              this.associadosPendentes.push(item)
             } 
            if(dataExtra.length == ctrlDepExtra){
              this.associadosPendentes.sort(function(a,b){
                if(a.nome < b.nome){
                  return -1;
                }else{
                  return 1;
                }
              });
            }else{
              ctrlDepExtra++
            }
          });
        }
      })
    }
  })
  this.db.getAssociadosBeneficiarioByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((data:any) => {
    if(data.length > 0){
        data.forEach((item:any) => {
          let idx = this.associadosAtivos.map(function(w){
            return w.nome
          }).indexOf(item.nome);
         if(idx < 0){
          this.associadosAtivos.push(item)
         } 
        if(data.length == ctrlDepAtivo){
          this.db.getAssociadosBeneficiarioExtraByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((dataExtra:any) => {
            if(dataExtra.length > 0){
              dataExtra.forEach(function(itemExtra){   
                let idxE = this.associadosAtivos.map(function(w){
                  return w.nome
                }).indexOf(itemExtra.nome);
               if(idxE < 0){
                this.associadosAtivos.push(itemExtra)
               } 
               if(dataExtra.length == ctrlExtraAtivo){ 
                this.associadosAtivos.sort(function(a,b){
                  if(a.nome < b.nome){
                    return -1;
                  }else{
                    return 1;
                  }
                });
              }else{
                ctrlExtraAtivo++
              }
            });
          }else{
            this.associadosAtivos.sort(function(a,b){
              if(a.nome < b.nome){
                return -1;
              }else{
                return 1;
              }
            });
          }
        })
        }else{
          ctrlDepAtivo++
        }
      });
    }else{
       this.db.getAssociadosBeneficiarioExtraByVendedor(nomeBeneficiario,localStorage.getItem('usuarioVendas')).subscribe((dataExtra:any) => {
        if(dataExtra.length > 0){
          dataExtra.forEach((item:any) => {
            let idx = this.associadosAtivos.map(function(w){
              return w.nome
            }).indexOf(item.nome);
           if(idx < 0){
            this.associadosAtivos.push(item)
           } 
          if(dataExtra.length == ctrlExtraAtivo){
            this.associadosAtivos.sort(function(a,b){
              if(a.nome < b.nome){
                return -1;
              }else{
                return 1;
              }
            });
          }else{
            ctrlExtraAtivo++
          }
        });
      }
    })
  }
})
}

  openModalEditar(proposta:any,origem:any,tipo:any){
    let ctrl = 1;
    if(tipo == 'ver'){
      this.editaOn = false;
    }
    if(tipo == 'editar'){
      this.editaOn = true;
    }
    let dFi = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dFi.checked = false;
    let dRi = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
    dRi.checked = false;
    let bMi = <HTMLInputElement>document.getElementById('boletoMensalEditar');
      bMi.checked = false;
    let dCi = <HTMLInputElement>document.getElementById('carneEditar');
      dCi.checked = false;
    this.todosDependentes = [];
    this.idAssociado = proposta._id;
    this.listaDeDependentesEditar.clear();
    this.listaDeDependentesExtraEditar.clear();
    this.empresa = proposta.empresa;
    this.backup = proposta;
    this.matriculaEditar = proposta.matricula;
    this.planoFisicoCheckEditar = proposta.planoFisicoCheck;
    this.codigoEmpresa = proposta.codigoEmpresa;
    this.subContratoEditar = proposta.subContrato;
    this.cpfEditar = proposta.cpf;
    this.sexoEditar = proposta.sexo;
    this.situacaoEditar = proposta.situacao;
    this.situacaoOriginal = proposta.situacao;
    this.observacaoPendenteEditar = proposta.observacaoPendente;
    this.estadoCivilEditar = proposta.estadoCivil;
    this.nascimentoEditar = proposta.nascimento;
    this.nomeEditar = proposta.nome;
    this.telefoneEditar = proposta.telefone;
    this.celularEditar = proposta.celular;
    this.ruaEditar = proposta.rua;
    this.numeroEditar = proposta.numero;
    this.complementoEditar = proposta.complemento;
    this.bairroEditar = proposta.bairro;
    this.municipioEditar = proposta.municipio;
    this.estadoEditar = proposta.estado;
    this.emailEditar = proposta.email;
    this.localTrabalhoEditar = proposta.localTrabalho;
    this.funcaoEditar = proposta.funcao;
    this.doencaEditar = proposta.doenca;
    this.especificarDoencaEditar = proposta.especificarDoenca;
    this.formaPagamentoEditar = proposta.formaPagamento;
    this.mensalidadeEditar = proposta.mensalidade;
    this.taxaAdesaoEditar = proposta.taxaAdesao;
    this.diaVencimentoEditar = proposta.diaVencimento;
    this.whatsappEditar = proposta.whatsapp;
    this.cepEditar = proposta.cep;
    this.dataAdesaoEditar = proposta.dataAdesao;
    this.totalMensalidadeEditar = proposta.totalMensalidade;
    this.nomeCartaoEditar = proposta.nomeCartao;  
    this.planosFisicosEditar = [];
    this.planosServidoresEditar = [];
    this.planoEditar = proposta.plano;
    this.matriculaIndicacaoPremiada = proposta.matriculaIndicacaoPremiada;
    this.indicacaoEnviada = proposta.semDadosIndicacao;
    this.nomeIndicacaoPremiada = proposta.nomeIndicacaoPremiada;
    this.mesIndicacaoPremiada = proposta.mesIndicacaoPremiada;
    this.checkDadosBancarios = proposta.checkDadosBancarios;
    this.checkPix = proposta.checkPix;
    this.checkVindi = proposta.checkVindi;
    this.checkConsignet = proposta.checkConsignet;
    this.banco = proposta.banco;
    this.agencia = proposta.agencia;
    this.agenciaDigito = proposta.agenciaDigito;
    this.contaCorrente = proposta.contaCorrente;
    this.contaCorrenteDigito = proposta.contaCorrenteDigito;
    this.cpfBanco = proposta.cpfBanco;
    this.tipoPix = proposta.tipoPix;
    this.pix = proposta.pix;
    this.db.getPlanos().subscribe((p:any) => {
      p.forEach((item:any) => {
       if(item.tipo == 'PLANO FISICO'){
         this.planosFisicosEditar.push(item);
       }
       if(item.tipo == 'PLANO SERVIDOR'){
         this.planosServidoresEditar.push(item);
       }
       if(ctrl == p.length){
        if(this.planoFisicoCheckEditar == true){
          this.selectPlanoEditar('fisico',this.planoEditar,'start');
        }else{
          this.selectPlanoEditar('servidor',this.planoEditar,'start');
        }
       }else{
         ctrl++
       }
      })
    });
    this.numeroCartaoEditar = proposta.numeroCartao;
    this.validadeCartaoEditar = proposta.validadeCartao;
    this.cartaoSelecionadoEditar = proposta.cartaoSelecionado;
    this.codigoCartaoEditar = proposta.codigoCartao ?  "***" : ""

    if(this.doencaEditar != ''){
      let c:any;
      if(this.doencaEditar == "SIM"){
        c = <HTMLInputElement>document.getElementById('doencaSimEditar');
        c.checked = true
      }
      if(this.doencaEditar == "NÃO"){
          c = <HTMLInputElement>document.getElementById('doencaNaoEditar');
          c.checked = true
      }
    }
    switch(this.formaPagamentoEditar){
      case 'DESCONTO EM FOLHA DE PAGAMENTO' : {
        let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
        dF.checked = true;
        this.bloqueioPagamentos = true;
        break;
      };
      case 'DEBITO RECORRENTE' : {
        let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
          dR.checked = true;
        break;
      };
      case 'BOLETO' : {
        let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
        bM.checked = true;
        break;
      };
      case 'CARNÊ' : {
        let dC = <HTMLInputElement>document.getElementById('carneEditar');
        dC.checked = true;
        break;
      };
      default:break;
    }     
    this.mesFolhaPagamentoEditar = proposta.mesFolhaPagamento;          
    /// GET DEPENDENTES
    if(proposta.dependentes && proposta.dependentes.length > 0){
      this.dep = proposta.dependentes;
      proposta.dependentes.forEach((item:any) => {
        if(item.sinistro){
          if(item.sinistro.numeroSinistro){
            item.sinistro.numeroSinistro = item.sinistro.numeroSinistro;
          }
          if(item.sinistro.valorSinistro){
            item.sinistro.valorSinistro = item.sinistro.valorSinistro;
          }
        }
      this.addDependenteEditar(item)
      })
    }
    if(proposta.dependentesExtra && proposta.dependentesExtra.length > 0){
    this.depExtras = proposta.dependentesExtra;
      proposta.dependentesExtra.forEach((item:any) => {
        if(item.sinistro){
          if(item.sinistro.numeroSinistro){
            item.sinistro.numeroSinistro = item.sinistro.numeroSinistro;
          }
          if(item.sinistro.valorSinistro){
            item.sinistro.valorSinistro = item.sinistro.valorSinistro;
          }
        }
      this.addDependenteExtraEditar(item)
      })
    }
    let qtdeDependentes = this.listaDeDependentesEditar.value;
    qtdeDependentes.forEach((item:any) => {
      if(item.nome != null){
        this.todosDependentes.push(item)
      }
    })
    let qtdeDependentesExtras = this.listaDeDependentesExtraEditar.value;
    qtdeDependentesExtras.forEach((item:any) => {
      if(item.nome != null){
        this.todosDependentes.push(item)
      }
    });
    if(origem == "save"){
      this.selectAsfEditar();
      this.selectAsfExtrasEditar();
      this.salvar('totalMensalidade',this.totalMensalidadeEditar);
    }else{
       let modal = document.getElementById('modalEditar');
       modal.style.display = "block"
       modal.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
    this.selectTab(0);
    this.docs = [];
    this.docs = proposta.documentos;
    if(this.docs && this.docs.length > 0){
      this.docs.forEach((item:any) => {
       let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
    
  let dataVl = new Date(this.dataAdesaoEditar);
  let mesVl = dataVl.getMonth();
  switch(mesVl){
    case 11: this.mesSeguinte = "JANEIRO";break;
    case 0: this.mesSeguinte = "FEVEREIRO";break;
    case 1: this.mesSeguinte = "MARÇO";break;
    case 2: this.mesSeguinte = "ABRIL";break;
    case 3: this.mesSeguinte = "MAIO";break;
    case 4: this.mesSeguinte = "JUNHO";break;
    case 5: this.mesSeguinte = "JULHO";break;
    case 6: this.mesSeguinte = "AGOSTO";break;
    case 7: this.mesSeguinte = "SETEMBRO";break;
    case 8: this.mesSeguinte = "OUTUBRO";break;
    case 9: this.mesSeguinte = "NOVEMBRO";break;
    case 10: this.mesSeguinte = "DEZEMBRO";break;
  }
  let mesFolha = this.mesFolhaPagamentoEditar.split('-')[1];
  switch(mesFolha){
    case '01': this.mesFolhaPagamentoEditarPrint = "JANEIRO";break;
    case '02': this.mesFolhaPagamentoEditarPrint = "FEVEREIRO";break;
    case '03': this.mesFolhaPagamentoEditarPrint = "MARÇO";break;
    case '04': this.mesFolhaPagamentoEditarPrint = "ABRIL";break; 
    case '05': this.mesFolhaPagamentoEditarPrint = "MAIO";break;
    case '06': this.mesFolhaPagamentoEditarPrint = "JUNHO";break;
    case '07': this.mesFolhaPagamentoEditarPrint = "JULHO";break;
    case '08': this.mesFolhaPagamentoEditarPrint = "AGOSTO";break;
    case '09': this.mesFolhaPagamentoEditarPrint = "SETEMBRO";break;
    case '10': this.mesFolhaPagamentoEditarPrint = "OUTUBRO";break;
    case '11': this.mesFolhaPagamentoEditarPrint = "NOVEMBRO";break;
    case '12': this.mesFolhaPagamentoEditarPrint = "DEZEMBRO";break;
  }
}

  closeModalEditar(){
    this.backup = [];
    this.empresa = "";
    this.indicacaoEnviada = false;
    this.associadosPendentes = [];
    this.associadosAtivos = [];
    this.planoEditar = "";
    this.observacaoPendenteEditar = "";
    this.idAssociado = "";
    this.subContratoEditar = "";
    this.planoFisicoCheckEditar = "";
    this.cpfEditar = "";
    this.estadoCivilEditar = "";
    this.nascimentoEditar = "";
    this.telefoneEditar = "";
    this.celularEditar = "";
    this.ruaEditar = "";
    this.numeroEditar = "";
    this.complementoEditar = "";
    this.bairroEditar = "";
    this.sexoEditar = "";
    this.municipioEditar = "";
    this.estadoEditar = "";
    this.emailEditar = "";
    this.localTrabalhoEditar = "";
    this.funcaoEditar = "";
    this.doencaEditar = "";
    this.especificarDoencaEditar = "";
    this.mensalidadeEditar = 0;
    this.taxaAdesaoEditar = 0;
    this.diaVencimentoEditar = 0;
    this.mesFolhaPagamentoEditar = "";
    this.whatsappEditar = "";
    this.cepEditar = "";
    this.dataAdesaoEditar = "";
    this.planoEditar = "";
    this.totalMensalidadeEditar = 0;
    this.nomeCartaoEditar = "";
    this.numeroCartaoEditar = "";
    this.validadeCartaoEditar = "";
    this.codigoCartaoEditar = "";
    this.formaPagamentoEditar = "";
    this.cartaoSelecionadoEditar = "";
    this.listaDeDependentesEditar.clear();
    this.listaDeDependentesExtraEditar.clear();
    this.bloqueioPagamentos = false;
    this.pdfs = [];
    this.images = [];
    this.docs = [];
    this.ngOnInit();
    let modal = document.getElementById('modalEditar');
    modal.style.display = "none"
  }

  tipoDependenteEditar(i){
    let tipoInput = (<HTMLInputElement>document.getElementById('tipoEditar'+i)).value;
    let asfInput = (<HTMLInputElement>document.getElementById('asfEditar'+i));
    if(tipoInput == "CÔNJUGE" && this.funeralConjugeEditar == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
      this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO')
    }else{
      this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('')
      asfInput.value = "";
    }
    if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.funeralFilhosMenorEditar == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
      this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
    }
  }
  
verificaNascimentoEditar(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoEditar'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfEditar'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimentoEditar'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(!this.verificaIdade(nascimentoInput.value)){
       this.filhoMenorIdadeCheck = true;
       if(this.funeralFilhosMenorEditar == "AUTOMATICO"){
         asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
       }
   }else{
    this.filhoMenorIdadeCheck = false;
    asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentesEditar.get('dependenteEditar.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO')
   }      
 }
}

selectAsfEditar(){
  this.totalAsfDependentesEditar = 0;
  this.totalMensalidadeEditar = 0;
  let control = 1;
  if(this.dependentesEditar.value.dependenteEditar.length > 0){
      this.dependentesEditar.value.dependenteEditar.forEach((item:any) => {
      if(item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
        this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
      }
      if(control == this.dependentesEditar.value.dependenteEditar.length){
        this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
      }else{
        control++
      }
    })
  }else{
    this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
  }
}

 selectAsfExtrasEditar(){
  this.totalAsfDependentesExtrasEditar = 0;
  this.totalMensalidadeEditar = 0;
  let control = 1;
  if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
    this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((item:any) => {
      if(this.funeralExtraEditar == "SIM" && item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
        this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
      }
      if(control == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
        this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
      }else{
        control++
      }
    })
  }else{
    this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
  }
}

buscarCepEditar(cepEdit){
  let cEdit = cepEdit.replace(/_/gi,'').replace('.','').replace('-','');
  if(cEdit.length > 7){
    this.db.getCep(cEdit).subscribe((data:any) => {
      let cepEdit = data;
       if(cepEdit.erro == true){
         alert("CEP NAO ENCONTRADO");
       }else{
         this.ruaEditar = cepEdit.logradouro.toUpperCase();
         this.bairroEditar = cepEdit.bairro.toUpperCase();
         this.municipioEditar = cepEdit.localidade.toUpperCase();
         this.estadoEditar = cepEdit.uf.toUpperCase();
         if(this.ruaEditar != this.backup.rua){
          this.salvar('rua',this.ruaEditar)
         }
         if(this.bairroEditar != this.backup.bairro){
          this.salvar('bairro',this.bairroEditar)
        }
        if(this.municipioEditar != this.backup.municipio){
          this.salvar('municipio',this.municipioEditar)
        }
        if(this.estadoEditar != this.backup.estado){
          this.salvar('estado',this.estadoEditar)
        }
       }
    });
  }
}

escolhePlanoFisicoEditar(planoFisico){
  if(planoFisico == true){
    this.subContratoEditar = "";
    this.mensalidadeEditar = 0;
    this.planoEditar = "";
    this.diaVencimentoEditar = 0;
    this.formaPagamentoEditar = "";
    this.mesFolhaPagamentoEditar = "";
    this.cartaoSelecionadoEditar = "";
    this.nomeCartaoEditar = "";
    this.validadeCartaoEditar = "";
    this.numeroCartaoEditar = "";
    this.codigoCartaoEditar = "";
    this.bloqueioPagamentos = false;
    let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carneEditar');
    dC.checked = false;
  }
}

escolheSubContratoEditar(subContrato){
  if(subContrato != ""){
    this.planoFisicoCheckEditar = false;
    this.mensalidadeEditar = 0;
    this.planoEditar = "";
    this.diaVencimentoEditar = 0;
    this.formaPagamentoEditar = "";
    this.mesFolhaPagamentoEditar = "";
    this.cartaoSelecionadoEditar = "";
    this.nomeCartaoEditar = "";
    this.validadeCartaoEditar = "";
    this.numeroCartaoEditar = "";
    this.codigoCartaoEditar = "";
    let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dF.checked = true;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
      dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carneEditar');
    dC.checked = false;
  }
}

selectPlanoEditar(tipo,plano,origem){
  let i;
  if(tipo == 'fisico'){
    if(plano == ""){
      this.formaPagamentoEditar = "";
      this.mensalidadeEditar = 0;
      this.totalMensalidadeEditar = 0;
    }else{
      i = this.planosFisicosEditar.map(function(item){
        return item.nome
      }).indexOf(plano);
      if(i >= 0){
      this.funeralConjugeEditar = this.planosFisicosEditar[i]?.funeralConjuge;
      this.funeralExtraEditar = this.planosFisicosEditar[i]?.funeralExtra;
      this.funeralFilhosMaiorEditar = this.planosFisicosEditar[i]?.funeralFilhosMaior;
      this.funeralFilhosMenorEditar = this.planosFisicosEditar[i]?.funeralFilhosMenor;
      this.editaAdesaoEditar = this.planosFisicosEditar[i]?.editaAdesao;
      this.editaMensalidadeEditar = this.planosFisicosEditar[i]?.editaMensalidade;
      this.editaTotalEditar = this.planosFisicosEditar[i]?.editaTotal;
      this.valorCapitalSeguradoEditar = this.planosFisicosEditar[i]?.valorCapitalSegurado;
      this.valorFuneralConjugeEditar = this.planosFisicosEditar[i]?.valorFuneralConjuge;
      this.valorFuneralExtraEditar = this.planosFisicosEditar[i]?.valorFuneralExtra;
      this.valorFuneralFilhosMaiorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMaior;
      this.valorFuneralFilhosMenorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMenor;
      if(this.planosFisicosEditar[i]?.valorCapitalSegurado == null){
        this.valorCapitalSeguradoEditar = 0;
      }else{
        this.valorCapitalSeguradoEditar = this.planosFisicosEditar[i]?.valorCapitalSegurado;
      }
      if(this.planosFisicosEditar[i]?.valorFuneralConjuge == null){
        this.valorFuneralConjugeEditar = 0;
      }else{
        this.valorFuneralConjugeEditar = this.planosFisicosEditar[i]?.valorFuneralConjuge;
      }
      if(this.planosFisicosEditar[i]?.valorFuneralExtra == null){
        this.valorFuneralExtraEditar = 0;
      }else{
        this.valorFuneralExtraEditar = this.planosFisicosEditar[i]?.valorFuneralExtra;
      }
      if(this.planosFisicosEditar[i]?.valorFuneralFilhosMaior == null){
        this.valorFuneralFilhosMaiorEditar = 0;
      }else{
        this.valorFuneralFilhosMaiorEditar = this.planosFisicosEditar[i]?.valorFuneralFilhosMaior;
      }
      if(this.planosFisicosEditar[i]?.valorFuneralFilhosMenor == null){
        this.valorFuneralFilhosMenorEditar = 0;
      }else{
        this.valorFuneralFilhosMenorEditar =  this.planosFisicosEditar[i]?.valorFuneralFilhosMenor;
      }
      if(origem != 'start'){
        this.mensalidadeEditar = this.planosFisicosEditar[i]?.mensalidade;
        this.selectAsfEditar();
        this.selectAsfExtrasEditar();
      }
      }
    }
  }
  if(tipo == 'servidor'){
    if(plano == ""){
      this.formaPagamentoEditar = "";
      this.mesFolhaPagamentoEditar = null;
      this.bloqueioPagamentos = false;
      let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
      dF.checked = false
    }else{
      if(this.diaVencimentoEditar == 0 || this.diaVencimentoEditar == null){
        this.diaVencimentoEditar = 15;
      }
      i = this.planosServidoresEditar.map(function(item){
        return item.nome
      }).indexOf(plano);
      if(i >= 0){
        this.funeralConjugeEditar = this.planosServidoresEditar[i]?.funeralConjuge;
        this.funeralExtraEditar = this.planosServidoresEditar[i]?.funeralExtra;
        this.funeralFilhosMaiorEditar = this.planosServidoresEditar[i]?.funeralFilhosMaior;
        this.funeralFilhosMenorEditar = this.planosServidoresEditar[i]?.funeralFilhosMenor;
        this.editaAdesaoEditar = this.planosServidoresEditar[i]?.editaAdesao;
        this.editaMensalidadeEditar = this.planosServidoresEditar[i]?.editaMensalidade;
        this.editaTotalEditar = this.planosServidoresEditar[i]?.editaTotal;
        if(this.planosServidoresEditar[i]?.valorCapitalSegurado == null){
          this.valorCapitalSeguradoEditar = 0;
        }else{
          this.valorCapitalSeguradoEditar = this.planosServidoresEditar[i]?.valorCapitalSegurado;
        }
        if(this.planosServidoresEditar[i]?.valorFuneralConjuge == null){
          this.valorFuneralConjugeEditar = 0;
        }else{
          this.valorFuneralConjugeEditar = this.planosServidoresEditar[i]?.valorFuneralConjuge;
        }
        if(this.planosServidoresEditar[i]?.valorFuneralExtra == null){
          this.valorFuneralExtraEditar = 0;
        }else{ 
          this.valorFuneralExtraEditar = this.planosServidoresEditar[i]?.valorFuneralExtra;
        }
        if(this.planosServidoresEditar[i]?.valorFuneralFilhosMaior == null){
          this.valorFuneralFilhosMaiorEditar = 0;
        }else{
          this.valorFuneralFilhosMaiorEditar = this.planosServidoresEditar[i]?.valorFuneralFilhosMaior;
        }
        if(this.planosServidoresEditar[i]?.valorFuneralFilhosMenor == null){
          this.valorFuneralFilhosMenorEditar = 0;
        }else{
          this.valorFuneralFilhosMenorEditar =  this.planosServidoresEditar[i]?.valorFuneralFilhosMenor;
        }
        if(origem != 'start'){
          this.mensalidadeEditar = this.planosServidoresEditar[i]?.mensalidade;
          this.formaPagamentoEditar = "DESCONTO EM FOLHA DE PAGAMENTO";
          let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
          dF.checked = true;
          this.bloqueioPagamentos = true;
          this.selectAsfEditar();
          this.selectAsfExtrasEditar();
        }
      }
    }
  }
}

cartaoSelectEditar(e,index){
  let checked = e.srcElement.checked;
  if(this.cartaoSelecionadoEditar != ""){
   let checks = document.getElementsByName('cards');
    for(let i = 0; i < checks.length; i++){
      let c = checks[i] as HTMLInputElement;
      c.checked= false
      if(i+1 == checks.length){
        if(checked){
          let cb = checks[index] as HTMLInputElement;
          cb.checked= true
          this.cartaoSelecionadoEditar = e.srcElement.labels[0].innerText;
        }else{
          this.cartaoSelecionadoEditar = "";
        }
      }
    }
  }else{
    this.cartaoSelecionadoEditar = e.srcElement.labels[0].innerText;
  }
}

selectDoencaEditar(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c;
  if(inner == "SIM" && checked == true){
      this.doencaEditar = inner;
      c = <HTMLInputElement>document.getElementById('doencaNaoEditar');
      c.checked = false
      this.salvar('doenca','SIM');
  }
  if(inner == "NÃO" && checked == true){
      this.doencaEditar = inner;
      c = <HTMLInputElement>document.getElementById('doencaSimEditar');
      c.checked = false
      this.especificarDoencaEditar = "";
      this.salvar('doenca','NÃO')
      this.salvar('especificarDoenca','')
  }
}

selectFormaPagamentoEditar(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  if(inner == 'DESCONTO EM FOLHA DE PAGAMENTO'){
    this.formaPagamentoEditar = inner;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
    dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carneEditar');
    dC.checked = false;
    this.mesFolhaPagamentoEditar = this.mesFolhaPagamento;
  }
  if(inner == 'DEBITO RECORRENTE'){
    this.formaPagamentoEditar = inner;
    let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dF.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
    bM.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carneEditar');
    dC.checked = false;
    this.mesFolhaPagamentoEditar = "";
  }
  if(inner == 'BOLETO'){
    let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
    dR.checked = false;
    let dC = <HTMLInputElement>document.getElementById('carneEditar');
    dC.checked = false;
    this.mesFolhaPagamentoEditar = "";
  }
  if(inner == 'CARNÊ'){
    let dF = <HTMLInputElement>document.getElementById('descontoFolhaEditar');
    dF.checked = false;
    let dR = <HTMLInputElement>document.getElementById('debitoRecorrenteEditar');
    dR.checked = false;
    let bM = <HTMLInputElement>document.getElementById('boletoMensalEditar');
    bM.checked = false;
    this.mesFolhaPagamentoEditar = "";
  }
  if(checked){
    this.formaPagamentoEditar = inner;
  }else{
    this.formaPagamentoEditar = "";
  }
}

salvar(campo,valor){
  if(!this.onOff.isOnline){
    alert("Você está Offline! Edição Não Permitida!")
    }else{
      let dados = {
        [campo] : valor
    }
    let valorAntigo = this.backup[campo];
    if(typeof valorAntigo === 'object'){
      valorAntigo = this.backup[campo];
    }
    this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      if(valorAntigo == true){
        valorAntigo = 'MARCADO'
      }
      if(valorAntigo == false){
        valorAntigo = 'DESMARCADO'
      }
      if(valor == true){
        valor = 'MARCADO'
      }
      if(valor == false){
        valor = 'DESMARCADO'
      }
      if(campo == "doenca" && valor == false){
        let bd = {
          especificarDoenca : null
        }
        this.db.patchAssociado(this.idAssociado,bd).subscribe(res => {
          console.log(res);
          }, err => {
            console.log(err);
          })
        }
      let body = {
        "menu" : "EDITAR ASSOCIADOS", 
        "descricao" : "MATRICULA: "+this.matriculaEditar+" - ASSOCIADO: "+this.nomeEditar+" - Campo "+campo+" alterado de: "+valorAntigo+" para: "+valor, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : localStorage.getItem('usuarioVendas')
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        this.backup[campo] = valor;
        if(campo == 'observacaoPendente'){
          let dados = {
            "data" : this.dataHoje, 
            "hora" : hora, 
            "motivo" : "CADASTRO PENDENTE", 
            "msg" : this.observacaoPendenteEditar, 
            "usuario" : localStorage.getItem('usuarioVendas')
          }
          this.db.newHistorico(this.idAssociado,dados).subscribe(res => console.log(res),err => console.log(err))
        }
      }, error => {
        console.log(error)
      }) 
    }, err => {
      console.log(err)
    })
  }
}

editarPlano(){
  let dados = {
    planoFisicoCheck : this.planoFisicoCheckEditar,
    subContrato :  this.subContratoEditar,
    plano : this.planoEditar,
    diaVencimento : this.diaVencimentoEditar,
    formaPagamento : this.formaPagamentoEditar,
    mesFolhaPagamento : this.mesFolhaPagamentoEditar,
    cartaoSelecionado : this.cartaoSelecionadoEditar,
    nomeCartao : this.nomeCartaoEditar,
    validadeCartao : this.validadeCartaoEditar,
    numeroCartao : this.numeroCartaoEditar,
    valorCapitalSegurado: this.valorCapitalSeguradoEditar,
    mensalidade:this.mensalidadeEditar,
    totalMensalidade: this.totalMensalidadeEditar,
    taxaAdesao: this.taxaAdesaoEditar
  };
  this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
    console.log(res);
    if(this.backup.planoFisicoCheck != this.planoFisicoCheckEditar){
        this.salvar('planoFisicoCheck',this.planoFisicoCheckEditar);
      }
    if(this.backup.subContrato != this.subContratoEditar){
        this.salvar('subContrato',this.subContratoEditar);
     }
    if(this.backup.plano != this.planoEditar){
      this.salvar('plano',this.planoEditar);
    }
    if(this.backup.formaPagamento != this.formaPagamentoEditar){
      this.salvar('formaPagamento',this.formaPagamentoEditar);
    }
    if(this.backup.mesFolhaPagamento != this.mesFolhaPagamentoEditar){
      this.salvar('mesFolhaPagamento',this.mesFolhaPagamentoEditar)
    }
    if(this.backup.cartaoSelecionado != this.cartaoSelecionadoEditar){
      this.salvar('cartaoSelecionado',this.cartaoSelecionadoEditar);
    }
    if(this.backup.nomeCartao != this.nomeCartaoEditar){
      this.salvar('nomeCartao',this.nomeCartaoEditar);
    }
    if(this.backup.validadeCartao != this.validadeCartaoEditar){
      this.salvar('validadeCartao',this.validadeCartaoEditar);
    }
    if(this.backup.valorCapitalSegurado != this.valorCapitalSeguradoEditar){
      this.salvar('valorCapitalSegurado',this.valorCapitalSeguradoEditar);
    }
    if(this.backup.mensalidade != this.mensalidadeEditar){
      this.salvar('mensalidade',this.mensalidadeEditar);
    }
    if(this.backup.totalMensalidade != this.totalMensalidadeEditar){
      this.salvar('totalMensalidade',this.totalMensalidadeEditar);
    }
    if(this.backup.numeroCartao){
      if(this.backup.numeroCartao != this.numeroCartaoEditar){
              this.salvar('numeroCartao',this.numeroCartaoEditar);
            }
    }
    if(this.backup.codigoCartao){
        if(this.backup.codigoCartao != this.codigoCartaoEditar){
           this.salvar('codigoCartao',this.codigoCartaoEditar);
       }
    }
    if(this.backup.diaVencimento){
      if(this.backup.diaVencimento != this.diaVencimentoEditar){
         this.salvar('diaVencimento',this.diaVencimentoEditar);
      }
    }  
    alert("Plano Atualizado com Sucesso!")
  }, err => {
    console.log(err);
  })
}

verificaEnter(input,tipo,e){
  if(e.keyCode === 13){
      if(tipo == 'titular'){
       this.buscarTitular(input)
      }
      if(tipo == 'beneficiario'){
        this.buscarBeneficiario(input)
      }
      if(tipo == 'tipo'){
        this.buscarTipoAssociado(input)
      }
      if(tipo == 'empresa'){
        this.buscarTipoEmpresa(input)
      }
   }
}

replaceStrings(string:any){
  if(!string){return}
  return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26');
}

reverseReplaceStrings(string:any){
  if(!string){return}
  return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&');
}

salvarDependentes(){
  this.totalAsfDependentesEditar = 0;
  this.totalMensalidadeEditar = 0;
  let control = 1;
  if(this.dependentesEditar.value.dependenteEditar.length > 0){
      this.dependentesEditar.value.dependenteEditar.forEach((item:any) => {
      if(item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
        this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
      }
      if(control == this.dependentesEditar.value.dependenteEditar.length){
        let controlExtra = 1;
        if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
          this.dependentesExtraEditar.value.dependenteExtraEditar.forEach(function(itemExtra){
            if(this.funeralExtraEditar == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO"){
              this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
            }
            if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
              this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
              this.patchDependentes();
            }else{
              controlExtra++
            }
          })
        }else{
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
          this.patchDependentes();
        }
      }else{
        control++
      }
    })
  }else{
    let controlExtra = 1;
    if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
      this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((item:any) => {
        if(this.funeralExtraEditar == "SIM" && item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
          this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
        }
        if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
          this.patchDependentes();
        }else{
          controlExtra++
        }
      })
    }else{
      this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
      this.patchDependentes();
    }
  }
}

patchDependentes(){
  let dados = { 
    dependentes:this.dependentesEditar.get('dependenteEditar').value,
    dependentesExtra:this.dependentesExtraEditar.get('dependenteExtraEditar').value,
    mensalidade:this.mensalidadeEditar,
    totalMensalidade:this.totalMensalidadeEditar
  }
  this.db.patchAssociado(this.idAssociado,dados).subscribe(res => {
    console.log(res);
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      "menu" : "EDITAR ASSOCIADOS", 
      "descricao" : "Dependentes Alterados do Cliente: "+this.nomeEditar, 
      "data" : this.dataHoje, 
      "hora" : hora, 
      "usuario" : localStorage.getItem('usuarioVendas')
    }
    this.db.postLogs(body).subscribe(result => {
      console.log(result)
      this.db.getAssociadosById(this.idAssociado).subscribe((data:any) => {
        let indice = this.associadosPendentes.map(function(item){
          return item._id
        }).indexOf(data._id)
        this.associadosPendentes[indice] = data;
        this.backup = data;
        this.listaDeDependentesEditar.clear();
        this.listaDeDependentesExtraEditar.clear();
        this.openModalEditar(data, "save",'editar')
        alert("Dependentes Atualizado com Sucesso!");
      })
    }, error => {
      console.log(error)
    }) 
  }, err => {
    console.log(err)
  })
}

createDependenteEditar(item):FormGroup{
  return this.formBuilder.group({
    nome: item.nome,
    nascimento:item.nascimento,
    cpf:item.cpf,
    tipo:item.tipo,
    asf:item.asf,
    sinistro:item.sinistro,
    situacao:item.situacao
  });
}

addDependenteEditar(item){
  this.listaDeDependentesEditar.push(this.createDependenteEditar(item));
}

removeDependenteEditar(index){
  if(window.confirm("Deseja realmente remover esse Dependente?")){
    if(this.listaDeDependentesEditar.value[index].sinistro){
      alert("Dependente Possui Sinistro Registrado, Impossível Remover!")
    }else{
      this.listaDeDependentesEditar.removeAt(index);
      this.selectAsfEditar();
      this.selectAsfExtrasEditar();
    }
  }
}

createDependenteExtraEditar(item):FormGroup{
  return this.formBuilder.group({
    nome: item.nome,
    nascimento:item.nascimento,
    cpf:item.cpf,
    tipo:item.tipo,
    asf:item.asf,
    sinistro:item.sinistro,
    situacao:item.situacao
  });
}

addDependenteExtraEditar(item){
  this.listaDeDependentesExtraEditar.push(this.createDependenteExtraEditar(item));
}

removeDependenteExtraEditar(index){
  if(window.confirm("Deseja realmente remover esse Dependente?")){
    if(this.listaDeDependentesExtraEditar.value[index].sinistro){
      alert("Dependente Possui Sinistro Registrado, Impossível Remover!")
    }else{
      this.listaDeDependentesExtraEditar.removeAt(index);
      this.selectAsfEditar();
      this.selectAsfExtrasEditar();
    }
  }
}

matriculaUnicaEditar(matricula){
  this.db.getAssociadosMatricula(matricula).subscribe((data:any) => {
    if(data.length > 0){
      this.matriculaCheckEditar = false;
      alert("ESTA MATRICULA JÁ ESTA CADASTRADA!");
    }else{
      this.matriculaCheckEditar = true;
      this.salvar('matricula',matricula)
    }
  })
}

calcularDependentes(){
  this.totalAsfDependentesEditar = 0;
  this.totalMensalidadeEditar = 0;
  let control = 1;
  if(this.dependentesEditar.value.dependenteEditar.length > 0){
      this.dependentesEditar.value.dependenteEditar.forEach((item:any) => {
      if(item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
        this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
      }
      if(control == this.dependentesEditar.value.dependenteEditar.length){
        let controlExtra = 1;
        if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
          this.dependentesExtraEditar.value.dependenteExtraEditar.forEach(function(itemExtra){
            if(this.funeralExtraEditar == "SIM" && itemExtra.asf == "FUNERAL CONTRATADO" && itemExtra.situacao == "ATIVO"){
              this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
            }
            if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
              this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
            }else{
              controlExtra++
            }
          })
        }else{
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
        }
      }else{
        control++
      }
    })
  }else{
    let controlExtra = 1;
    if(this.dependentesExtraEditar.value.dependenteExtraEditar.length > 0){
      this.dependentesExtraEditar.value.dependenteExtraEditar.forEach((item:any) => {
        if(this.funeralExtraEditar == "SIM" && item.asf == "FUNERAL CONTRATADO" && item.situacao == "ATIVO"){
          this.totalAsfDependentesExtrasEditar += this.valorFuneralExtraEditar;
        }
        if(controlExtra == this.dependentesExtraEditar.value.dependenteExtraEditar.length){
          this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
        }else{
          controlExtra++
        }
      })
    }else{
      this.totalMensalidadeEditar = this.totalAsfDependentesEditar + this.totalAsfDependentesExtrasEditar + this.mensalidadeEditar;
    }
  }
}

onChange(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeClick(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

uploadFile(){
  const imagedata = new FormData();
  for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
   this.db.enviarDocAssociado(this.idAssociado, imagedata).subscribe((result) => {
     let jsonDate = new Date().toJSON();
     let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(result);
       let body = {
         "menu" : "ASSOCIADOS", 
         "descricao" : "Documento Enviado: "+this.matriculaEditar+" - "+this.nomeEditar, 
         "data" : this.dataHoje, 
         "hora" : horaAgora, 
         "usuario" : localStorage.getItem('usuarioVendas')
       }
       this.db.postLogs(body).subscribe(res => {
         console.log(res);
       this.atualizarDocs();
       })
     },(error) => {
       let jsonDate = new Date().toJSON();
     let horaAgora = new Date(jsonDate).toLocaleTimeString();
       console.log(error);
       let body = {
         "menu" : "ASSOCIADOS", 
         "descricao" : "Documento Enviado: "+this.matriculaEditar+" - "+this.nomeEditar, 
         "data" : this.dataHoje, 
         "hora" : horaAgora, 
         "usuario" : localStorage.getItem('usuarioVendas')
       }
       this.db.postLogs(body).subscribe(res => {
       console.log(res);
       this.atualizarDocs();
       })
     });
 }
 
 delDoc(url){
  if(window.confirm('Deseja Realmente Deletar esse Documento?')){
   this.db.removeDoc(url).subscribe(res => {
     console.log(res);
     this.db.removeDocAssociadoDB(this.idAssociado,url).subscribe(result => {
       console.log(result);
       this.atualizarDocsDel(url);
     }, error => {
       console.log(error);
     })
    },err => {
      console.log(err);
       this.db.removeDocAssociadoDB(this.idAssociado,url).subscribe(result => {
       console.log(result);
       this.atualizarDocsDel(url);
     }, error => {
       console.log(error);
     })
   })
   let jsonDate = new Date().toJSON();
   let horaAgora = new Date(jsonDate).toLocaleTimeString();
     let body = {
       "menu" : "ASSOCIADOS", 
       "descricao" : "Documento Enviado: "+this.matriculaEditar+" - "+this.nomeEditar, 
       "data" : this.dataHoje, 
       "hora" : horaAgora, 
       "usuario" : localStorage.getItem('usuarioVendas')
      }
     this.db.postLogs(body).subscribe(res => {
       console.log(res);
     })
 }
}
 
 atualizarDocs(){
  this.docs = [];
  this.db.getAssociadosById(this.idAssociado).subscribe((result:any) => {
    this.docs = result.documentos;
    this.pdfs = [];
    this.images = [];
    this.selectedFiles = "";
    this.files = [];
    if(this.docs && this.docs.length > 0){
      this.pdfs = [];
      this.images = [];
      this.docs.forEach((item:any) => {
      let ext = item.url.split('.').pop();
        if(ext == 'pdf' || ext == 'PDF'){
          this.pdfs.push(item);
        }else{
          this.images.push(item);
        }
      })
    }
  })
}

atualizarDocsDel(url){
  this.pdfs = [];
  this.images = [];
  let idx = this.docs.map(function(item){
    return item.url
  }).indexOf(url)
  if(idx >= 0){
    this.docs.splice(idx,1);
    if(this.docs && this.docs.length > 0){
        this.docs.forEach((item:any) => {
        let ext = item.url.split('.').pop();
          if(ext == 'pdf' || ext == 'PDF'){
            this.pdfs.push(item);
          }else{
            this.images.push(item);
          }
        })
      }
    }
  }

openModalPdfViewer(url){
  this.pdfViewerOnDemand.pdfSrc = url;
  this.pdfViewerOnDemand.refresh();
}

openModalLightBox(url, i){
  this.indexImage = i;
  this.imagemSelecionada = url;
  let modal = document.getElementById('myModal')
  modal.style.display = "block";
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal = document.getElementById('myModal')
  modal.style.display = "none";
}

cpfUnicoEditar(cpf){
  let key = cpf.replace(/\D/g,"");
  if(key.length == 11){
    this.db.getAssociadosCpf(cpf).subscribe((data:any) => {
      if(data.length > 0){
        this.cpfCheckEditar = false;
        alert("ESTE CPF JÁ ESTA CADASTRADO!");
        this.cpfEditar = "";
      }else{
        this.cpfCheckEditar = true;
        this.salvar('cpf',cpf)
      }
    })
  }
}

buscarAsssociadoPorMatricula(matricula){
  if(this.onOff.isOnline){
    this.db.getAssociadosMatricula(matricula).subscribe((data:any) => {
      this.nomeIndicacaoPremiada = data[0].nome;
    })
  }
}

solicitarIndicacaoPremiada(){
  if(window.confirm("Deseja realmente solicitar a Indicação Premiada, os dados não poderão ser Editados Posteriormente!")){
    let idIndicacao:any = "";
    let responsavelIndicacao:any = "";
    let idxIndicacao = this.db.emails.map((e:any) => {
      return JSON.stringify(Object.keys(e));
    }).indexOf('["indicacaoPremiada"]');
    if(idxIndicacao >= 0){
      let dadosIndicacao:any = Object.values(this.db.emails[idxIndicacao])[0];
      idIndicacao = dadosIndicacao.idResponsavel;
      responsavelIndicacao = dadosIndicacao.nomeResponsavel;
    }
     let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let randomInd = Math.random().toString(36).slice(-8);
      let dadosPgto = this.checkDadosBancarios ? " - Dados Bancarios: "+this.banco+" - Agencia: "+this.agencia+" - "+this.agenciaDigito+" - Conta: "+this.contaCorrente+" - "+this.contaCorrenteDigito+" - CPF: "+this.cpfBanco : this.checkPix ? " - Tipo de Pix: "+this.tipoPix+" - Chave Pix: "+this.pix : this.checkConsignet ? " - CONSIGNET" : this.checkVindi ? " - VINDI" : "";
      let indicacaoPrem = {
        "id": randomInd,
        "data" : this.dataHoje,
        "hora" : hora,
        "titulo" : "INDICAÇÃO PREMIADA",
        "descricao" : "Associado: "+ this.nomeEditar + " - Matricula: " + this.matriculaEditar+ " - Quem Indicou: "+ this.nomeIndicacaoPremiada + " - Matricula: " + this.matriculaIndicacaoPremiada+ " - Data Crédito: 15/" + this.mesIndicacaoPremiada.split('-').reverse().join('/') + dadosPgto,
        "idResponsavel" : idIndicacao,
        "responsavel" : responsavelIndicacao,
        "resolvido":false,
        "dataResolucao" : "",
        "horaResolucao" : "",
        "prioridade" : "ALTA",
        "origem":"Sistema",
        "idUserSolicitacao":localStorage.getItem('idUserVendas'),
        "nomeSolicitante":localStorage.getItem('usuarioVendas')
      }
      this.db.postTarefa(indicacaoPrem).subscribe(res => {
        console.log(res)
        alert("Indicação Enviada com Sucesso!");
        this.indicacaoEnviada = true;
        this.selectTab(0);
      },err => console.log(err))
  }
}

}
