import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filtro',
    pure: false
})
export class FiltroPipe implements PipeTransform {
    transform(items: Array<any>, key: string): Array<any> {
        if(items){
            return items.filter(item => item.empresaOrigem === key);
        }
    }
}